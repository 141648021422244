import React, { useState } from "react";
import styled from "styled-components";
import IconImage from "../../assets/estate.png";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

type Props = {};

const ExternalsResident = (props: Props) => {
  const [deactivate, setDeactivate] = useState({ active: true });
  const [toggleState, setToggleState] = useState("all");

  const handleDeactivate = (event: React.FormEvent<HTMLInputElement>) => {
    setDeactivate({
      ...deactivate,
      [event.currentTarget.name]: event.currentTarget.checked,
    });
  };
  const handleAll = () => {
    setToggleState("all");
  };
  const handleActive = () => {
    setToggleState("active");
  };
  const handleInActive = () => {
    setToggleState("inactive");
  };
  return (
    <Wrapper>
      <Counters>
        <Count>
          <div>
            <HomeOutlinedIcon />
          </div>
          <div>
            <CountTitle style={{ color: "#C5CAE4" }}>
              Total Residents
            </CountTitle>
            <h3>500</h3>
          </div>
        </Count>
        <Count>
          <div>
            <HomeOutlinedIcon />
          </div>
          <div>
            <CountTitle style={{ color: "#00BA07" }}>
              Active Residents
            </CountTitle>
            <h3>350</h3>
          </div>
        </Count>
        <Count>
          <div>
            <HomeOutlinedIcon />
          </div>
          <div>
            <CountTitle style={{ color: "#B50C0C" }}>
              Inactive Residents
            </CountTitle>
            <h3>150</h3>
          </div>
        </Count>
      </Counters>
      <ToggleResidentContainer>
        <ToggleButton
          style={{
            borderBottom:
              toggleState === "all" ? "10px solid #2D4BF3" : "transparent",
            color:
              toggleState === "all" ? "#686868" : "rgba(104, 104, 104, 0.2)",
          }}
          onClick={handleAll}
        >
          Total Resident
        </ToggleButton>
        <ToggleButton
          style={{
            borderBottom:
              toggleState === "active" ? "10px solid #2D4BF3" : "transparent",
            color:
              toggleState === "active" ? "#686868" : "rgba(104, 104, 104, 0.2)",
          }}
          onClick={handleActive}
        >
          Active Resident
        </ToggleButton>
        <ToggleButton
          style={{
            borderBottom:
              toggleState === "inactive" ? "10px solid #2D4BF3" : "transparent",
            color:
              toggleState === "inactive"
                ? "#686868"
                : "rgba(104, 104, 104, 0.2)",
          }}
          onClick={handleInActive}
        >
          Inactive Resident
        </ToggleButton>
      </ToggleResidentContainer>

      <Member>
        <EName>EST-10B45</EName>
        <DP>
          <img src={IconImage} alt="Estate IQ" />
          <h4>Oladoye Tolulope</h4>
        </DP>
        <Address>10B, Wiseman Crescent, Golden Gate Estate.</Address>
        <CTA>
          <div>
            <EmailOutlinedIcon />
          </div>
          <div>
            <LocalPhoneOutlinedIcon />
          </div>
        </CTA>
        <Delete>
          <DeleteOutlinedIcon />
        </Delete>
        <DeActivate>
          <FormControlLabel
            control={
              <Switch
                checked={deactivate.active}
                onChange={handleDeactivate}
                color="primary"
                name="active"
                size="small"
              />
            }
            label=""
          />
        </DeActivate>
      </Member>
    </Wrapper>
  );
};

export default ExternalsResident;

const Wrapper = styled.div`
  width: 100%;
`;
const Counters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 765px;
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 991px) {
    width: 700px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 576px) {
    margin-top: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
    width: 400px;
  }
  @media (max-width: 426px) {
    width: 90%;
    margin: auto;
  }
`;
const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 235px;
  height: 80px;
  border-radius: 5px;
  text-align: left;
  :first-child {
    border: 1px solid rgba(45, 75, 243, 0.2);
  }
  :nth-child(2) {
    border: 1px solid rgba(0, 186, 7, 0.2);
  }
  :last-child {
    border: 1px solid rgba(181, 12, 12, 0.2);
  }
  h3 {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  div {
    :first-child {
      background-color: #f4f6fe;
      border-radius: 5px;
      padding: 10px;
      margin-right: 10px;
      svg {
        color: var(--mainBlue);
      }
    }
  }
  @media (max-width: 1200px) {
    width: 220px;
  }
  @media (max-width: 768px) {
    width: 160px;
  }
  @media (max-width: 576px) {
    width: 190px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media (max-width: 426px) {
    width: 100%;
  }
`;
const CountTitle = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.6px;
`;
const Member = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  height: 71px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
  @media (max-width: 426px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
const EName = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.7px;
  color: var(--mainBlack);
  @media (max-width: 1200px) {
    font-size: 12px;
  }
  @media (max-width: 426px) {
    display: none;
  }
`;
const DP = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 31px;
    height: 31px;
    border-radius: 50%;
  }
  h4 {
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 12px;
  }
  @media (max-width: 1200px) {
    h4 {
      font-size: 12px;
    }
  }
  @media (max-width: 426px) {
    img {
      width: 24px;
      height: 24px;
    }
  }
`;
const Address = styled.div`
  margin-left: 25px;
  width: 268px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.8px;
  color: var(--mainBlack);
  @media (max-width: 1200px) {
    font-size: 12px;
    width: 230px;
    margin-left: 15px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const CTA = styled.div`
  display: flex;
  width: 58px;
  justify-content: space-between;
  div {
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid var(--mainBlue);
    :hover {
      background-color: var(--mainBlue);
      svg {
        color: white;
      }
    }
  }
  svg {
    width: 13px;
    height: 13px;
    color: var(--mainBlue);
  }
`;
const Delete = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: white;
  color: var(--mainBlue);
`;
const DeActivate = styled.div`
  width: 32px;
  align-items: center;
`;
const ToggleResidentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;
const ToggleButton = styled.button`
  width: 187px;
  height: 49px;
  background: #fbfbfb;
  font-size: 12px;
  letter-spacing: -0.6px;
  font-weight: bold;
  outline: 0;
  border-color: transparent;
  :hover {
    cursor: pointer;
  }
`;
