import React, { useState, useEffect,useContext} from "react";
import styled from "styled-components";
// import Logo from "../assets/logo.svg";
// import DropDown from "../assets/dropdown.svg";
import { Link } from "react-router-dom";
import SideNav from "./SideNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders, faAngleDown} from "@fortawesome/free-solid-svg-icons";
// import { faUserPlus } from "@fortawesome/free-brands-svg-icons"
import IconImage from "../../assets/estate.png";
import MenuIcon from "@mui/icons-material/Menu";
import animateScrollTo from "animated-scroll-to";
import {AppContext} from '../../Context/index';
import Modal from './Modal';
import create from "../../assets/create.svg";
import join from "../../assets/join.svg";
import CreateNew from '../Settings/CreateNew';
import JoinNew from '../Settings/JoinNew';


interface Props {
  tabName: string;
  rightSubHeader: any;
  mainBody: React.ReactNode;
}

const Main: React.FC<Props> = (props) => {
  const [toggle, setToggle] = useState(false);
  const [toggleNewEstate, setNewEstate] = useState(false);
  const {userData} =useContext(AppContext)
  const [formState, setFormState] =useState({
    estatePick:"create"
  });
  
  const handleToogle = () => {
    setToggle(!toggle);
  };
  const closeToggle = () => {
    setToggle(false);
  };
  const handleToggleNewEstate =()=>{
    setNewEstate(!toggleNewEstate)
  }
  const handleEstateChange =(event: React.FormEvent<HTMLInputElement>)=>{
setFormState({...formState,[event.currentTarget.name] : event.currentTarget.value})
  }
  const ChooseCreate =()=>{
    setFormState({...formState,estatePick:"create"})
  }
  const ChooseJoin =()=>{
    setFormState({...formState,estatePick:"join"})
  }
  useEffect(() => {
    animateScrollTo(0);
  }, []);
  return (
    <>
      <Wrapper>
        <SideNav toggle={toggle} />
        <ContentWrapper>
          <HeaderWrapper>
            <HeaderContainer>
              <LeftHeader>
                <div className="tabtitle">{props.tabName}</div>
                <div className="burger" onClick={handleToogle}>
                  {/* <FontAwesomeIcon icon={faHamburger} /> */}
                  <MenuIcon />
                </div>
              </LeftHeader>

              <RightHeader>
              {/* <UserMgt onClick={handleEstateChange}>
                  <FontAwesomeIcon icon={faUserPlus} />
                </UserMgt> */}
                {/* <Bell>
                  <FontAwesomeIcon icon={faBell} />
                  <div></div>
                </Bell> */}
                <Link to ="/profile">
                <NameSection>
                  <DP>
                    <img src={IconImage} alt="EstateIQ" />
                  </DP>
                  <Details>
                    <Name>{userData?.firstName}{" "}{userData?.lastName}</Name>
                    <Title>{userData?.role}<FontAwesomeIcon icon={faAngleDown}/></Title>
                  </Details>
                </NameSection>
                </Link>
                <Link to ="/settings">
                <Settings>
                  <FontAwesomeIcon icon={faSliders} />
                 
                </Settings>
                </Link>
              </RightHeader>
            </HeaderContainer>
          </HeaderWrapper>
          <ContentBody onClick={closeToggle}>
            <SubHeading>
              <LeftSubHeading>
                {props.tabName} {` >`}
              </LeftSubHeading>
              <RightSubHeading>{props.rightSubHeader}</RightSubHeading>
            </SubHeading>
            {props.mainBody}
          </ContentBody>
        </ContentWrapper>
      </Wrapper>
      <Modal heading="Create or Join Estate(s)" toggleModal={handleToggleNewEstate} modal={toggleNewEstate} body={<>
        <SignUpModal>    
                <EstateCTA onClick={ChooseCreate}>
                  <img src={create} alt="Create Estate IQ" />
                  <RightModalContent>
                    <h2>Create a New Estate</h2>
                    <p>
                      Sign up as an estate admin and onboard members of your
                      estate
                    </p>
                  </RightModalContent>
                  <input type="radio" name="estateChoice" onChange={handleEstateChange} checked={formState?.estatePick==="create"} value="create"/>
                </EstateCTA>
              
                <EstateCTA onClick={ChooseJoin}>
                  <img src={join} alt="Join Estate IQ" />
                  <RightModalContent>
                    <h2>Join a New Estate</h2>
                    <p>
                    Sign up as a member of an existing estate
                    </p>
                  </RightModalContent>
                  <input type="radio" name="estateChoice" onChange={handleEstateChange} checked={formState?.estatePick==="join"} value="join"/>
                </EstateCTA>
              
            </SignUpModal>
            {formState.estatePick==="create" && (
              <>
              <CreateNew closeFormToggle={handleToggleNewEstate}/>
              {/* <FormGroup>
                <label>Enter Estate Name</label>
                <input type="text" name="estateName"/>
                
              </FormGroup>
              <FormGroup>
                <label>Enter Estate Address</label>
            <input type="text" name="address"/>
              </FormGroup>
              <CreateButton>Create Estate</CreateButton> */}
              </>
            )}
            {formState.estatePick==="join" && (
              <>
              <JoinNew closeFormToggle={handleToggleNewEstate}/>
              {/* <FormGroup>
                <label>Choose Estate</label>
                <input type="text" name="estateName"/>
                
              </FormGroup>
              
              <CreateButton>Join Estate</CreateButton> */}
              </>
            )}
      </>}/>
    </>
  );
};

export default Main;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;
const ContentWrapper = styled.div`
  width: calc(100% - 241px);
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const ContentBody = styled.div`
  padding-left: 48px;
  padding-right: 29px;
  padding-top: 100px;
  @media (max-width: 991px) {
    padding-left: 29px;
    padding-right: 29px;
  }
  @media (max-width: 576px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
const HeaderWrapper = styled.div`
  border-bottom: 1px solid #c5cae4;
  padding-top: 28px;
  padding-bottom: 28px;
  position: fixed;
  background-color: white;
  width: calc(100% - 241px);
  z-index: 2;
  @media (max-width: 991px) {
    background-color: var(--mainBlue);
    border-color: transparent;
    width: 100%;
    position: fixed;
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 48px;
  padding-right: 19px;
  @media (max-width: 991px) {
    align-items: center;
  }
  @media (max-width: 768px) {
    padding-left: 29px;
    padding-right: 29px;
  }
  @media (max-width: 576px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  a{
    text-decoration:none;
  }
`;
const LeftHeader = styled.div`
  font-weight: 700;
  font-size: 24px;
  .tabtitle {
    @media (max-width: 991px) {
      display: none;
    }
  }
  .burger {
    border: 1px solid white;
    color: white;
    // width:40px;
    // height:40px;
    padding: 10px 15px;
    border-radius: 5px;
    @media (min-width: 991px) {
      display: none;
    }
  }
`;
const RightHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap:12px;
`;
const NameSection = styled.div`
  background-color: #fbfbfb;
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 12px;
 
  padding-right: 12px;
  border-radius: 5px;
`;
const Details = styled.div`
  text-align: right;
  margin-left: 22px;
`;
const DP = styled.div`
  width: 30px;
  height: 30px;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;
const Name = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
`;
const Title = styled.div`
  background: rgba(234, 203, 48, 0.1);
  border-radius: 2px;
  padding: 2px;
  display:flex;
  gap:5px;
  align-items:center;
  width: 58px;
  // padding:2px 12px 2px 12px;
  color: var(--mainBlue);
  font-weight: 500;
  font-size: 7px;
  text-align: center;
  margin-left: auto;
 
`;
// const Bell = styled.div`
//   width: 50px;
//   height: 50px;
//   background-color: #fbfbfb;
//   border-radius: 5px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: relative;
//   div {
//     position: absolute;
//     width: 8px;
//     height: 8px;
//     top: 12px;
//     right: 14px;
//     border-radius: 50%;
//     background-color: #e12828;
//   }
// `;
// const UserMgt = styled.div`
//   width: 50px;
//   height: 50px;
//   background-color: #fbfbfb;
//   border-radius: 5px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: relative;
//  :hover{
//    background-color:var(--mainBlue);
//    color:white;
//    cursor:pointer;
//  }
// `;
const Settings = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fbfbfb;
  color: var(--mainBlack);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  // @media (min-width: 991px) {
  //   display: none;
  // }
`;

const SubHeading = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const LeftSubHeading = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #c5cae4;
  letter-spacing: -1px;
  @media (max-width: 768px) {
    width: 100%;
    color: black;
    font-weight: bold;
    text-align: left;
    border-bottom: 1px solid #c5cae4;
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;
const RightSubHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
    padding-bottom: 20px;
    padding-top: 30px;
  }
  
`;
const SignUpModal = styled.div`

  width: 500px;
  margin:auto;
  margin-bottom:10px;
  // right: 35px;
  // top: 80px;
  // background-color: white;
  // padding: 20px;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  // border-radius: 20px;
  // @media (max-width: 1100px) {
  //   top: 350px;
  //   right: 15px;
  //   display: ${(props: any) => (props.navToggle ? "block" : "none")};
  // }
  @media (max-width: 576px) {
    width: 300px;
    padding: 10px;
  }
  // a {
  //   text-decoration: none;
  // }
`;
const EstateCTA = styled.div`
  display: flex;
  align-items:center;

  background-color: #fbfbfb;
  border-radius: 20px;
  // width:100%;
  padding: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  :hover {
    cursor: pointer;
    background-color:#c5cae4;
  }
  img {
    margin-right: 10px;
  }
  @media (max-width: 576px) {
    img {
      width: 50px;
      height: 50px;
    }
  }
  input{
    font-size:36px;
    margin-left:auto;
  }
`;
const RightModalContent = styled.div`
  text-align: left;
  h2 {
    font-weight: 700;
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 5px;
    letter-spacing: -2px;
    color: black;
  }
  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 10px;
    letter-spacing: -0.8px;
    color: black;
  }
`;
// const FormGroup = styled.div`
//   margin-top: 15px;
//   margin-bottom: 15px;
//   text-align: left;
//   position: relative;
//   width:500px;
//   margin:auto;
//   input {
//     width: 100%;
//     background: #fbfbfb;
//     border: 1px solid #c5cae4;
//     border-radius: 4px;
//     height: 58px;
//     padding-left: 10px;
//   }
//   label {
//     width: 100%;
//     font-size: 12px;
//     font-weight: 400;
//     color: #686868;
//   }
//   @media (max-width: 1366px) {
//     input {
//       height: 48px;
//     }
//   }
// `;
// const CreateButton = styled.div `
// background-color:var(--mainBlue);
// width:300px;
// height:42px;
// margin:auto;
// margin-top:20px;
// display:flex;
// align-items:center;
// justify-content:center;
// border-radius:8px;
// color:white;
// outline:0;
// border-color:transparent;
// `