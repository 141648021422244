import React, { useState, useContext } from "react";
import styled from "styled-components";
import create from "../../assets/create.svg";
import join from "../../assets/join.svg";
import IconImage from "../../assets/estate.png";
import CreateAccount from "./CreateNew";
import JoinAccount from "./JoinNew";
import { AppContext } from "../../Context/index";

const AdminSettings = () => {
  const { userData } = useContext(AppContext);
  // console.log(userData);
  const [formState, setFormState] = useState({
    estatePick: "",
  });

  const closeFormToggle = () => {
    setFormState({ ...formState, estatePick: "" });
  };

  const handleEstateChange = (event: React.FormEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };
  const ChooseCreate = () => {
    setFormState({ ...formState, estatePick: "create" });
  };
  const ChooseJoin = () => {
    setFormState({ ...formState, estatePick: "join" });
  };
  return (
    <>
      {!formState.estatePick && (
        <>
          <SectionTitle>Switch Accounts</SectionTitle>
          <AllAccount>
            {userData?.estates.map((estate: any, i: any) => {
              return (
                <NameSection key={i}>
                  <DP>
                    <img src={IconImage} alt="EstateIQ" />
                  </DP>
                  <Details>
                    <Name>{estate?.name}</Name>
                    <Title>Admin</Title>
                  </Details>
                </NameSection>
              );
            })}
          </AllAccount>
        </>
      )}
      {!formState.estatePick && (
        <>
          <SectionTitle>Add New</SectionTitle>
          <SignUpModal>
            <EstateCTA onClick={ChooseCreate}>
              <img src={create} alt="Create Estate IQ" />
              <RightModalContent>
                <h2>Create a New Estate</h2>
                <p>
                  Sign up as an estate admin and onboard members of your estate
                </p>
              </RightModalContent>
              <input
                type="radio"
                name="estateChoice"
                onChange={handleEstateChange}
                checked={formState?.estatePick === "create"}
                value="create"
              />
            </EstateCTA>

            <EstateCTA onClick={ChooseJoin}>
              <img src={join} alt="Join Estate IQ" />
              <RightModalContent>
                <h2>Join a New Estate</h2>
                <p>Sign up as a member of an existing estate</p>
              </RightModalContent>
              <input
                type="radio"
                name="estateChoice"
                onChange={handleEstateChange}
                checked={formState?.estatePick === "join"}
                value="join"
              />
            </EstateCTA>
          </SignUpModal>
        </>
      )}

      {formState.estatePick === "create" && (
        <>
          <CreateAccount closeFormToggle={closeFormToggle} />
        </>
      )}
      {formState.estatePick === "join" && (
        <>
          <JoinAccount closeFormToggle={closeFormToggle} />
        </>
      )}
    </>
  );
};

export default AdminSettings;

const AllAccount = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  gap: 20px;
  margin-bottom: 10px;

  @media (max-width: 576px) {
    flex-direction: column;
    width: 100%;
  }
`;
const SignUpModal = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  @media (max-width: 1200px) {
    flex-direction: column;
    margin: auto;
    margin-top: 10px;
  }
  @media (max-width: 576px) {
    width: 100%;
    margin-left: 5px;
  }
`;
const SectionTitle = styled.div`
  color: #686868;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.7px;
  text-align: left;
  padding-top: 20px;
  @media (max-width: 576px) {
    width: 100%;
    padding-bottom: 10px;
  }
`;
const EstateCTA = styled.div`
  display: flex;
  align-items: center;
  background-color: #fbfbfb;
  border-radius: 20px;
  width: 499px;
  padding: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  :hover {
    cursor: pointer;
    background-color: #c5cae4;
  }
  img {
    margin-right: 10px;
  }
  @media (max-width: 1200px) {
    margin: auto;
  }
  @media (max-width: 576px) {
    width: 300px;
    padding: 10px;
    margin-right: auto;
    img {
      width: 40px;
      height: 40px;
    }
  }
  input {
    font-size: 36px;
    margin-left: auto;
  }
`;
const RightModalContent = styled.div`
  text-align: left;
  h2 {
    font-weight: 700;
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 5px;
    letter-spacing: -2px;
    color: black;
  }
  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 10px;
    letter-spacing: -0.8px;
    color: #686868;
    font-weight: 700;
  }
  @media (max-width: 576px) {
    h2 {
      font-size: 18px;
      letter-spacing: -0.8px;
    }
    width: 200px;
  }
`;

const NameSection = styled.div`
  background-color: #fbfbfb;
  display: flex;
  align-items: center;
  height: 50px;
  width: 250px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 15px;
  border-radius: 5px;
  :hover {
    cursor: pointer;
    background-color: #c5cae4;
  }
`;
const Details = styled.div`
  text-align: right;
  margin-left: 22px;
`;
const DP = styled.div`
  width: 30px;
  height: 30px;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;
const Name = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  letter-spacing: -0.7px;
`;
const Title = styled.div`
  background: rgba(234, 203, 48, 0.1);
  border-radius: 2px;
  padding: 2px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  width: 58px;
  // padding:2px 12px 2px 12px;
  color: var(--mainBlue);
  font-weight: 500;
  font-size: 7px;
  text-align: center;
  margin-left: auto;
`;
