import React, { useContext, useEffect } from "react";
import Main from "../Constants/Main";
import ResidentActivity from "./ResidentActivity";
import { AppContext } from "../../Context/index";
import { VisitorAccessButton, FilterByDate } from "../Constants/Snippets";
import { httprequeset } from "../Constants/Constant";
import { useNavigate } from "react-router-dom";
import Externals from './ExternalsActivity'
// interface Props {
//   navToggle: boolean;
// }

const Index: React.FC = (props) => {
  const { userRole } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    httprequeset
      .get(`/auth/profile`)
      .then((res) => {})
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/");
        }
      });
  }, [navigate]);
  return (
    <>
      <Main
        tabName="Activity Logs"
        rightSubHeader={
          <>
            <>
              {userRole === "resident" && (
                <>
                  <FilterByDate /> <VisitorAccessButton />
                </>
              )}
            </>
          </>
        }
        mainBody={
          <>
           {userRole === "resident" && (
                <>
                 <ResidentActivity in />
                </>
              )}
               {userRole === "security" && (
                <>
                 <Externals/>
                </>
              )}
            
          </>
        }
      />
    </>
  );
};

export default Index;
