import React,{useContext, useEffect} from "react";
// import Logo from "../assets/logo.svg";
// import DropDown from "../assets/dropdown.svg";
import { useNavigate } from "react-router-dom";
// import Modal from "../Constants/Modal";
import Main from "../Constants/Main";
// import { AddPersonnelButton } from "../Constants/Snippets";
import { httprequeset } from "../Constants/Constant";
import { AppContext } from "../../Context/index";
import AdminSettings from './AdminSettings';

const Index: React.FC = (props) => {
  const { userRole } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(()=>{
    httprequeset.get(`/auth/profile`)
      .then((res) => {  
      })
    .catch((err) => {
         if(err.response.status===401){
          navigate('/')
         }
       });
  },[navigate])
  return (
    <>
      <Main
        tabName="Settings"
        rightSubHeader={
          <>
            
          </>
        }
        mainBody={
          <>
           {userRole === "estate_admin" &&  <AdminSettings/>}
           
          </>
        }
      />
    </>
  );
};
export default Index;

