import React, { useEffect } from "react";
// import styled from "styled-components";
// import Logo from "../assets/logo.svg";
// import DropDown from "../assets/dropdown.svg";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Main from "../Constants/Main";
import AdminApprovals from "./AdminApprovals";
import { AdminAddResident, FilterByDate } from "../Constants/Snippets";
import { httprequeset } from "../Constants/Constant";

// interface Props {
//   divAnchor:<MouseEventHandler> || <HTMLAnchorElement>
// }

const Index: React.FC = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    httprequeset
      .get(`/auth/profile`)
      .then((res) => {})
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/");
        }
      });
  }, [navigate]);
  // Todo, move the add resident modal to the top of the application and pass as props
  //
  // const CloseAddToggle=()=>{
  //   setResidentOption(false)
  // }
  // const DoNothing =()=>{

  // }
  // <div onClick={props.addResidentOption ? CloseAddToggle:DoNothing}>
  return (
    <div>
      {localStorage.getItem('activeStatus')==='provisioned'? <UnVerified>Please Verify your Email</UnVerified> : null}
      {(localStorage.getItem("empty")==='emptyEstate') ? <Empty>You do not have an estate and you don't belong to one, please create an estate</Empty> :null}
      <Main
        tabName="Approvals"
        rightSubHeader={
          <>
            <AdminAddResident /> <FilterByDate />
          </>
        }
        mainBody={
          <>
            <AdminApprovals />
          </>
        }
      />
    </div>
  );
};

export default Index;
const UnVerified = styled.div `
background-color:tomato;
color:white;
font-weight:bold;
position:fixed;
z-index:1000;
font-size:12px;
display:flex;
align-items:center;
justify-content:center;
width:300px;
height:40px;
left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
`
const Empty = styled.div `
background-color:tomato;
color:white;
font-weight:bold;
position:fixed;
z-index:1000;
font-size:11px;
display:flex;
align-items:center;
justify-content:center;
width:300px;
height:40px;
left: 0; 
  right: 0; 
  bottom:0;
  margin-left: auto; 
  margin-right: auto;
`
// const Head = styled.div`
//   select {
//     width: 115px;
//     height: 40px;
//     padding-left: 10px;
//     padding-right: 10px;
//     font-size: 12px;
//     background-color: #f4f6fe;
//     border-radius: 5px;
//     border-color: transparent;
//     outline: 0;
//   }
// `;
// const AddButton = styled.button`
//   width: 200px;
//   height: 50px;
//   color: white;
//   background-color: var(--mainBlue);
//   outline: 0;
//   border-color: transparent;
//   margin-right: 15px;
//   font-size: 12px;
//   border-radius: 5px;
//   :hover {
//     background-color: transparent;
//     color: var(--mainBlue);
//     border: 1px solid var(--mainBlue);
//   }
// `;
