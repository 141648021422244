import React from "react";
import styled from "styled-components";
import Logo from "../../assets/logo-colored.svg";
const Terms = () => {
  return (
    <>
      <Container>
        <div>
          <img src={Logo} alt="Estate IQ" />
          <h2>Terms and Condition {"&"} Privacy Policy</h2>
        </div>
        Welcome to EstateIQ, an estate management app for residents and estate
        managers within gated communities. <br />
        This page (read in conjunction with the documents referred to on it)
        details the terms and conditions ("Terms") on which we provide these
        digital services on the App. Please read these Terms carefully before
        using this App. You understand that by using the EstateIQ, you are
        deemed to have accepted these Terms, regardless of how you subscribe to
        or use the services of the App. These Terms of Service and various other
        policies are binding under relevant provisions of the Information
        Technology Act of 2000. In these Terms, the references to "you", "user",
        and "your", shall mean the end user/customer accessing the App, its
        contents, and using the services offered through this App. "We", "us",
        "our", and "EstateIQ App" shall mean Adaren Business Solutions.
        <br />
        <br />
        1. User Account, Password, and Security: All information used for the
        Mobile App's registration process is kept secure and confidential. You
        are responsible for maintaining the confidentiality of the password and
        account, do not share these details with anyone. EstateIQ App cannot and
        will not be liable for any loss or damage arising from your failure to
        comply with this section. <br />
        <br />
        2. Services Offered: EstateIQ provides internet-based protocols through
        the app (all such services, collectively, the "Service"). One such
        Service offers a secure, convenient, flexible way of controlling who has
        access to your building and when access is allowed. It also offers
        additional services such as enhancing the security framework within
        gated communities, collaboration among residents, and collection of fees
        and dues that are critical to maintaining infrastructure. EstateIQ makes
        payments convenient, and collections seamless. <br />
        <br />
        In addition, these shall be governed by specific policies as and when
        introduced and will be available on the T & C page. Terms may be further
        supplemented by product-specific conditions.
        <br />
        <br />
        3. Proprietary rights: You acknowledge and agree that the platform
        contains proprietary and confidential information that is protected by
        applicable intellectual property and other laws. Except as expressly
        authorized by EstateIQ App, you agree not to modify, rent, lease, loan,
        sell, distribute or create derivative works based on the service, in
        whole or in part. You also agree not to extract the code or
        reverse-engineer it in any way. Any attempt at hacking or unlawful use
        of the EstateIQ App can and will invite the maximum prosecution
        allowable under the law.
        <br />
        <br />
        4. Privacy Policy: We have developed this Privacy Policy to protect your
        personal information and keep it confidential. By visiting Our App, you
        are accepting and consenting to the practices described in this Privacy
        Policy. The following Privacy Policy and Cookie Policy outlines the
        information we may process and how we may use that information to better
        serve you while using our App. Please review the following carefully so
        that you understand our privacy policy.
        <br />
        <Privacy>
          1. Correspondence Information. Following are the various
          correspondence information that you are likely to provide when you
          access/ register for our service:
          <ul>
            <li>
              Personal Information: Name, telephone, email id, address, and
              vehicle information.
            </li>
            <li>
              Location Information: This app collects location data to enable
              the Emergency Panic feature even when the app is closed or not in
              use. This data is NOT used for advertising/support ads.
            </li>
            <li>
              Account Information: Your account history on EstateIQ (without
              limitation); all request logs, transactions, communications, etc.
              as pertaining to only use of the EstateIQ app. We maintain this in
              encrypted form on secure servers. However, these details may be
              stored for regulatory and compliance purposes only. We do not
              allow unauthorized publishing or disclosure of users’ non-public
              contact or information.
            </li>
            <li>
              User IDs: Your usernames, email addresses, and other information
              used by you in relation to our Services.
            </li>
            <li>
              Shared Information: Fake/imposter information is not allowed on
              EstateIQ App.
            </li>
          </ul>
          <br />
          2. Technical and functional management of the Mobile App: When you use
          EstateIQ App, we also process technical data such as your IP address,
          device ID or MAC address, and information about the manufacturer,
          model, and operating system of your mobile device. We use this data to
          enable us to deliver the functionalities of the Mobile App, resolve
          technical difficulties, and provide you with the correct (prevent the
          transmission of spam, viruses, and/or unlawful, defamatory, obscene,
          or otherwise undesirable material or content) and most of all,
          up-to-date version of the app.
          <br />
          <br />
          3. Operational Use: This app collects location data to enable the
          Emergency Panic feature even when the app is closed or not in use.
          This data is NOT used for advertising/support ads. This is done only
          with the intent to improve your experience while using our services.
          <br />
          <br />
          4. Applicable law and procedure. We collect and store your
          correspondence Information to (a) comply with our obligations under
          the law, and (b) monitor your use of our Services to ensure your
          compliance with our Terms of Service for example to prevent fake
          profiles from being created.
          <br />
          <br />
          5. Cookie Policy. A cookie is a small text file that we place on your
          mobile device to enable various features of the mobile app. "Cookies"
          are used to store user preferences and to track user trends, so as to
          enhance your interactive experience and generally improve our services
          to you. You can change your cookie settings to accept or not accept
          cookies in your browser settings. If you do accept a "cookie", you
          thereby agree to our use of any Personal Information collected while
          using that cookie.
        </Privacy>
        <p>
          Last Updated on August 01, 2022, EstateIQ is operated by Adaren
          Business Solutions.
        </p>
      </Container>
    </>
  );
};
export default Terms;

const Container = styled.div`
  text-align: left;
  padding-top: 40px;
  padding-bottom: 40px;
  color: var(--mainBlack);
  font-size: 14px;
  line-height: 20px;
  @media (min-width: 1336.1px) {
    width: 1200px;
    margin: auto;
  }
  @media (max-width: 1336px) {
    width: 1024px;
    margin: auto;
  }
  @media (max-width: 1200px) {
    width: 90%;
    margin: auto;
  }
  img {
    margin-bottom: 40px;
    width: 150px;
    height: 48px;
  }
  h2 {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.8px;
  }
  p {
    color: rgba(117, 117, 117, 1);
    width: 100%;
    font-size: 12px;
    text-align: right;
    margin-left: auto;
  }
`;
const Privacy = styled.div`
  width: 90%;
  margin-left: auto;
`;
