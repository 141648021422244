import React, { useEffect } from "react";
// import styled from "styled-components";
// import Logo from "../assets/logo.svg";
// import DropDown from "../assets/dropdown.svg";
// import { Link } from "react-router-dom";
import Main from "../Constants/Main";
import { httprequeset } from "../Constants/Constant";
import { useNavigate } from "react-router-dom";

// interface Props {
//   navToggle: boolean;
// }

const Index: React.FC = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    httprequeset
      .get(`/auth/profile`)
      .then((res) => {})
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/");
        }
      });
  }, [navigate]);
  return (
    <>
      <Main
        tabName="Clients"
        rightSubHeader={<>Hello World</>}
        mainBody={<>This is the main Body of each Page</>}
      />
    </>
  );
};

export default Index;
