import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import Logo from "../../assets/logo-colored.svg";
import authImage from "../../assets/authImage.jpg";
import create from "../../assets/create.svg";
import join from "../../assets/join.svg";

type Props = {}

const NoAccount = (props: Props) => {
  return (
    <Wrapper>
        <Left>
            <Content>
        <IconContainer>
            <Link to="/">
              <img src={Logo} alt="Estate IQ" />
            </Link>
          </IconContainer>
          <SignUpContainer>
              <Link to="/auth/create/estate">
                <EstateCTA>
                  <img src={create} alt="Create Estate IQ" />
                  <RightModalContent>
                    <h2>Create an Estate</h2>
                    <p>
                      Sign up as an estate admin and onboard members of your
                      estate
                    </p>
                  </RightModalContent>
                </EstateCTA>
              </Link>
              <Link to="/auth/join/estate">
                <EstateCTA>
                  <img src={join} alt="Join Estate IQ" />
                  <RightModalContent>
                    <h2>Join an Estate</h2>
                    <p>
                    Sign up as a member of an existing estate
                    </p>
                  </RightModalContent>
                </EstateCTA>
              </Link>
            </SignUpContainer>
          </Content>
        </Left>
        <Right>

        </Right>
    </Wrapper>
  )
}

export default NoAccount
const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;
const Left = styled.div`
  width: 65%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const Right = styled.div`
  width: 35%;
  height: 1000px;
  background-image: url(${authImage});
  background-size: cover;
  @media(min-width:1366px){
    height:100vh;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;
const Content = styled.div`
  width: 500px;
  margin: auto;
  padding-top: 80px;
  @media (min-width: 1367px) {
    padding-top:100px;
  }
  @media (max-width: 1240px) {
    padding-top: 40px;
  }
  @media (max-width: 576px) {
    width: 80%;
    margin: auto;
  }
`;
const IconContainer = styled.div`
  text-align: left;
  img {
    width: 135px;
    height: 64px;
  }
  @media (max-width: 991px) {
    text-align: left;
    img {
      width: 111px;
      height: 29px;
    }
  }
`;
const SignUpContainer = styled.div`
 padding-top:30px;
  width: 500px;
  
  @media (max-width: 1100px) {
    
  }
  @media (max-width: 576px) {
    width: 300px;
    padding: 10px;
  }
  a {
    text-decoration: none;
  }
`;
const EstateCTA = styled.div`
  display: flex;
  background-color: #fbfbfb;
  border-radius: 20px;
  // width:100%;
  padding: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  :hover {
    cursor: pointer;
  }
  img {
    margin-right: 10px;
  }
  @media (max-width: 576px) {
    img {
      width: 50px;
      height: 50px;
    }
  }
`;
const RightModalContent = styled.div`
  text-align: left;
  h2 {
    font-weight: 700;
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 5px;
    letter-spacing: -2px;
    color: black;
  }
  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 10px;
    letter-spacing: -0.8px;
    color: black;
  }
`;
