import React, { useState } from "react";
import styled from "styled-components";
import IconImage from "../../assets/estate.png";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Success from "../Constants/Success";
// import Switch from "@material-ui/core/Switch";
import loader from "../../assets/loader.gif";
import Modal from "../Constants/Modal";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
// import { httprequeset } from "../Constants/Constant";

type Props = {
  firstName: string;
  lastName: string;
  email: string;
  key: any;
  memberId: any;
  status: any;
  position: any;
  phoneNumber: any;
};

const MemberTable = (props: Props) => {
  //   const navigate = useNavigate();
  // const [approve, setApproved] = useState({ active: true });
  const [toggleActivate, setToggleActivate] = useState(false);
  // const [activateLoading, setActivateLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleActivateToggle = () => {
    setToggleActivate(!toggleActivate);
  };
  const CloseSuccessModal = () => {
    setSuccess(!success);
  };
  // const handleApproval =
  //   (id: any) => async (event: React.FormEvent<HTMLInputElement>) => {
  //     handleActivateToggle();
  //     setActivateLoading(true);
  //     setSuccess(false);
  //   try {
  //     const response = await httprequeset.patch(`/estate-members/${id}`, {
  //       status: "active",
  //     });
  //     console.log(response);
  //     if (response.status === 200) {
  //       setActivateLoading(false);
  //       setSuccess(true);
  //       handleUserProfile();
  //     }
  //   } catch (err:any) {
  //     console.log(err);
  //     setSuccess(false);
  //     setActivateLoading(false);
  //     if(err.response.status===401){
  //       return navigate('/')
  //     }
  //   }
  // const checked = estateMembers?.find(
  //   (members: any) => members.memberId===id
  // );
  // console.log(checked)

  // setApproved({
  //   ...approve,
  //   [event.currentTarget.name]: event.currentTarget.checked,
  // });
  // };
  return (
    <Member key={props.memberId}>
      <DP>
        <img src={IconImage} alt="Estate IQ" />
        <h4>{props.firstName + " " + props.lastName}</h4>
      </DP>
      <EName>{props.email}</EName>

      <Position>{props.position}</Position>
      <EName>{props.phoneNumber}</EName>
      <CTA>
        <div>
          <EmailOutlinedIcon />
        </div>
        <div>
          <LocalPhoneOutlinedIcon />
        </div>
      </CTA>
      <Delete>
        <DeleteOutlinedIcon />
      </Delete>
      {/* <Approve>
        <FormControlLabel
          control={
            <Switch
              checked={props.status === "active" ? true : false}
              onChange={handleApproval(props.memberId)}
              color="primary"
              name="active"
              size="small"
            />
          }
          label={
            <StyledLabel>
              {props.status === "active" ? " Activated" : "Deactivated"}
            </StyledLabel>
          }
        />
      </Approve> */}
      <Modal
        toggleModal={handleActivateToggle}
        body={
          <>
            {/* {activateLoading && ( */}
            <Loader>
              <img src={loader} alt="loading Activation" />
            </Loader>
            {/* )} */}
            {success ? (
              <>
                <Modal
                  heading=""
                  toggleModal={CloseSuccessModal}
                  modal={success}
                  body={
                    <>
                      <Success
                        messageOne=""
                        messageTwo="Successfully Approved"
                        action={() => {
                          CloseSuccessModal();
                          handleActivateToggle();
                        }}
                        actionText="Back"
                      />
                    </>
                  }
                />
              </>
            ) : null}
          </>
        }
        heading="Activate Resident"
        modal={toggleActivate}
      />
    </Member>
  );
};
const Member = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  height: 71px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
  @media (max-width: 426px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
const EName = styled.div`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -0.7px;
  color: var(--mainBlack);
  @media (max-width: 1200px) {
    font-size: 12px;
  }
  @media(max-width:426px){
    font-size:10px;
  }
`;
const DP = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 31px;
    height: 31px;
    border-radius: 50%;
  }
  h4 {
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 12px;
    font-size: 14px;
    letter-spacing: -0.8px;
  }
  @media (max-width: 1200px) {
    h4 {
      font-size: 12px;
    }
  }
  @media (max-width: 426px) {
    img {
      width: 24px;
      height: 24px;
    }
    h4{
     
        font-size:10px;
      
    }
  }
`;
const Position = styled.div`
  margin-left: 25px;
  // width: 100px;
  text-align: left;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: -0.8px;
  color: var(--mainBlack);
  @media (max-width: 1200px) {
    font-size: 12px;
    width: 230px;
    margin-left: 15px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

// const Approve = styled.div`
//   width: 100px;
//   align-items: center;
//   label {
//     font-size: 12px;
//   }
//   @media(max-width:576px){
//     display:none;
//   }
// `;
const Loader = styled.div`
  width: 100%;
  text-align: center;
  img {
    width: 40px;
    margin: auto;
  }
`;
// const StyledLabel = styled.span`
//   font-weight: 700;
//   font-size: 12px;
//   letter-spacing: -0.8px;
// `;
const CTA = styled.div`
  display: flex;
  width: 58px;
  justify-content: space-between;
  div {
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid var(--mainBlue);
    :hover {
      background-color: var(--mainBlue);
      svg {
        color: white;
      }
    }
  }
  svg {
    width: 13px;
    height: 13px;
    color: var(--mainBlue);
  }
  @media (max-width: 426px) {
    display: none;
  }
`;
const Delete = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: white;
  color: var(--mainBlue);
`;

export default MemberTable;
