import * as React from "react";
import styled from "styled-components";
import Error404 from "../../assets/404.svg";
import {useNavigate} from 'react-router-dom';


export default function FourZeroFour() {
  const navigate =useNavigate();
  return (
    <Wrapper>
      <Container>
      <img src={Error404} alt="Estate IQ 404" />
      <TextSection>
        <h1>404</h1>
        <p>Page Not Found</p>
      </TextSection>
      <CTA onClick={()=>{navigate(-1)}}>Go back</CTA>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
width:100%;
height:100vh;
display:flex;
align-items:center;
justify-content:center;
`;
const Container= styled.div `

img {
  width: 340px;
  height: 340px;
}
`
const TextSection = styled.div`
  h1 {
    font-size: 128px;
    letter-spacing: -2px;
    // line-height: 106px;
    margin-top:-100px;
    margin-bottom:-40px;
  }
  p {
    font-size: 20px;
    color: var(--mainBlue);
    letter-spacing: -0.8px;
  }
`;
const CTA = styled.button`
  background-color: var(--mainBlue);
  display: flex;
  align-items:center;
  justify-content:center;
  width: 333px;
  height: 41px;
  margin-top:40px;
  color: white;
  border-color:transparent;
  font-size:16px;
  border-radius:6px;
  :hover{
    cursor:pointer
  }
`;
