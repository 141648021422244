import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import Logo from "../assets/logo.svg";
// import DropDown from "../assets/dropdown.svg";
import { useNavigate } from "react-router-dom";
// import Modal from "../Constants/Modal";
import Main from "../Constants/Main";
import AdminProfile from "./AdminProfile";
import CreateNew from "../Settings/CreateNew";
import JoinNew from "../Settings/JoinNew";
import { httprequeset } from "../Constants/Constant";

const Index: React.FC = (props) => {
  const [joinEstate, setJoinEstate] = useState(false);
  const [createEstate, setCreateEstate] = useState(false);
  // const [editProfile,setEditProfile] =useState(false);
  const handleJoinEstate = () => {
    setJoinEstate(!joinEstate);
  };
  const handleCreateEstate = () => {
    setCreateEstate(!createEstate);
  };
  // const handleEditProfile=()=>{
  //     setEditProfile(!editProfile)
  // }
  const navigate = useNavigate();
  useEffect(()=>{
    httprequeset.get(`/auth/profile`)
      .then((res) => {  
      })
    .catch((err) => {
         if(err.response.status===401){
          navigate('/')
         }
       });
  },[navigate])
  return (
    <>
      <Main
        tabName="Profile Setting"
        rightSubHeader={
          !joinEstate && !createEstate ? (
            <>
              <JoinEstate onClick={handleJoinEstate}>
                Join New Estate
              </JoinEstate>
              <CreateNewEstate onClick={handleCreateEstate}>
                Create New Estate
              </CreateNewEstate>
              {/* <EditProfile onClick={handleEditProfile}>Edit your Profile</EditProfile> */}
            </>
          ) : null
        }
        mainBody={
          <>
            {joinEstate ? (
              <JoinNew closeFormToggle={handleJoinEstate} />
            ) : createEstate ? (
              <CreateNew closeFormToggle={handleCreateEstate} />
            ) : (
              // editProfile ? (<>Edit Profile</>) :
              <AdminProfile />
            )}
          </>
        }
      />
    </>
  );
};
export default Index;

const JoinEstate = styled.button`
  width: 200px;
  height: 50px;
  color: white;
  background-color: var(--mainPurple);
  outline: 0;
  border: 1px solid var(--mainPurple);
  margin-right: 15px;
  font-size: 12px;
  border-radius: 5px;

  :hover {
    color: #cecece;
    border: transparent;
  }
`;
const CreateNewEstate = styled.button`
  width: 200px;
  height: 50px;
  color: white;
  background-color: var(--mainBlue);
  outline: 0;
  border: 1px solid var(--mainBlue);
  margin-right: 15px;
  font-size: 12px;
  border-radius: 5px;

  :hover {
    color: #cecece;
    border: transparent;
  }
`;
// const EditProfile = styled.button `
// width: 200px;
// height: 50px;
// color: var(--mainBlue);
// background-color: white;
// outline: 0;
// border: 1px solid var(--mainBlue);
// margin-right: 15px;
// font-size: 12px;
// border-radius: 5px;

// :hover {
//   color: var(--mainPurple);
//   border: transparent;
// }
// `
