import React, { useState } from "react";
import styled from "styled-components";
import authImage from "../../assets/authImage.jpg";
import Logo from "../../assets/logo-colored.svg";
import { Link,useNavigate } from "react-router-dom";
import loader from "../../assets/loader.gif"
import { adminhttprequest } from "../Constants/Constant";

type Props = {};

const EstateSignup = (props: Props) => {
  const navigate =useNavigate()
  const [formState, setFormState] = useState({
    name: "",
    address: "",
    contactPhoneNumber: "",
    estimatedNumberOfResidents: 0,
    isLoading: false,
    zone:"",
    formError:[],
  });
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };
  const handleSignUp = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormState({ ...formState, isLoading: true,formError:[] });
    const payload = { ...formState };
    adminhttprequest.post("/estates", payload)
      .then((res) => {
        console.log(res)
        if (res.status === 201) { 
          navigate('/')
          setFormState({
            ...formState,
            isLoading: false,
          });         
        }
       
      })
      .catch((err) => {
        setFormState({
          ...formState,
          isLoading: false,
          formError:Object.values(err?.response?.data?.message)
        });
      });
      
  };
  const isDisabled = () => {
    const {name,address,estimatedNumberOfResidents,contactPhoneNumber,zone } = formState;
    if (name && address && estimatedNumberOfResidents && contactPhoneNumber && zone) {
      return false;
    }
    return true;
  };
  return (
    <Wrapper>
      <Left>
        <Content>
          <IconContainer>
            <Link to="/">
            <img src={Logo} alt="Estate IQ" />
            </Link>
          </IconContainer>
          <FormHeader>
            <h3>Create Estate</h3>
          </FormHeader>
          <StyledForm onSubmit={handleSignUp}>
            <FormGroup>
              <label>Estate Name</label>
              <input type="text" name="name" 
              value={formState.name} 
              onChange={handleChange}/>
            </FormGroup>
            <FormGroup>
              <label>Address</label>
              <input type="address" name="address" 
              value={formState.address} 
              onChange={handleChange}/>
            </FormGroup>
            <FormGroup>
              <label>Contact Phone Number</label>
              <input type="tel" name="contactPhoneNumber" 
              value={formState.contactPhoneNumber} 
              onChange={handleChange} />
            </FormGroup>
            <FormGroup>
              <label>Total Estimated Number of Residents</label>
              <input
                type="number"
                name="estimatedNumberOfResidents"
                onChange={handleChange}
                value={formState.estimatedNumberOfResidents}
              />
            </FormGroup>
            <FormGroup>
              <label>Zone</label>
              <input type="address" name="zone" onChange={handleChange} />
            </FormGroup>

            <ButtonContainer>
            {formState.isLoading ? (
                <LoadingButton>
                  <img src={loader} alt="Estate IQ" />
                </LoadingButton>
              ) : (
                <StyledButton
                  type="submit"
                  disabled={isDisabled()}
                  value="Create Estate"
                />
              )}
              {formState.formError.length < 2? (
                formState.formError.map((err:any,i)=>{                
                  return <FormErr key={i}>{err}</FormErr>  
})
              ): <FormErr>{formState.formError}</FormErr>}
            
            </ButtonContainer>
          </StyledForm>
        </Content>
      </Left>
      <Right></Right>
    </Wrapper>
  );
};

export default EstateSignup;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;
const Left = styled.div`
  width: 65%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const Right = styled.div`
  width: 35%;
  height: 1000px;
  background-image: url(${authImage});
  background-size: cover;
  @media(min-width:1366px){
    height:100vh;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;
const Content = styled.div`
  width: 500px;
  margin: auto;
  padding-top: 40px;
  @media (min-width: 1367px) {
    padding-top: 85px;
  }
  @media (max-width: 1240px) {
    padding-top: 40px;
  }
  @media (max-width: 576px) {
    width: 80%;
    margin: auto;
  }
`;
const IconContainer = styled.div`
  text-align: left;
  img {
    width: 135px;
    height: 64px;
  }
  @media (max-width: 991px) {
    text-align: left;
    img {
      width: 111px;
      height: 29px;
    }
  }
`;
const FormHeader = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: left;
  h3 {
    margin-top: 0px;
    margin-bottom: 1px;
    font-size: 38px;
    letter-spacing: -2px;
    color: #111111;
    font-weight: bold;
  }
  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 12px;
    color: #545454;
  }
  @media (min-width: 1367px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  @media (max-width: 1240px) {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  @media (max-width: 576px) {
    text-align: left;
    margin-top: 50px;
    h3 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
      letter-spacing: -1px;
    }
    p {
      font-size: 12px;
    }
  }
`;
const StyledForm = styled.form`
  width: 100%;
`;
const FormErr = styled.div `
font-size: 10px;
margin-top: 2px;
margin-bottom:2px;
color: tomato;
text-align: right;
.err {
  font-size: 10px;
  margin-top: 0px;
  color: tomato;
  text-align: left;
}
.login_from_signup{
  width:100%;
  text-align:right;
  margin-left:auto;
}
`
const FormGroup = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  input {
    width: 97%;
    background: #fbfbfb;
    border: 1px solid #c5cae4;
    border-radius: 4px;
    height: 58px;
    padding-left: 10px;
  }
  label {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #686868;
  }
  @media (max-width: 1366px) {
    input {
      height: 48px;
    }
  }
`;
const StyledButton = styled.input`
width: 363px;
height: 51px;
border-color: transparent;
border-radius: 6px;
color: white;
background-color: var(--mainBlue);
font-size: 12px;
margin-bottom: 10px;
:disabled {
  background-color: #ccc;
  color: black;
}
:disabled:hover {
  background-color: #ccc;
  color: black;
}
@media (max-width: 426px) {
  width: 100%;
  margin: auto;
}
:hover {
  background-color: #f2cd16;
  color: var(--mainBlue);
}
`;
const LoadingButton = styled.button`
  width: 363px;
  height: 51px;
  border-color: transparent;
  border-radius: 6px;
  color: white;
  background-color: var(--mainBlue);
  font-size: 12px;
  margin-bottom: 10px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 60px;
    // height:45px;
  }
  @media (max-width: 426px) {
    width: 100%;
    margin: auto;
  }
  :hover {
    background-color: #f2cd16;
    color: var(--mainBlue);
  }
`;
const ButtonContainer = styled.div`
  margin-top: 25px;
  text-align: right;
  a {
    width: 363px;
    padding: 12px 88px;
    font-size: 16px;
    color: white;
    font-weight: 400;
    text-decoration: none;
  }
  @media (min-width: 1367px) {
    margin-top: 70px;
  }
  @media (max-width: 576px) {
    text-align: center;
    a {
      font-size: 12px;
    }
  }
`;