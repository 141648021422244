import React, { useState, useEffect } from "react";
import styled from "styled-components";
import authImage from "../../assets/authImage.jpg";
import Logo from "../../assets/logo-colored.svg";
import { Link, useNavigate } from "react-router-dom";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import axios from "axios";
import { BASEURL } from "../Constants/Constant";
import { adminhttprequest } from "../Constants/Constant";
import loader from "../../assets/loader.gif";
import Alert from "../Constants/Alert";

type Props = {};
const JoinEstate = (props: Props) => {
  const [allEstates, setAllEstates] = useState([]);
  const [hideInput, setHideInput] = useState(false);
  const [formState, setFormState] = useState({
    estateId: "",
    role: "resident",
    estateName: "",
    isLoading: false,
    formError: [],
    queryResult: [],
    createSuccess: false,
  });
  const handleHide = () => {
    setHideInput(!hideInput);
  };
  // const ClearForm = () => {
  //   setFormState({
  //     ...formState,
  //     estateId: "",
  //     role: "resident",
  //     estateName: "",
  //     isLoading: false,
  //     formError: [],
  //     queryResult: [],
  //   });
  // };
  const navigate = useNavigate();
  const closeAlertModal = () => {
    setFormState({ ...formState, createSuccess: false, formError: [] });
  };
  useEffect(() => {
    axios
      .get(`${BASEURL}/estates`)
      .then((res) => {
        // console.log(res)
        setAllEstates(res?.data?.estates);
      })

      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          navigate("/");
        }
      });
  }, [navigate]);
  const SearchQuery = (event: React.FormEvent<HTMLInputElement>) => {
    const input = event.currentTarget.value;
    const result = allEstates?.filter((estate: any) => {
      return estate.name.toLowerCase().indexOf(input.toLowerCase()) > -1;
    });
    if (result?.length > 0) {
      setSearchModal(!searchModal);
      setFormState({ ...formState, queryResult: result });
    }
    setFormState({
      ...formState,
      queryResult: result,
    });
  };
  const [searchModal, setSearchModal] = useState(false);

  const chooseEstate = (id: any, name: any) => {
    handleHide();
    setFormState({
      ...formState,
      estateId: id,
      estateName: name,
    });
  };

  const handleSignUp = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    closeAlertModal();
    setFormState({ ...formState, isLoading: true, formError: [] });
    const payload = { ...formState };
    // console.log(payload);
    adminhttprequest.post(`/estates/${formState?.estateId}/join`, payload)
      .then((res) => {
        if (res.status === 201) {
          navigate("/");
          setFormState({
            ...formState,
            estateName: "",
            estateId: "",
            createSuccess: true,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        setFormState({
          ...formState,
          createSuccess: false,
          isLoading: false,
          formError: Object.values(err?.response?.data?.message),
        });
      });
  };
  const isDisabled = () => {
    if (formState.estateId) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Wrapper
      onClick={() => {
        setSearchModal(false);
      }}
    >
      <Left>
        <Content>
          <IconContainer>
            <Link to="/">
              <img src={Logo} alt="Estate IQ" />
            </Link>
          </IconContainer>
          <FormHeader>
            <h3>Join an Estate</h3>
          </FormHeader>
          <StyledForm onSubmit={handleSignUp}>
            <FormGroup>
              <label>Choose Estate</label>
              {hideInput ? (
                <input
                  type="text"
                  onMouseEnter={handleHide}
                  value={formState.estateName}
                />
              ) : (
                <input type="text" name="estateName" onChange={SearchQuery} />
              )}
              <FormIcon>
                <SearchOutlinedIcon />
              </FormIcon>
            </FormGroup>
            {searchModal ? (
              <SearchForm>
                <SearchModal>
                  {formState.queryResult?.map((query: any, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => {
                          chooseEstate(query.id, query.name);
                        }}
                      >
                        {query?.name}
                      </div>
                    );
                  })}
                </SearchModal>
              </SearchForm>
            ) : null}
            {/* <Success>{formState?.createSuccess}</Success> */}
            <ButtonContainer>
              {formState.isLoading ? (
                <LoadingButton>
                  <img src={loader} alt="Estate IQ" />
                </LoadingButton>
              ) : (
                <StyledButton
                  type="submit"
                  disabled={isDisabled()}
                  value="Sign Up"
                />
              )}
              {/* {formState.formError.length < 2 ? (
                formState.formError.map((err: any) => {
                  return <FormErr>{err}</FormErr>;
                })
              ) : (
                <FormErr>{formState.formError}</FormErr>
              )} */}
            </ButtonContainer>
            <div className="login_from_signup">
              <Link to="/">You have an account? Click here to login</Link>
            </div>
          </StyledForm>
        </Content>
        {formState.createSuccess ? (
          <>
            <Alert
              alertSeverity="success"
              alertTitle="Please confirm your email; Await Admittance into estate from admin"
              open={formState.createSuccess}
              closeModal={closeAlertModal}
            />
          </>
        ) : null}
        {formState.formError.length < 2 ? (
          formState.formError.map((err: any) => {
            return (
              <Alert
                alertSeverity="warning"
                alertTitle={err}
                open={formState.formError.length ? true : false}
                closeModal={closeAlertModal}
              />
            );
          })
        ) : (
          <Alert
            alertSeverity="warning"
            alertTitle={formState.formError}
            open={formState.formError.length ? true : false}
            closeModal={closeAlertModal}
          />
        )}
      </Left>
      <Right></Right>
    </Wrapper>
  );
};

export default JoinEstate;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
`;
const Left = styled.div`
  width: 65%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const Right = styled.div`
  width: 35%;
  height: 1000px;
  background-image: url(${authImage});
  background-size: cover;
  @media (min-width: 1366px) {
    height: 100vh;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;
const Content = styled.div`
  width: 500px;
  margin: auto;
  padding-top: 40px;
  @media (min-width: 1367px) {
    padding-top: 85px;
  }
  @media (max-width: 1240px) {
    padding-top: 40px;
  }
  @media (max-width: 576px) {
    width: 80%;
    margin: auto;
  }
`;
const IconContainer = styled.div`
  text-align: left;
  img {
    width: 135px;
    height: 64px;
  }
  @media (max-width: 991px) {
    text-align: left;
    img {
      width: 111px;
      height: 29px;
    }
  }
`;
const FormHeader = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: left;
  h3 {
    margin-top: 0px;
    margin-bottom: 1px;
    font-size: 38px;
    letter-spacing: -2px;
    color: #111111;
    font-weight: bold;
  }
  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 12px;
    color: #545454;
  }
  @media (min-width: 1367px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  @media (max-width: 1240px) {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  @media (max-width: 576px) {
    text-align: left;
    margin-top: 50px;
    h3 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
      letter-spacing: -1px;
    }
    p {
      font-size: 12px;
    }
  }
`;
const StyledForm = styled.form`
  width: 100%;
  .err {
    font-size: 10px;
    margin-top: 0px;
    color: tomato;
    text-align: left;
  }
  .login_from_signup {
    width: 100%;
    text-align: right;
    margin-left: auto;
  }
`;
const SearchForm = styled.div`
  position: absolute;
  margin-top: -10px;
  width: 497px;
  z-index: 5;
  background: white;

  border-radius: 5px;
  border: 1px solid #c5cae4;
`;
const SearchModal = styled.div`
  div {
    padding-left: 10px;
    paddding-right: 10px;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    :hover {
      background-color: #ccc;
      cursor: pointer;
    }
  }
`;
// const FormErr = styled.div`
//   font-size: 10px;
//   margin-top: 2px;
//   margin-bottom: 2px;
//   color: tomato;
//   text-align: right;
// `;
// const FlexFormGroup = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 102%;
//   div {
//     width: 50%;
//     text-align: left;
//   }
//   input {
//     width: 90%;
//     background: #fbfbfb;
//     border: 1px solid #c5cae4;
//     border-radius: 4px;
//     height: 58px;
//     padding-left: 10px;
//     position: relative;
//   }
//   label {
//     width: 100%;
//     font-size: 12px;
//     font-weight: 400;
//     color: #686868;
//   }
//   @media (max-width: 1366px) {
//     input {
//       height: 48px;
//     }
//   }
//   @media (max-width: 576px) {
//     flex-direction: column;
//     width: 97%;
//     div {
//       width: 100%;
//       margin-top: 5px;
//       margin-bottom: 5px;
//     }
//     input {
//       width: 100%;
//     }
//   }
// `;
const FormGroup = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  position: relative;
  input {
    width: 97%;
    background: #fbfbfb;
    border: 1px solid #c5cae4;
    border-radius: 4px;
    height: 58px;
    padding-left: 10px;
  }
  label {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #686868;
  }
  @media (max-width: 1366px) {
    input {
      height: 48px;
    }
  }
`;
const FormIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 45px;

  svg {
    font-size: 14px;
  }
`;
const StyledButton = styled.input`
  width: 363px;
  height: 51px;
  border-color: transparent;
  border-radius: 6px;
  color: white;
  background-color: var(--mainBlue);
  font-size: 12px;
  margin-bottom: 10px;
  :disabled {
    background-color: #ccc;
    color: black;
  }
  :disabled:hover {
    background-color: #ccc;
    color: black;
  }
  @media (max-width: 426px) {
    width: 100%;
    margin: auto;
  }
  :hover {
    background-color: #f2cd16;
    color: var(--mainBlue);
  }
`;
// const Success = styled.p`
//   color: green;
//   font-size: 13px;
//   letter-spacing: -0.6px;
//   text-align: right;
// `;
const LoadingButton = styled.button`
  width: 363px;
  height: 51px;
  border-color: transparent;
  border-radius: 6px;
  color: white;
  background-color: var(--mainBlue);
  font-size: 12px;
  margin-bottom: 10px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 60px;
    // height:45px;
  }
  @media (max-width: 426px) {
    width: 100%;
    margin: auto;
  }
  :hover {
    background-color: #f2cd16;
    color: var(--mainBlue);
  }
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  text-align: right;
  a {
    width: 363px;
    padding: 12px 88px;
    font-size: 16px;
    color: white;
    font-weight: 400;
    text-decoration: none;
  }
  @media (min-width: 1367px) {
    margin-top: 70px;
  }
  @media (max-width: 576px) {
    text-align: center;
    a {
      font-size: 12px;
    }
  }
`;
