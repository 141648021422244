import axios from "axios";

const BASEURL = "https://estateiq-api.herokuapp.com";

const adminhttprequest = axios.create({
  baseURL: "https://estateiq-api.herokuapp.com",
});
adminhttprequest.interceptors.request.use((config) => {
  const userToken = localStorage.getItem("adminToken");
  if (userToken) {
    config.headers.Authorization = `Bearer ${userToken}`;
  }
  return config;
});

const httprequeset = axios.create({
  baseURL: "https://estateiq-api.herokuapp.com",
});

httprequeset.interceptors.request.use((config) => {
  const token = localStorage.getItem("isAuthenticated");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { BASEURL, httprequeset, adminhttprequest };

// const validateInput = (event: React.FormEvent<HTMLInputElement>) => {
//   let { name, value } = event.currentTarget;
//   setError((prev: any) => {
//     const stateObj = { ...prev, [name]: "" };

//     switch (name) {
//       case "firstName":
//         if (!value) {
//           stateObj[name] = "Please Enter your First Name";
//         }
//         break;
//       case "lastName":
//         if (!value) {
//           stateObj[name] = "Please Enter your Last Name";
//         }
//         break;
//       case "contactPhoneNumber":
//         if (!value) {
//           stateObj[name] = "Enter your Phone Number";
//         }
//         break;
//       case "email":
//         if (!value) {
//           stateObj[name] = "Please Enter your Email";
//         }
//         break;
//       case "password":
//         if (!value) {
//           stateObj[name] = "Please Enter Password";
//         } else if (formState.cfPassword && value !== formState.cfPassword) {
//           stateObj["cfPassword"] =
//             "Password and Confrim Pasword does not match";
//         } else {
//           stateObj["cfPassword"] = formState.cfPassword
//             ? ""
//             : error.cfPassword;
//         }
//         break;
//       case "cfPassword":
//         if (!value) {
//           stateObj[name] = "Please enter Confirm password";
//         } else if (formState.password && value !== formState.password) {
//           stateObj[name] = "Password and Confrim Pasword does not match";
//         }
//         break;
//       default:
//         break;
//     }

//     return stateObj;
//   });
// };
