import React,{useEffect} from "react";
import Main from "../Constants/Main";
import { VisitorAccessButton, FilterByDate } from "../Constants/Snippets";
import {useNavigate} from 'react-router-dom';
import { httprequeset } from "../Constants/Constant";
// interface Props {
//   navToggle: boolean;
// }

const Index: React.FC = (props) => {
  const navigate = useNavigate();
  useEffect(()=>{
    httprequeset.get(`/auth/profile`)
      .then((res) => {  
      })
    .catch((err) => {
         if(err.response.status===401){
          navigate('/')
         }
       });
  },[navigate])
  return (
    <>
      <Main
        tabName="Tasks"
        rightSubHeader={
          <>
            <>
              <FilterByDate /> <VisitorAccessButton />
            </>
          </>
        }
        mainBody={<>Coming Soon . . . .</>}
      />
    </>
  );
};

export default Index;
