import React, { useState, useContext } from "react";
import styled from "styled-components";
import KeyboardTabOutlinedIcon from "@mui/icons-material/KeyboardTabOutlined";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";
import { AppContext } from "../../Context";
import Modal from "../Constants/Modal";
interface IconProp {
  in: boolean;
}
export default function ExternalsDashboard() {
  const { estateVisits } = useContext(AppContext);
  const [codeDetails, setCodeDetails] = useState<any>([]);
  // const [toggleState, setToggleState] = useState("externalvisitors");
  // console.log(estateVisits);
  const [residentDetails, setResidentDetails] = useState(false);
  // const [visitorIn, setVisitorIn] = useState(true);

  // const handleExternalVisitors = () => {
  //   setToggleState("externalvisitors");
  // };
  // const handleExternalResidents = () => {
  //   setToggleState("externalresidents");
  // };
  const handleResidentDetails = (access: any) => {
    setResidentDetails(!residentDetails);
    const codeDetails = estateVisits?.visits.filter(
      (visit: any) => visit?.accessCode === access
    );
    // console.log(codeDetails)
    setCodeDetails(codeDetails);
  };
  // console.log(estateVisits)
  return (
    <Wrapper>
      <Title>Recent Activity</Title>
      {/* <ToggleActivityContainer>
      <ToggleButton
        style={{
          borderBottom:
            toggleState === "externalvisitors"
              ? "10px solid #2D4BF3"
              : "transparent",
          color:
            toggleState === "externalvisitors"
              ? "#686868"
              : "rgba(104, 104, 104, 0.2)",
        }}
        onClick={handleExternalVisitors}
      >
        Visitors
      </ToggleButton>
      <ToggleButton
        style={{
          borderBottom:
            toggleState === "externalresidents"
              ? "10px solid #2D4BF3"
              : "transparent",
          color:
            toggleState === "externalresidents"
              ? "#686868"
              : "rgba(104, 104, 104, 0.2)",
        }}
        onClick={handleExternalResidents}
      >
        Resident
      </ToggleButton>
    </ToggleActivityContainer> */}
      <TableHeader>
        <CardFlex>
          <IconHeader />
          <GName>Guest Name</GName>
          <GPhoneNo>Guest Phone Number</GPhoneNo>
          <AccessCode>Code</AccessCode>
          <HName>Host Name</HName>

          <DateAndTime>Date & Time</DateAndTime>
          <ActionHeader>Action</ActionHeader>
        </CardFlex>
      </TableHeader>
      {estateVisits?.visits?.map((visit: any) => {
        return (
          <>
            <CardContainer
              onClick={() => {
                handleResidentDetails(visit?.accessCode);
              }}
            >
              <CardFlex>
                <IconBG in={visit?.status === "accepted" ? true : false}>
                  {visit?.status === "accepted" ? (
                    <KeyboardTabOutlinedIcon />
                  ) : (
                    <KeyboardReturnOutlinedIcon />
                  )}
                </IconBG>
                <GName>{visit?.visitor?.fullName}</GName>
                <GPhoneNo>{visit?.visitor?.phoneNumber}</GPhoneNo>
                <AccessCode>{visit?.accessCode}</AccessCode>
                <HName>{visit?.host?.firstName + visit?.host?.lastName}</HName>

                <DateAndTime>
                  <p>{visit?.endDate}</p>
                  {/* <p>9:00AM</p> */}
                </DateAndTime>
                <Action in={visit?.status === "accepted" ? true : false}>
                  {visit?.status === "pending"
                    ? "Code Issued"
                    : visit?.status === "accepted"
                    ? "Signed In"
                    : "Signed Out"}
                </Action>
              </CardFlex>
            </CardContainer>

            <Modal
              heading="Visitor Info"
              modal={residentDetails}
              toggleModal={handleResidentDetails}
              body={
                <>
                  <AccessWrapper>
                    <VisitorDetails>
                      <div>
                        <p>Host Name</p>
                        <h3>
                          {" "}
                          {codeDetails?.[0]?.host?.firstName +
                            " " +
                            codeDetails?.[0]?.host?.lastName}
                        </h3>
                        <p>Guest's Name</p>
                        <h3>{codeDetails?.[0]?.visitor?.fullName}</h3>
                        <p>Visitor's Phone Number</p>
                        <h3>{codeDetails?.[0]?.visitor?.phoneNumber}</h3>
                      </div>
                      <div>
                        <p>Host Address</p>
                        <h3> {codeDetails?.[0]?.host?.address}</h3>
                        <p>Visitor's Email Address</p>
                        <h3>{codeDetails?.[0]?.visitor?.email}</h3>
                      </div>
                    </VisitorDetails>

                    <AccessCodeDetails>
                      {codeDetails?.[0]?.accessCode}
                    </AccessCodeDetails>
                    <SignedIn
                      in={
                        codeDetails?.[0]?.status === "accepted" ? true : false
                      }
                    >
                      {codeDetails?.[0]?.status === "pending"
                        ? "Code Issued"
                        : codeDetails?.[0]?.status === "accepted"
                        ? "Signed In"
                        : "Signed Out"}
                    </SignedIn>
                  </AccessWrapper>
                </>
              }
            />
          </>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  // width: 592px;
  text-align: left;
  @media (max-width: 991px) {
    margin: auto;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
// const ToggleActivityContainer = styled.div`
//   display: flex;
//   align-items: center;
//   margin-top: 30px;
//   margin-bottom: 30px;
// `;
// const ToggleButton = styled.button`
//   width: 187px;
//   height: 49px;
//   background: #fbfbfb;
//   font-size: 12px;
//   letter-spacing: -0.6px;
//   font-weight: bold;
//   outline: 0;
//   border-color: transparent;
//   :hover {
//     cursor: pointer;
//   }
// `;
const Title = styled.div`
  color: #171717;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 15px;
  @media (max-width: 576px) {
    font-size: 14px;
  }
`;
const TableHeader = styled.div`
  background-color: #f4f6fe;
  border-radius: 10px;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  @media (max-width: 426px) {
    padding: 20px 10px;
  }
`;
const CardContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 10px 20px;
  @media (max-width: 426px) {
    padding: 20px 10px;
  }
  :hover {
    background-color: #fbfbfb;
    cursor: pointer;
  }
`;
const CardFlex = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  p {
    font-size: 12px;
    letter-spacing: -0.7px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 500;
  }
  @media (max-width: 426px) {
    p {
      font-size: 12px;
    }
  }
`;
const IconBG = styled.div<IconProp>`
  background-color: ${(props) =>
    props.in ? "#e5f8e6" : "rgba(181, 12, 12, 0.2)"}};
  border-radius: 5px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:${(props) => (props.in ? "#00ba07" : "#B50C0C")} ;
  font-size: 12px;
  @media(max-width:768px){
    margin-right:10px;
  }
  @media(max-width:426px){
    width:24px;
    height:24px;
    // margin-right:10px;
  }
`;
const IconHeader = styled.div`
  width: 32px;
  @media (max-width: 768px) {
    margin-right: 10px;
  }
`;
const ActionHeader = styled.div`
  letter-spacing: -0.7px;
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  width: 60px;
`;

const DateAndTime = styled.div`
  letter-spacing: -0.7px;
  font-weight: bold;
  font-size: 12px;
  width: 100px;
  p {
    letter-spacing: -0.7px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 500;
  }
  @media (max-width: 426px) {
    p {
      font-size: 12px;
    }
  }
`;
const GName = styled.div`
  letter-spacing: -0.7px;
  font-weight: 500;
  font-size: 12px;
  width: 118px;
  text-align: left;
`;
const GPhoneNo = styled.div`
  letter-spacing: -0.7px;
  font-weight: 500;
  font-size: 12px;
  width: 110px;
  text-align: left;
  @media (max-width: 576px) {
    display: none;
  }
`;
const AccessCode = styled.div`
  letter-spacing: -0.7px;
  font-weight: 500;
  width: 60px;
  text-align: left;
  font-size: 12px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const HName = styled.div`
  letter-spacing: -0.7px;
  font-weight: 500;
  width: 100px;
  font-size: 12px;
  text-align: left;
  @media (max-width: 576px) {
    display: none;
  }
`;

const Action = styled.div<IconProp>`
letter-spacing:-0.7px;
font-weight:500;
font-size:12px;
text-align:left;
width:60px;
color:${(props) => (props.in ? "#00BA07" : "#B50C0C")}};
`;
const AccessWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  p {
    font-size: 12px;
    color: #686868;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  h6 {
    margin-top: 10px;
    font-size: 12px;
    color: var(--mainBlue);
  }
`;
const VisitorDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-bottom: 50px;
  p {
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 2px;
    font-color: #686868;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--mainBlue);
  }
  h3 {
    color: var(--mainBlack);
    letter-spacing: -0.7px;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 12px;
  }
  div {
    width: 45%;
  }
  @media (max-width: 576px) {
    font-size: 11px;
    h3 {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: -0.5px;
    }
  }
`;

const AccessCodeDetails = styled.div`
  width: 373px;
  margin: auto;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f6ff;
  border-radius: 4px;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: -6px;
  color: var(--mainBlue);
  @media (max-width: 426px) {
    width: 80%;
  }
`;
const SignedIn = styled.div<IconProp>`
margin-top:10px;
margin-bottom:0px;
font-size:12px;
font-weight:bold;
color:${(props) => (props.in ? "#00BA07" : "#B50C0C")}};
`;
// const AccessWrapper = styled.div`
//   padding-top: 50px;
//   padding-bottom: 50px;
//   text-align: center;
//   p {
//     font-size: 12px;
//     color: #686868;
//     margin-top: 0px;
//     margin-bottom: 10px;
//   }
//   h6 {
//     margin-top: 10px;
//     font-size: 12px;
//     color: var(--mainBlue);
//   }
// `;
