import React from "react";
import styled from "styled-components";
import adImage from "../../assets/advert.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";


export default function ResidentDashboard() {
  const taskData = [
    {
      title: "Light Bill Expired",
      taskBody: " Your Light Subscription needs renewal, please subscribe",
    },
    {
      title: "Subscription Expired",
      taskBody:
        " Your Monthly Subscription has expired, You might be bared from Entry",
    },
    {
      title: "Security Bill ",
      taskBody:
        " Security Bill for this month has not been paid, Please be reminded",
    },
  ];
  return (
    <Wrapper>
      <AdvertContainer>
        <Title>Adverts</Title>
        <AdvertSection>
          <AdvertImage>
            <img src={adImage} alt="Estate IQ" />
          </AdvertImage>
          <AdvertDetails>
            <h2>Delight Confetionaries</h2>
            <p>
              we are experts at making your favorite confectionaires. Book us
              for your events and functions as we guarantee quality and
              efficiency
            </p>
          </AdvertDetails>
        </AdvertSection>
        <AdvertCTA>
          <button>Call us now</button>
        </AdvertCTA>
      </AdvertContainer>
      <Title>Pending Tasks</Title>

      {taskData.map((task, i) => {
        return (
          <TaskWrapper key={i}>
            <Subscribe>
              <SubIcon>
                <FontAwesomeIcon icon={faTriangleExclamation} />
              </SubIcon>
              <SubscriptionDetails>
                <p>{task.title}</p>
                <h5>{task.taskBody}</h5>
              </SubscriptionDetails>
            </Subscribe>
            <ButtonContainer>
              <button>Make Subscription</button>
            </ButtonContainer>
          </TaskWrapper>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  // width:100%:
  width: 592px;
  text-align: left;
  @media (max-width: 991px) {
    margin: auto;
  }
  @media (max-width: 768px) {
    width: 500px;
  }
  @media (max-width: 576px) {
    width: 400px;
  }
  @media (max-width: 426px) {
    width: 330px;
  }
`;
const Title = styled.div`
  color: #171717;
  font-weight: 700;
  font-size: 18px;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 15px;
  @media (max-width: 576px) {
    font-size: 14px;
  }
`;
const AdvertSection = styled.div`
  display: flex;
  background-color: #fbfbfb;
  border-radius: 10px;
  z-index: 1;
  position: relative;
  padding: 20px;
  text-align: left;
  @media (max-width: 426px) {
    align-items: center;
  }
`;
const AdvertContainer = styled.div`
  //   width: 592px;
`;
const AdvertImage = styled.div`
  img {
    width: 154px;
    height: 154px;
  }
  @media (max-width: 426px) {
    img {
      width: 128px;
      height: 128px;
    }
  }
`;
const AdvertDetails = styled.div`
  margin-left: 28px;
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #171717;
    margin-top: 8px;
    // letter-spacing:-5px;
  }
  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #686868;
    letter-spacing: -0.8px;
  }
  @media (max-width: 576px) {
    h2 {
      font-size: 18px;
      line-height: 25px;
    }
    p {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;
const AdvertCTA = styled.div`
  margin-top: -10px;
  width: 100%;
  background-color: var(--mainBlue);
  :hover {
    background-color: #ef9c50;
    color: var(--mainBlue);
  }
  button {
    background-color: var(--mainBlue);
    color: white;
    position: relative;
    padding-top: 10px;
    z-index: 0;
    height: 64px;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: transparent;
    ouline: 0;
    :hover {
      background-color: #ef9c50;
      color: var(--mainBlue);
    }
  }
  @media (max-width: 576px) {
    font-size: 14px;
  }
`;
const TaskWrapper = styled.div`
  //   width: 584px;
  //   height: 88px;
  background: #f5f5f5;
  border-radius: 5px;
  padding: 12px 20px;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 5px;
  @media (max-width: 576px) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
`;
const Subscribe = styled.div`
  display: flex;
  align-items: center;

  // justify-content:space-between;
`;
const SubIcon = styled.div`
  background: rgba(239, 156, 80, 0.2);
  border-radius: 5px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: #ef9c50;
  }
`;
const SubscriptionDetails = styled.div`
  margin-left: 15px;
  width: 319px;
  p {
    color: #ef9c50;
    font-weight: 700;
    font-size: 10px;
  }
  h5 {
    font-size: 14px;
    line-height: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  @media (max-width: 576px) {
    h5 {
      font-size: 12px;
    }
  }
`;
const ButtonContainer = styled.div`
  width: 100%;
  text-align: right;
  button {
    background: #d5dbfd;
    border-radius: 5px;
    width: 180px;
    height: 28px;
    border: transparent;
    outline: 0;
    color: var(--mainBlue);
    :hover {
      background-color: #ef9c50;
    }
  }
  @media (max-width: 576px) {
    margin-top: 10px;
  }
`;
