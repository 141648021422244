import React,{useContext} from "react";
import styled from "styled-components";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";
// import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import ClientChart from "./Charts/ClientChart";
import estateImage from "../../assets/estate.png";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import {AppContext} from '../../Context'
interface ResidentProps {
id:any,
firstName:string,
lastName:string,
status:string,
phoneNumber:string,
address:string,

};

const ResidentCard= (props:ResidentProps) => {

  return (
    <ResidentCardWrapper>
      <ResIcon>
        <img src={estateImage} alt="Estate IQ Res" />
      </ResIcon>
      <ResDetails>
        <h2>{props.firstName}{" "}{props.lastName}</h2>
        <div>
          <LocationOnOutlinedIcon />
          <p>
           {props.address ? props.address : 'No Address Included'}
          </p>
        </div>
      </ResDetails>
      <div>
        <SubButton>{props.status}</SubButton>
        <ResTotal>
          <h6>ID : {props.id}</h6>
          <p> 13 June 2021</p>
        </ResTotal>
      </div>
    </ResidentCardWrapper>
  );
};

// interface MembersProps{
//   members: IMembers[];
// }
const AdminDashboard = () => {
 
  const {estateMembers} = useContext(AppContext);
const activeMembers = estateMembers?.filter((members:any)=>members.status==='active')
const inActiveMembers = estateMembers?.filter((members:any)=>members.status==='provisioned')
  return (
    <Wrapper>
      <ResidentSection>
        <ResidentChart>
          <ChartHeader>
            <div>
              <SupervisedUserCircleOutlinedIcon />
            </div>
            <h2>Residents</h2>
          </ChartHeader>
          <ChartBody>
            <PieSection>
              <ClientChart />
            </PieSection>
            <PieLabel>
              <LabelRow>
                <LabelBG style={{ backgroundColor: "#2D4BF3" }}></LabelBG>
                <div>
                  <p>Active Clients</p>
                  <h2>{activeMembers?.length}</h2>
                </div>
              </LabelRow>
              <LabelRow>
                <LabelBG style={{ backgroundColor: "#F2CD16" }}></LabelBG>
                <div>
                  <p>Inactive Clients</p>
                  <h2>{inActiveMembers?.length}</h2>
                </div>
              </LabelRow>
            </PieLabel>
          </ChartBody>
          <ChartFooter>
            <p>{estateMembers?.length} Residents Enrolled</p>
            <div>
              {/* +3% <ArrowRightAltOutlinedIcon /> */}
            </div>
          </ChartFooter>
        </ResidentChart>
        <ResidentReg>
          <ResRegHeader>
            <h2>Newest Residents</h2>
            <p></p>
          </ResRegHeader>
          <ResidentBody>
            {estateMembers?.map((members:any)=>{
              return(
                <ResidentCard key={members.memberId} id ={members.memberId} firstName={members.firstName} lastName={members.lastName}  status={members.status} phoneNumber={members.phoneNumber} address={members.address}/>
              )
            })}
            {estateMembers?.length < 1 && <p>No Resident in your Estate</p>}
           
           
          </ResidentBody>
        </ResidentReg>
      </ResidentSection>
      <SubscriptionSection></SubscriptionSection>
      <UtilitySection></UtilitySection>
    </Wrapper>
  );
};

export default AdminDashboard;
const Wrapper = styled.div`
  width: 100%;
  // width: 592px;
  text-align: left;
  padding-top: 50px;
  // @media(min-width:1366px){
  //   width:1050px;
  // }
  @media (max-width: 991px) {
    margin: auto;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media(max-width:426px){
    width:330px;
  }
`;
const ResidentSection = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  flex-wrap:wrap;
  @media(max-width:426px){
    width:100%;
  }
`;
const ResidentChart = styled.div`
  width: 364px;
  height: 342px;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media(max-width:426px){
    width:320px;
    height:304px;
  }
`;
const ChartHeader = styled.div`
padding-left:27px;
padding-top:13px;
padding-bottom:13px;
display:flex;
align-items:center;
border-bottom:1px solid #F5F5F5;
color:var(--mainBlue);
div{
  width:24px;
  height:24px;
  border-radius:5px;
  background-color:#F4F6FE;
  margin-right:10px;
  display:flex;
  align-items:center;
  justify-content:center;
  svg{
    font-size:12px;
  }
}
h2{
  margin-top:0px;
  margin-bottom:0px;
  font-weight:700;
  letter-spacing:-0.8px;
  font-size:16px;
  // color:var(--mainBlue);
}

}
`;
const ChartBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ChartFooter = styled.div`
  display: flex;
  height: 35px;
  background-color: #f5f5f5;
  align-items: center;
  justify-content: space-between;
  padding-left: 39px;
  padding-right: 22px;
  border-radius: 5px;

  p {
    color: var(--mainBlue);
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 10px;
    letter-spacing: -0.8px;
    font-weight: 700;
  }
  div {
    background-color: #e5f8e6;
    display: flex;
    align-items: center;
    color: #00ba07;
    font-size: 10px;
    padding-left: 3px;
    padding-right: 3px;
    font-weight: 700;
    svg {
      transform: rotate(-30deg);
      color: #00ba07;
      width: 12px;
    }
  }
  @media(max-width:426px){
    padding-top:10px;
    padding-bottom:10px;
  }
`;
const PieSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 250px;
`;
const PieLabel = styled.div`
  width: 120px;
`;
const LabelRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
  p {
    font-weight: 700;
    font-size: 10px;
    color: #c5cae4;
    letter-spacing: -0.8px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  h2 {
    color: #171717;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 5px;
  }
`;
const LabelBG = styled.div`
  width: 10px;
  height: 10px;
  borderradius: 1px;
  margin-right: 4px;
`;
const ResidentReg = styled.div`
  width: 520px;
  border: 1px solid #f5f5f5;
  height: 342px;
  border-radius: 5px;
  @media(max-width:576px){
    width:100%;
  }
  @media(max-width:426px){
    width:330px;
  }
`;
const ResRegHeader = styled.div`
  padding-left: 27px;
  padding-top: 13px;
  padding-bottom: 13px;
  display: flex;
  align-items: flex-end;
  gap: 10px;
  border-bottom: 1px solid #f5f5f5;

  h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 700;
    letter-spacing: -1px;
    font-size: 18px;
    // color:var(--mainBlue);
  }
  p {
    color: var(--mainBlue);
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 10px;
    font-weight: 700;
  }
`;
const ResidentBody = styled.div`
  overflow: scroll;
  height: 285px;
  
`;
const ResidentCardWrapper = styled.div`
  padding: 18px;
  display: flex;
  justify-content: space-between;
  @media(max-width:576px){
    width:300px;
    padding-left:5px;
    padding-right:5px;
  }
`;
const ResIcon = styled.div`
  img {
    width: 33px;
    height: 33px;
  }
  @media(max-width:576px){
    width:40px;
  }
`;
const ResDetails = styled.div`
  h2 {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.8px;
    color: var(--mainBlack);
    margin-top: 0px;
    margin-bottom: 5px;
  }
  div {
    display: flex;
    gap: 6px;
    width: 206px;
  }
  p {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #686868;
    font-weight: 400;
    font-size: 10px;
    letter-spacing: -0.8px;
  }
  svg {
    color: var(--mainBlue);
  }
  @media(max-width:576px){
    div{
      width:100px;
    }
  }
`;
const ResTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
  h6 {
    color: var(--mainBlue);
    font-weight: 700;
    letter-spacing: -0.6px;
    font-size: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  p {
    color: #686868;
    font-weight: 400;
    letter-spacing: -0.6px;
    font-size: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;
const SubButton = styled.div`
  width: 152px;
  height: 24px;
  color: #686868;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d6deff;
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: -0.6px;
  @media(max-width:576px){
    width:80px;
  }
`;

const SubscriptionSection = styled.div``;
const UtilitySection = styled.div``;
