import React,{useEffect,useContext,useState} from "react";
import styled from "styled-components";
// import Logo from "../assets/logo.svg";
// import DropDown from "../assets/dropdown.svg";
// import { Link } from "react-router-dom";
// import Modal from "../Constants/Modal";
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import Main from "../Constants/Main";
import { AddPersonnelButton } from "../Constants/Snippets";
import PersonnelTable from "./PersonnelTable";
import {useNavigate} from 'react-router-dom';
import { httprequeset } from "../Constants/Constant";
import { AppContext } from "../../Context";

const Index: React.FC = (props) => {
  const { estateMembers } = useContext(AppContext);
  const navigate = useNavigate();
  const [toggleState, setToggleState] = useState("all");
  const [allPersonnels, setAllPersonnels]=useState([]);
  const [allPersonnelCount, setAllPersonnelCount]=useState(0);
  const [allAdminCount, setAllAdminCount]=useState(0);
  const [allSecurityCount, setAllSecurityCount]=useState(0);
  useEffect(()=>{
    // const Securities = estateMembers?.filter((member:any)=>member.estateRole==='security')
    httprequeset.get(`/auth/profile`)
    .then((res) => {  
    })   
  .catch((err) => {
       if(err.response.status===401){
        navigate('/')
        
       }
     });
    //  console.log(estateMembers)
     const AllPersonnels = estateMembers?.filter((personnel:any)=> personnel.estateRole !== 'resident');
     const admins = estateMembers?.filter(
      (members: any) => members.estateRole === "estate_admin"
    );
    const securities = estateMembers?.filter(
      (members: any) => members.estateRole === "security"
    );
     setAllPersonnels(AllPersonnels);
    setToggleState('all');
    setAllPersonnelCount(AllPersonnels?.length);
    setAllAdminCount(admins?.length);
    setAllSecurityCount(securities?.length);
    // setSecurityCount(securities?.length);

  },[estateMembers,navigate])
  
 
  
  const handleAll = () => {
    const AllPersonnels = estateMembers?.filter((personnel:any)=> personnel.estateRole !== 'resident');
    setAllPersonnels(AllPersonnels);
    setToggleState("all");
    // setPersonnelCount(AllPersonnels?.length);
  };
  const handleAdmins = () => {
    const admins = estateMembers?.filter(
      (members: any) => members.estateRole === "admin"
    );
    setAllPersonnels(admins);
    setToggleState("estate_admin");
    // setActiveAdmin(admins?.length);
  };
  const handleSecurities = () => {
    const securities = estateMembers?.filter(
      (members: any) => members.estateRole === "security"
    );
    setAllPersonnels(securities);
    setToggleState("security");
    // setSecurityCount(securities?.length);
  };

  return (
    <>
    {localStorage.getItem('activeStatus')==='provisioned'? <UnVerified>Please Verify your Email</UnVerified> : null}
    {(localStorage.getItem("empty")==='emptyEstate') ? <Empty>You do not have an estate and you don't belong to one, please create an estate</Empty> :null}
      <Main
        tabName="Personnels"
        rightSubHeader={
          <>
            <AddPersonnelButton />
          </>
        }
        mainBody={
          <>
          <Counters>
        <Count>
          <div>
            <SupervisorAccountOutlinedIcon/>
          </div>
          <div>
            <CountTitle style={{ color: "#C5CAE4" }}>
              All Personnel
            </CountTitle>
            <h3>{allPersonnelCount}</h3>
          </div>
        </Count>
        <Count>
          <div>
            <ContactsOutlinedIcon />
          </div>
          <div>
            <CountTitle style={{ color: "#00BA07" }}>Admin</CountTitle>
            <h3>{allAdminCount}</h3>
          </div>
        </Count>
        <Count>
          <div>
          <AdminPanelSettingsOutlinedIcon/>
          </div>
          <div>
            <CountTitle style={{ color: "#B50C0C" }}>Securities</CountTitle>
            <h3>{allSecurityCount}</h3>
          </div>
        </Count>
      </Counters>
          <ToggleResidentContainer>
        <ToggleButton
          style={{
            borderBottom:
              toggleState === "all" ? "10px solid #2D4BF3" : "transparent",
            color:
              toggleState === "all" ? "#686868" : "rgba(104, 104, 104, 0.2)",
          }}
          onClick={handleAll}
        >
          All Personnels
        </ToggleButton>
        <ToggleButton
          style={{
            borderBottom:
              toggleState === "estate_admin" ? "10px solid #2D4BF3" : "transparent",
            color:
              toggleState === "estate_admin"
                ? "#686868"
                : "rgba(104, 104, 104, 0.2)",
          }}
          onClick={handleAdmins}
        >
          All Admins
        </ToggleButton>
        <ToggleButton
          style={{
            borderBottom:
              toggleState === "security" ? "10px solid #2D4BF3" : "transparent",
            color:
              toggleState === "security"
                ? "#686868"
                : "rgba(104, 104, 104, 0.2)",
          }}
          onClick={handleSecurities}
        >
          All Securities
        </ToggleButton>
      </ToggleResidentContainer>

         
              <TableList>
              {allPersonnels?.map((personnels:any,i)=>{
            return(
              <PersonnelTable
                firstName={personnels.firstName}
                lastName={personnels.lastName}
                key={i}
                memberId={1}
                email={personnels.email}
                status="active"
                position={personnels.estateRole}
                phoneNumber={personnels.phoneNumber}
              />         
            )
          })}
           </TableList>
          </>
        }
      />
    </>
  );
};
export default Index;
const UnVerified = styled.div `
background-color:tomato;
color:white;
font-weight:bold;
position:fixed;
z-index:1000;
font-size:12px;
display:flex;
align-items:center;
justify-content:center;
width:300px;
height:40px;
left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
`
const Empty = styled.div `
background-color:tomato;
color:white;
font-weight:bold;
position:fixed;
z-index:1000;
font-size:11px;
display:flex;
align-items:center;
justify-content:center;
width:300px;
height:40px;
left: 0; 
  right: 0; 
  bottom:0;
  margin-left: auto; 
  margin-right: auto;
`
const Counters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 765px;
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 991px) {
    width: 700px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 576px) {
    margin-top: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
    width: 400px;
  }
  @media (max-width: 426px) {
    width: 90%;
    margin: auto;
  }
`;
const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 235px;
  height: 80px;
  border-radius: 5px;
  text-align: left;
  :first-child {
    border: 1px solid rgba(45, 75, 243, 0.2);
  }
  :nth-child(2) {
    border: 1px solid rgba(0, 186, 7, 0.2);
  }
  :last-child {
    border: 1px solid rgba(181, 12, 12, 0.2);
  }
  h3 {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  div {
    :first-child {
      background-color: #f4f6fe;
      border-radius: 5px;
      padding: 10px;
      margin-right: 10px;
      svg {
        color: var(--mainBlue);
      }
    }
  }
  @media (max-width: 1200px) {
    width: 220px;
  }
  @media (max-width: 768px) {
    width: 160px;
  }
  @media (max-width: 576px) {
    width: 190px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media (max-width: 426px) {
    width: 100%;
  }
`;
const CountTitle = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.6px;
`;
const TableList = styled.div`
  padding-top: 40px;
`;
const ToggleResidentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;
const ToggleButton = styled.button`
  width: 187px;
  height: 49px;
  background: #fbfbfb;
  font-size: 12px;
  letter-spacing: -0.6px;
  font-weight: bold;
  outline: 0;
  border-color: transparent;
  :hover {
    cursor: pointer;
  }
`;
