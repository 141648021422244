import React, { useContext,useEffect } from "react";
import Main from "../Constants/Main";
import ResidentDashboard from "./ResidentDashboard";
import ExternalDashboard from "./ExternalsDashboard";
import AdminDashboard from "./AdminDashboard";
import styled from "styled-components";
import { VisitorAccessButton,AdminAddResident,FilterByDate,VerifyAccessButton } from "../Constants/Snippets";
import { AppContext } from "../../Context/index";
import { httprequeset } from "../Constants/Constant";
import {useNavigate} from 'react-router-dom';

// interface Props {
//   navToggle: boolean;
// }
const Index: React.FC = (props) => {
  const { userRole } = useContext(AppContext);
  
  const navigate = useNavigate();
  useEffect(()=>{
    httprequeset.get(`/auth/profile`)
      .then((res) => {  
       
      })
    .catch((err) => {
    
         if(err.response.status===401){
          navigate('/')
         }
       });
  },[navigate])
  return (
    <>
     {localStorage.getItem('activeStatus')==='provisioned'? <UnVerified>Please Verify your Email</UnVerified> : null}
    {(localStorage.getItem("empty")==='emptyEstate') ? <Empty>You do not have an estate and you don't belong to one, please create an estate</Empty> :null}
      <Main
        tabName="Dashboard"
    rightSubHeader={<>{userRole==='estate_admin' &&<> <AdminAddResident/><FilterByDate/></> }
    {userRole==='resident' && <> <VisitorAccessButton/></>}{userRole === 'security' && <VerifyAccessButton/>}
    </> 
      }
        mainBody={
          <>
            {userRole === "security" && <ExternalDashboard />}
            {userRole === "resident" && <ResidentDashboard />}
            {userRole === "estate_admin" && <AdminDashboard />}
          </>
        }
      />
    </>
  );
};

export default Index;
const UnVerified = styled.div `
background-color:tomato;
color:white;
font-weight:bold;
position:fixed;
z-index:1000;
font-size:12px;
display:flex;
align-items:center;
justify-content:center;
width:300px;
height:40px;
left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
`
const Empty = styled.div `
background-color:tomato;
color:white;
font-weight:bold;
position:fixed;
z-index:1000;
font-size:11px;
display:flex;
align-items:center;
justify-content:center;
width:300px;
height:40px;
left: 0; 
  right: 0; 
  bottom:0;
  margin-left: auto; 
  margin-right: auto;
`