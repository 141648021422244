import React, { useState,useEffect} from "react";
import styled from "styled-components";
// import Logo from "../assets/logo.svg";
// import DropDown from "../assets/dropdown.svg";
import { useNavigate } from "react-router-dom";
import Modal from "../Constants/Modal";
import Main from "../Constants/Main";
import ExternalsResident from "./ExternalsResident";
import AdminResident from './AdminResident';
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { httprequeset } from "../Constants/Constant";

// interface Props {
//   navToggle: boolean;
// }

const Index: React.FC = (props) => {
  const navigate = useNavigate();
  useEffect(()=>{
    httprequeset.get(`/auth/profile`)
      .then((res) => {  
      })
    .catch((err) => {
         if(err.response.status===401){
          navigate('/')
         }
       });
  },[navigate])
  const [residentAccess, setResidentAccess] = useState(false);
  const [residentDetails, setResidentDetails] = useState(false);

  const handleResidentAccess = () => {
    setResidentAccess(!residentAccess);
  };
  const handleResidentDetails = () => {
    setResidentDetails(!residentDetails);
  };
  const handleDetailsForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };
  const External = false;
  const Admin = true;

  return (
    <>
    {localStorage.getItem('activeStatus')==='provisioned'? <UnVerified>Please Verify your Email</UnVerified> : null}
    {(localStorage.getItem("empty")==='emptyEstate') ? <Empty>You do not have an estate and you don't belong to one, please create an estate</Empty> :null}
      <Main
        tabName="Residents"
        rightSubHeader={
          <>
          {External && (<>
              <ResidentButton onClick={handleResidentAccess}>
                <div>
                  <AddOutlinedIcon />
                </div>
                Resident Entry
              </ResidentButton>
              <VisitorButton onClick={handleResidentAccess}>
                <div>
                  <AddOutlinedIcon />
                </div> 
                Visitor Entry
              </VisitorButton>
            </>)}
            
          </>
        }
        mainBody={
          <>
  {External && (<ExternalsResident />)}
  {Admin && (<AdminResident />)}     
          </>
        }
      />
      <Modal
        heading="Input Access Code"
        modal={residentAccess}
        toggleModal={handleResidentAccess}
        body={
          <>
            <AccessWrapper>
              <p>Code</p>
              <StyledForm onSubmit={handleDetailsForm}>
                <AccessContent type="text" />
                <h6>Code Valid within 72 Hours</h6>
                <SubmitButton onClick={handleResidentDetails}>
                  Submit
                </SubmitButton>
              </StyledForm>
            </AccessWrapper>
          </>
        }
      />
      <Modal
        heading="Visitor Info"
        modal={residentDetails}
        toggleModal={handleResidentDetails}
        body={
          <>
            <AccessWrapper>
              <VisitorDetails>
                <div>
                  <p>Host Name</p>
                  Ololademi Asake
                </div>
                <div>
                  <p>Host Address</p>
                  House 33, elegunjobi street, off rigmore road, Golden Estate
                  Gate
                </div>
              </VisitorDetails>

              <AccessCode>PQY-3091</AccessCode>
              <h6>Code Valid within 72 Hours</h6>
              <SubmitButton>Input Details</SubmitButton>
            </AccessWrapper>
          </>
        }
      />
    </>
  );
};

export default Index;

const UnVerified = styled.div `
background-color:tomato;
color:white;
font-weight:bold;
position:fixed;
z-index:1000;
font-size:12px;
display:flex;
align-items:center;
justify-content:center;
width:300px;
height:40px;
left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
`
const Empty = styled.div `
background-color:tomato;
color:white;
font-weight:bold;
position:fixed;
z-index:1000;
font-size:11px;
display:flex;
align-items:center;
justify-content:center;
width:300px;
height:40px;
left: 0; 
  right: 0; 
  bottom:0;
  margin-left: auto; 
  margin-right: auto;
`

const ResidentButton = styled.button`
  background-color: var(--mainBlue);
  // border: 1px solid var(--mainBlue);
  color: white;
  border-color: transparent;
  outline: 0;
  width: 200px;
  height: 50px;
  margin-left: 15px;
  font-size: 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: white;
    color: var(--mainBlue);
    margin-right: 12px;
    font-size: 12px;
  }
  :hover {
    background-color: var(--mainBlue);
    color: white;
    border-color: transparent;
  }
`;
const VisitorButton = styled.button`
  background-color: #c61c82;
  border: 1px solid #c61c82;
  color: white;
  outline: 0;
  width: 200px;
  height: 50px;
  margin-left: 15px;
  font-size: 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: white;
    color: #9d3ccb;
    margin-right: 12px;
    font-size: 12px;
  }
  // :hover {
  //   background-color: var(--mainBlue);
  //   color: white;
  //   border-color: transparent;
  // }
`;
const StyledForm = styled.form`
  width: 100%;
`;
const AccessWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  p {
    font-size: 12px;
    color: #686868;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  h6 {
    margin-top: 10px;
    font-size: 12px;
    color: var(--mainBlue);
  }
`;
const VisitorDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-bottom: 50px;
  p {
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 5px;
    font-color: #686868;
    letter-spacing: -0.5px;
  }
  div {
    width: 45%;
    color: var(--mainBlack);
    letter-spacing: -0.7px;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
  }
  @media (max-width: 576px) {
    font-size: 11px;
    div {
      font-size: 14px;
      letter-spacing: -0.5px;
    }
  }
`;

const AccessContent = styled.input`
  width: 500px;
  background-color: #fbfbfb;
  border: 1px solid #c5cae4;
  border-radius: 4px;
  color: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 64px;
  font-weight: bold;
  letter-spacing: -3px;
  margin: auto;
  height: 128px;
  @media (max-width: 576px) {
    width: 80%;
    margin: auto;
  }
`;
const AccessCode = styled.div`
  width: 373px;
  margin: auto;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f6ff;
  border-radius: 4px;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: -6px;
  color: var(--mainBlue);
  @media (max-width: 426px) {
    width: 80%;
  }
`;
const SubmitButton = styled.button`
  background-color: var(--mainBlue);
  outline: 0;
  border-color: transparent;
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin: auto;
  font-size: 12px;
  border-radius: 5px;
  svg {
    margin-left: 10px;
    font-size: 12px;
  }
`;

// const AccessForm = styled.form`
//   width: 500px;
//   margin: auto;
//   padding-top: 40px;
//   @media (max-width: 768px) {
//     margin: auto;
//   }
//   @media (max-width: 576px) {
//     width: 85%;
//     margin: auto;
//   }
// `;
