import React,{useContext,useEffect} from "react";
import Main from "../Constants/Main";
import ResidentComplaints from './ResidentsComplaints';
import { AppContext } from "../../Context/index";
import { httprequeset } from "../Constants/Constant";
import {useNavigate} from 'react-router-dom';
import {VisitorAccessButton,AddComplaintsButton } from "../Constants/Snippets";
// interface Props {
//   navToggle: boolean;
// }

const Index: React.FC = (props) => {
  const { userRole } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(()=>{
    httprequeset.get(`/auth/profile`)
      .then((res) => {  
      })
    .catch((err) => {
         if(err.response.status===401){
          navigate('/')
         }
       });
  },[navigate])
  return (
    <>
      <Main
        tabName="Complaints"
        rightSubHeader={<>
         {userRole==='resident' && <><AddComplaintsButton/> <VisitorAccessButton/></>}
          
        </>}
        mainBody={<><ResidentComplaints/></>}
      />
    </>
  );
};

export default Index;

