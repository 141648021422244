import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./Components/Auth/ProtectRoutes";
import Dashboard from "./Components/Dashboard/Index";
import Login from "./Components/Auth/Login";
import SignUpAdmin from "./Components/Auth/SignupAdmin";
import Terms from "./Components/Auth/Terms";
import JoinEstate from "./Components/Auth/JoinEstate";
import EstateSignup from "./Components/Auth/EstateSignup";
import Activate from "./Components/Auth/Activation";
import Confirm from "./Components/Auth/Confirm";
import Residents from "./Components/Residents/Index";
import ActivityLogs from "./Components/ActivityLogs/Index";
import Client from "./Components/Clients/Index";
import Complaints from "./Components/Complaints/Index";
import Members from "./Components/Members/Index";
import Messaging from "./Components/Messaging/Index";
import Permissions from "./Components/Permissions/Index";
import Tasks from "./Components/Tasks/Index";
import Transaction from "./Components/Transactions/Index";
import Utility from "./Components/Utilities/Index";
import AdminApprovals from "./Components/Approvals/Index";
import NoAccount from "./Components/Auth/NoAccount";
import ResetPassword from "./Components/Auth/ResetPassword";
import SetPassword from "./Components/Auth/SetPassword";
import Personnels from "./Components/Personnels/Index";
import FourZeroFour from "./Components/Auth/404";
import Profile from "./Components/Profile/Index";
import Settings from "./Components/Settings/Index";

import { AppProvider } from "./Context";
const App: React.FC = () => {
  return (
    <div className="App">
      <AppProvider>
        <Routes>
          <Route element={<Login />} path="/" />
          <Route element={<SignUpAdmin />} path="/auth/create" />
          <Route element={<EstateSignup />} path="/auth/create/estate" />
          <Route element={<JoinEstate />} path="/auth/join/estate" />
          <Route element={<Activate />} path="/auth/activate" />
          <Route element={<Confirm />} path="/auth/confirm" />
          <Route element={<NoAccount />} path="/auth/options" />
          <Route element={<ResetPassword />} path="/auth/reset" />
          <Route element={<SetPassword />} path="/auth/password" />
          <Route element={<Terms/>} path="/terms" />
          {/* <Route element={<Dashboard />} path="/dashboard" /> */}
          <Route
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
            path="/dashboard"
          />
          <Route
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
            path="/profile"
          />
          <Route
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
            path="/settings"
          />
          <Route
            element={
              <ProtectedRoute>
                <AdminApprovals />
              </ProtectedRoute>
            }
            path="/admin/approval"
          />
          <Route
            element={
              <ProtectedRoute>
                <Residents />
              </ProtectedRoute>
            }
            path="/resident"
          />
          <Route
            element={
              <ProtectedRoute>
                <Transaction />
              </ProtectedRoute>
            }
            path="/transaction"
          />
          <Route
            element={
              <ProtectedRoute>
                <Messaging />
              </ProtectedRoute>
            }
            path="/message"
          />
          <Route
            element={
              <ProtectedRoute>
                <Permissions />
              </ProtectedRoute>
            }
            path="/permission"
          />
          <Route
            element={
              <ProtectedRoute>
                <Tasks />
              </ProtectedRoute>
            }
            path="/task"
          />
          <Route
            element={
              <ProtectedRoute>
                <Members />
              </ProtectedRoute>
            }
            path="/member"
          />
          <Route
            element={
              <ProtectedRoute>
                <Complaints />
              </ProtectedRoute>
            }
            path="/complaint"
          />
          <Route
            element={
              <ProtectedRoute>
                <ActivityLogs />
              </ProtectedRoute>
            }
            path="/activity"
          />
          <Route
            element={
              <ProtectedRoute>
                <Client />
              </ProtectedRoute>
            }
            path="/client"
          />
          <Route
            element={
              <ProtectedRoute>
                <Utility />
              </ProtectedRoute>
            }
            path="/utility"
          />
          <Route
            element={
              <ProtectedRoute>
                <Personnels />
              </ProtectedRoute>
            }
            path="/personnel"
          />
          <Route element={<FourZeroFour />} path="*" />
        </Routes>
      </AppProvider>
    </div>
  );
};

export default App;
