import React from "react";
import styled from "styled-components";
import KeyboardTabOutlinedIcon from "@mui/icons-material/KeyboardTabOutlined";
import KeyboardReturnOutlinedIcon from "@mui/icons-material/KeyboardReturnOutlined";

interface IconProp {
  in: boolean;
}

const ResidentActivity = (props: IconProp) => {
  return (
    <Wrapper>
      <CardContainer>
        <CardFlex>
          <IconBG in={true}>
            <KeyboardTabOutlinedIcon />
          </IconBG>
          <h2>PQY-3091 </h2>
          <p>Key has been logged in within the estate</p>
        </CardFlex>
        <DateAndTime>
          <p>2, July 2021</p>
          <p>5:30 am</p>
        </DateAndTime>
      </CardContainer>
      <CardContainer>
        <CardFlex>
          <IconBG in={false}>
            <KeyboardReturnOutlinedIcon />
          </IconBG>
          <h2>PQY-3091 </h2>
          <p>Key has been logged in within the estate</p>
        </CardFlex>
        <DateAndTime>
          <p>2, July 2021</p>
          <p>5:30 am</p>
        </DateAndTime>
      </CardContainer>
    </Wrapper>
  );
};

export default ResidentActivity;

const Wrapper = styled.div`
  // width:100%:
  width: 594px;
  text-align: left;
  padding-top: 65px;
  @media (max-width: 1200px) {
    width: 600px;
  }
  @media (max-width: 991px) {
    width: 100%;
    margin: auto;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const CardContainer = styled.div`
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  @media(max-width:426px){
      padding:20px 10px;
  }
`;
const CardFlex = styled.div`
  display: flex;
  align-items: center;
  h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
    letter-spacing: -1px;
    font-weight: bold;
    color: #171717;
    margin-left: 18px;
    margin-right: 18px;
  }
  p {
    font-size: 14px;
    letter-spacing: -0.7px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 400;
  }
  @media(max-width:426px){
    p{
        font-size:12px;
    }
    h2{
        font-size:12px;
        margin-right:8px;
        margin-left:8px;
    }
}
`;
const IconBG = styled.div<IconProp>`
  background-color: ${(props) =>
    props.in ? "#e5f8e6" : "rgba(181, 12, 12, 0.2)"}};
  border-radius: 5px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:${(props) => (props.in ? "#00ba07" : "#B50C0C")} ;
  font-size: 12px;
`;

const DateAndTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  p {
    font-size: 14px;
    letter-spacing: -1px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 400;
    margin-right: 5px;
  }
  @media(max-width:426px){
      p{
          font-size:12px;
      }
  }
`;
