import React,{useEffect} from "react";
import Main from "../Constants/Main";
import ResidentMembers from "./ResidentMembers";
import { httprequeset } from "../Constants/Constant";
import styled from "styled-components";
import {useNavigate} from 'react-router-dom';
import { AddMemberButton, VisitorAccessButton } from "../Constants/Snippets";

const Index: React.FC = (props) => {
  const navigate = useNavigate();
  useEffect(()=>{
    httprequeset.get(`/auth/profile`)
      .then((res) => {  
      })
    .catch((err) => {
         if(err.response.status===401){
          navigate('/')
         }
       });
  },[navigate])
  return (
    <>
     {localStorage.getItem('activeStatus')==='provisioned'? <UnVerified>Please Verify your Email</UnVerified> : null}
     {(localStorage.getItem("empty")==='emptyEstate') ? <Empty>You do not have an estate and you don't belong to one, please create an estate</Empty> :null}
      <Main
        tabName="Residents"
        rightSubHeader={
          <>
            <AddMemberButton /> <VisitorAccessButton />
          </>
        }
        mainBody={
          <>
            <ResidentMembers />
          </>
        }
      />
    </>
  );
};

export default Index;
const UnVerified = styled.div `
background-color:tomato;
color:white;
font-weight:bold;
position:fixed;
z-index:1000;
font-size:12px;
display:flex;
align-items:center;
justify-content:center;
width:300px;
height:40px;
left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
`
const Empty = styled.div `
background-color:tomato;
color:white;
font-weight:bold;
position:fixed;
z-index:1000;
font-size:11px;
display:flex;
align-items:center;
justify-content:center;
width:300px;
height:40px;
left: 0; 
  right: 0; 
  bottom:0;
  margin-left: auto; 
  margin-right: auto;
`
