import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import loader from "../../assets/loader.gif";
import { httprequeset } from "../Constants/Constant";
import Modal from "../Constants/Modal";
import Success from "../Constants/Success";
import { AppContext } from "../../Context/index";

type Props = {
  closeFormToggle: any;
};
const CreateAccount = (props: Props) => {
  const { handleUserProfile } = useContext(AppContext);
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    name: "",
    address: "",
    contactPhoneNumber: "",
    estimatedNumberOfResidents: 0,
    isLoading: false,
    zone: "",
    formError: [],
    createSuccess: false,
  });
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };
  // const ClearForm = () => {
  //   setFormState({
  //     ...formState,
  //     name: "",
  //     address: "",
  //     contactPhoneNumber: "",
  //     estimatedNumberOfResidents: 0,
  //     isLoading: false,
  //     zone: "",
  //     formError: [],
  //     createSuccess: false,
  //   });
  // };
  const CloseSuccessMessage = () => {
    setFormState({ ...formState, createSuccess: false });
    navigate("/profile");
  };
  const handleSignUp = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormState({ ...formState, isLoading: true, formError: [] });
    const payload = { ...formState };
    httprequeset
      .post("/estates", payload)
      .then((res) => {
        if (res.status === 201) {
          handleUserProfile();
          props.closeFormToggle();
          // ClearForm();
          setFormState({
            ...formState,
            name: "",
            address: "",
            contactPhoneNumber: "",
            estimatedNumberOfResidents: 0,
            isLoading: false,
            zone: "",
            formError: [],
            createSuccess: true,
          });
        }
      })
      .catch((err) => {
      if (err?.response.status===401){
          return navigate('/')
      }
        setFormState({
          ...formState,
          isLoading: false,
          formError: Object.values(err?.response?.data?.message),
        });
        // console.log(err.response.data.message);
      });
  };
  const isDisabled = () => {
    const {
      name,
      address,
      estimatedNumberOfResidents,
      contactPhoneNumber,
      zone,
    } = formState;
    if (
      name &&
      address &&
      estimatedNumberOfResidents &&
      contactPhoneNumber &&
      zone
    ) {
      return false;
    }
    return true;
  };
  return (
    <>
      <Form onSubmit={handleSignUp}>
        <CloseForm onClick={props.closeFormToggle}>
          <FontAwesomeIcon icon={faXmark} />
        </CloseForm>
        <SectionTitle>Create A New Estate</SectionTitle>
        <FormGroup>
          <label>Enter Estate Name</label>
          <input
            type="text"
            name="name"
            value={formState.name}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Enter Estate Address</label>
          <input
            type="text"
            name="address"
            value={formState.address}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Contact Phone Number</label>
          <input
            type="tel"
            name="contactPhoneNumber"
            value={formState.contactPhoneNumber}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Estimated No of Resident</label>
          <input
            type="number"
            name="estimatedNumberOfResidents"
            onChange={handleChange}
            value={formState.estimatedNumberOfResidents}
          />
        </FormGroup>
        <FormGroup>
          <label>Zone</label>
          <input
            type="text"
            name="zone"
            onChange={handleChange}
            value={formState.zone}
          />
        </FormGroup>
        {formState.isLoading ? (
          <LoadingButton>
            <img src={loader} alt="Estate IQ" />
          </LoadingButton>
        ) : (
          <CreateButton
            type="submit"
            value="Create Estate"
            disabled={isDisabled()}
          />
        )}
        {formState.formError.length < 2 ? (
          formState.formError.map((err: any, i) => {
            return <FormErr key={i}>{err}</FormErr>;
          })
        ) : (
          <FormErr>{formState.formError}</FormErr>
        )}
      </Form>
      <Modal
        heading=""
        modal={formState.createSuccess}
        toggleModal={CloseSuccessMessage}
        body={
          <>
            <Success
              messageOne=""
              messageTwo="Successfully Created a New Estate"
              action={CloseSuccessMessage}
              actionText="Back"
            />
          </>
        }
      />
    </>
  );
};
export default CreateAccount;
const Form = styled.form`
  width: 500px;
  position: relative;
  @media (max-width: 576px) {
    width: 100%;
    margin: auto;
  }
`;
const SectionTitle = styled.div`
  color: #686868;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.7px;
  text-align: left;
  padding-top: 20px;
  @media (max-width: 576px) {
    width: 100%;
    padding-bottom: 10px;
  }
`;
const FormGroup = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: left;
  position: relative;
  width: 500px;
  margin: auto;
  input {
    width: 100%;
    background: #fbfbfb;
    border: 1px solid #c5cae4;
    border-radius: 4px;
    height: 58px;
    padding-left: 10px;
  }
  label {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #686868;
  }
  @media (max-width: 1366px) {
    input {
      height: 48px;
    }
  }
  @media (max-width: 576px) {
    width: 300px;
    padding-top: 10px;
    padding-bottom: 10px;
    input {
      width: 100%;
      padding: 0px !important;
    }
  }
`;
const CloseForm = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-left: auto;
  margin-right: -30px;
  color: black;
  svg {
    font-size: 24px;
  }
  :hover {
    color: var(--mainBlue);
    cursor: pointer;
  }
  @media (max-width: 576px) {
    position: absolute;
    right: 30px;
    top: -40px;
  }
`;
const CreateButton = styled.input`
  background-color: var(--mainBlue);
  width: 300px;
  height: 42px;
  margin: auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  outline: 0;
  border-color: transparent;
  :hover {
    cursor: pointer;
  }
  :disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
const LoadingButton = styled.div`
  background-color: var(--mainBlue);
  width: 300px;
  height: 42px;
  margin: auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  outline: 0;
  border-color: transparent;
  img {
    width: 20px;
  }
`;
const FormErr = styled.div`
  font-size: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  color: tomato;
  text-align: right;
  .err {
    font-size: 10px;
    margin-top: 0px;
    color: tomato;
    text-align: left;
  }
  .login_from_signup {
    width: 100%;
    text-align: right;
    margin-left: auto;
  }
`;
