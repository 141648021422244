import React, { useState, useEffect } from "react";
import styled from "styled-components";
import IconImage from "../../assets/estate.png";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
// import EmptyChat from "../../assets/emptychat.svg";
// interface TProps {
//  toggleSmallChat: any;
// }
const useWindowWide = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWidth]);

  return width;
};
// const EmptyStack = () => {
//   return (
//     <EmptyWrapper>
//       <div>
//         <img src={EmptyChat} alt="Estate IQ" />
//         <h3>Keep in touch</h3>
//         <p>Send Messages to Admin and other Residents in the Estate </p>
//       </div>
//     </EmptyWrapper>

//   );
// };
const NonEmptyStack = () => {
  return (
    <NonEmptyWrapper>
      <Ping>
        <p>10:16</p>
        <div>
          Vel et commodo et scelerisque aliquam. Sed libero, non praesent felis,
          sem eget venenatis neque. Massa tincidunt tempor a nisl eu mauris
          lectus.
        </div>
      </Ping>
      <Pong>
        <p>10:20</p>
        <div>
          Vel et commodo et scelerisque aliquam. Sed libero, non praesent felis,
          sem eget venenatis neque. Massa tincidunt tempor a nisl eu mauris
          lectus.
        </div>
      </Pong>
      <Pong></Pong>
      <Ping>
        <p>10:16</p>
        <div>
          Vel et commodo et scelerisque aliquam. Sed libero, non praesent felis,
          sem eget venenatis neque. Massa tincidunt tempor a nisl eu mauris
          lectus.
        </div>
      </Ping>
      <Pong>
        <p>10:20</p>
        <div>
          Vel et commodo et scelerisque aliquam. Sed libero, non praesent felis,
          sem eget venenatis neque. Massa tincidunt tempor a nisl eu mauris
          lectus.
        </div>
      </Pong>
      <Pong></Pong>
    </NonEmptyWrapper>
  );
};

const ResidentMessaging = () => {
  const [toggleSmallChat, setToggleSmallChat] = useState(false);
  const windowSize = useWindowWide();
  const handleChatToggleTrue = () => {
    setToggleSmallChat(true);
  };
  const handleChatToggleFalse = () => {
    setToggleSmallChat(false);
  };
  return (
    <Wrapper>
      <Container>
        {((windowSize < 991 && !toggleSmallChat) || windowSize > 991) && (
          <LeftContainer>
            <LeftHeader>
              <h2>Chats</h2>
              <p>4 New Unread Messages</p>
            </LeftHeader>
            <ChatList>
              {Array.from(Array(15)).map((i) => (
                <Chat onClick={handleChatToggleTrue} key={i}>
                  <ChatDP>
                    <img src={IconImage} alt="Estate IQ" />
                  </ChatDP>

                  <ChatMessages>
                    <h3>Oladoye Tolulope</h3>
                    <p>When can we come around sir</p>
                  </ChatMessages>
                  <ChatNotification>3</ChatNotification>
                </Chat>
              ))}
            </ChatList>
          </LeftContainer>
        )}

        {((windowSize < 991 && toggleSmallChat) || windowSize > 991) && (
          <RightContainer>
            <RightHeader>
              <MDBack onClick={handleChatToggleFalse}>
                <ChevronLeftOutlinedIcon />
              </MDBack>
              <RightChatDP>
                <img src={IconImage} alt="Estate IQ" />
              </RightChatDP>
              <RightContactDetails>
                <h3>
                  Oladoye Tolulope<span>Admin</span>
                </h3>
                <h5>
                  Golden Gate Estate <span>. EIQ402</span>
                </h5>
              </RightContactDetails>
              <RightCallIcon>
                <LocalPhoneOutlinedIcon />
              </RightCallIcon>
            </RightHeader>
            <ChatStacks>
              <NonEmptyStack />
            </ChatStacks>
            <RightFooter>
              <FooterContent>
                <ChatIcon>
                  <SentimentSatisfiedOutlinedIcon />
                </ChatIcon>
                <ChatInput type="text" placeholder="Enter Your Message Here" />
                <ChatIcon>
                  <SendOutlinedIcon />
                </ChatIcon>
              </FooterContent>
            </RightFooter>
          </RightContainer>
        )}
      </Container>
    </Wrapper>
  );
};
export default ResidentMessaging;
// const EmptyWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 100%;
//   height: 100%;
//   h3{
//     margin-top: 0px;
//     margin-bottom:0px;
//     font-size: 24px;
//     font-weight: bold;
//     letter-spacing: -1px;
//     color: var(--mainBlack);
//   }
// `;
const Ping = styled.div`
  text-align: left;
  width: 65%;
  margin-top: 25px;
  margin-bottom: 25px;
  p {
    color: #686868;
    font-size: 12px;
    letter-spacing: -0.6px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  div {
    background: rgba(0, 148, 255, 0.2);
    border-radius: 0px 20px 20px 20px;
    padding: 16px;
  }
  @media (max-width: 1200px) {
    width: 85%;
  }
  @media (max-width: 991px) {
    width: 65%;
  }
  @media (max-width: 768px) {
    div {
      font-size: 12px;
    }
  }
  @media (max-width: 576px) {
    width: 70%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  @media (max-width: 476px) {
    width: 85%;
  }
`;
const Pong = styled.div`
  width: 65%;
  margin-left: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  p {
    text-align: right;
    color: #686868;
    font-size: 12px;
    letter-spacing: -0.6px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  div {
    text-align: left;
    background-color: white;
    border-radius: 20px 0px 20px 20px;
    padding: 16px;
  }
  @media (max-width: 1200px) {
    width: 85%;
  }
  @media (max-width: 768px) {
    div {
      font-size: 12px;
    }
  }
  @media (max-width: 991px) {
    width: 65%;
  }
  @media (max-width: 576px) {
    width: 70%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  @media (max-width: 476px) {
    width: 85%;
  }
`;
const NonEmptyWrapper = styled.div`
  padding-top: 51px;
  padding-left: 10px;
  padding-right: 10px;
`;
const Wrapper = styled.div`
  width: 100%;
  //   padding-bottom: 80px;
  padding-top: 40px;
  position: relative;
  @media (max-width: 991px) {
    position: fixed;
    width: calc(100vw - 60px);
  }
  @media (max-width: 576px) {
    width: calc(100vw - 30px);
  }
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  background: #ffffff;
  border: 1px solid #c5cae4;
  border-radius: 5px;

  @media (max-width: 1960px) {
    width: 100%;
    height: 708px;
  }
  @media (max-width: 1366px) {
    height: 540px;
  }
  @media (max-width: 991px) {
    height: 100vh;
  }
  @media (min-width: 1960.1px) {
    width: 1600px;
    height: 708px;
  }
`;
const LeftContainer = styled.div`
  width: 35%;
  border-right: 1px solid #c5cae4;
  @media (max-width: 1200px) {
    width: 40%;
  }
  @media (max-width: 991px) {
    width: 100%;
    border-right: transparent;
  }
`;

const RightContainer = styled.div`
  width: 65%;
  position: relative;
  background-color: #e5e5e5;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const LeftHeader = styled.div`
  height: 93px;
  padding-bottom: 14px;
  padding-top: 37px;
  padding-left: 42px;
  border-bottom: 1px solid #c5cae4;
  text-align: left;
  h2 {
    margin-top: 0px;
    margin-bottom: 42px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1px;
    color: var(--mainBlack);
  }
  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 12px;
    color: #c5cae4;
    letters-spacing: -0.7px;
    font-weight: bold;
  }
  @media (max-width: 1366px) {
    padding-left: 20px;
  }
  @media (max-width: 576px) {
    padding-bottom: 10px;
    padding-top: 17px;
    height: 63px;
    h2 {
      margin-bottom: 11px;
    }
  }
`;
const ChatList = styled.div`
  height: 450px;
  overflow: scroll;
  //   box-shadow: 0 4px 2px -2px gray;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.1);
  @media (max-width: 1366px) {
    height: 320px;
  }
  @media (max-width: 991px) {
    height: calc(100vh - 120px);
  }
`;
// const LeftFooter = styled.div`
//   position: absolute;
//   bottom: 0;
// `;
const Chat = styled.div`
  display: flex;
  padding-left: 42px;
  padding-right: 20px;
  padding-top: 18px;
  padding-bottom: 10px;
  border-bottom: 1px solid #c5cae4;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    background-color: #eaeaea;
    cursor: pointer;
  }
  @media (max-width: 1366px) {
    padding-left: 20px;
  }
`;
const ChatDP = styled.div`
  width: 31px;
  height: 31px;
  img {
    width: 31px;
    height: 31px;
    border-radius: 50%;
  }
`;
const ChatMessages = styled.div`
  text-align: left;
  width: 70%;
  h3 {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 8px;
    color: var(--mainBlack);
    letter-spacing: -0.7px;
    font-weight: 700;
  }
  p {
    color: #686868;
    font-size: 12px;
    letter-spacing: -0.6px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;
const ChatNotification = styled.div`
  background-color: var(--mainBlue);
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  color: white;
`;
const RightHeader = styled.div`
  display: flex;
  align-items: center;
  // height:88px;
  padding-left: 35px;
  padding-right: 35px;
  border-bottom: 1px solid #c5cae4;
  justify-content: space-between;
  background-color: white;
  @media (max-width: 1200px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const RightChatDP = styled.div`
  width: 43px;
  height: 43px;
  img {
    width: 43px;
    height: 43px;
    border-radius: 50%;
  }
  @media (max-width: 576px) {
    width: 39px;
    height: 39px;
    img {
      width: 39px;
      height: 39px;
    }
  }
`;

const RightContactDetails = styled.div`
  text-align: left;
  width: 300px;
  padding-top: 23px;
  padding-bottom: 23px;
  h3 {
    color: var(--mainBlack);
    margin-top: 0px;
    margin-bottom: 7px;
    font-size: 18px;
    letter-spacing: -1px;
    span {
      color: #c5cae4;
      font-size: 10px;
      letter-spacing: -0.5px;
      margin-left: 10px;
    }
  }

  h5 {
    color: #686868;
    letter-spacing: -0.6px;
    margin-top: 0px;
    margin-bottom: 0px;
    span {
      font-size: 12px;
      color: var(--mainBlack);
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  @media (max-width: 576px) {
    width: 260px;
    h3 {
      font-size: 14px;
      span {
        font-size: 10px;
      }
    }
    h5 {
      font-size: 10px;
    }
  }
  @media (max-width: 426px) {
    width: 150px;
  }
`;
const RightCallIcon = styled.div`
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f4f6fe;
  border: 1px solid var(--mainBlue);
`;
const ChatStacks = styled.div`
  //   background-color: #e5e5e5;
  height: 500px;
  overflow: scroll;
  @media (max-width: 1366px) {
    height: 400px;
  }
  @media (max-width: 991px) {
    height: calc(100vh - 120px);
  }
`;

const RightFooter = styled.div`
  width: 100%;
  height: 37px;
  bottom: 3px;
  background-color: transparent;
  display: flex;
  position: absolute;
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 18px;
  padding-right: 18px;
`;
const ChatIcon = styled.div`
  color: var(--mainBlue);
  :last-child {
    transform: rotate(-25deg);
  }
`;
const ChatInput = styled.input`
  height: 37px;
  border-radius: 3px;
  border-color: transparent;
  width: 85%;
  @media (max-width: 1200px) {
    width: 80%;
  }
`;
const MDBack = styled.div`
  color: var(--mainBlue);
  @media (min-width: 991px) {
    display: none;
  }
`;
