import React, { useState,useContext,useEffect } from "react";
import styled from "styled-components";
import authImage from "../../assets/authImage.jpg";
// import PasswordStrengthBar from "react-password-strength-bar";
// import Icon from "../../assets/icon.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Logo from "../../assets/logo-colored.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASEURL } from "../Constants/Constant";
import loader from "../../assets/loader.gif";
import {AppContext} from '../../Context'
interface Props {
  // authImage:string
}

const Login = (props: Props) => {
  const navigate = useNavigate();
  const {handleUserProfile} =useContext(AppContext)
  const [formState, setFormState] = useState({
    password: "",
    email: "",
    isLoading: false,
    formError: [],
    passwordType: "password",
  });
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };
  const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormState({ ...formState, isLoading: true });
    const payload = { ...formState };
    axios
      .post(`${BASEURL}/auth/login`, payload)
      .then((res) => {
        if (res.status === 201) {
          localStorage.setItem("isAuthenticated", res.data.token);
          setFormState({
            ...formState,
            isLoading: false,
          });
          
          setTimeout(()=>{
            handleUserProfile();
            navigate("/dashboard");
          },200)
        }
      })
      .catch((err) => {
        setFormState({
          ...formState,
          isLoading: false,
          formError: Object.values(err?.response?.data?.message),
        });
      });
  };
  const togglePassword = () => {
    if (formState.passwordType === "password") {
      setFormState({
        ...formState,
        passwordType: "text",
      });
    } else {
      setFormState({
        ...formState,
        passwordType: "password",
      });
    }
  };
  const isDisabled = () => {
    const { password, email } = formState;
    if (password && email) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    localStorage.clear()
    handleUserProfile()
  }, [handleUserProfile])
  
  return (
    <Wrapper>
      <Left>
        <Content>
          <IconContainer>
            <Link to="/">
              <img src={Logo} alt="EstateIQ Icon" />
            </Link>
          </IconContainer>

          <FormHeader>
            <h3>Welcome Back</h3>
            <CreateAccount>Don't have an account? <Link to="/auth/create"> Click here to create one</Link></CreateAccount>
            <p>Continue from where you left off...</p>
          </FormHeader>
          <StyledForm onSubmit={handleLogin}>
            <FormGroup>
              <label>Email Address</label>
              <input type="email" name="email" onChange={handleChange} />
            </FormGroup>
            <FormGroup>
              <label>Password</label>
              <input type={formState.passwordType} name="password" onChange={handleChange} />
              <Eye>
                {formState.passwordType === "password" ? (
                  <VisibilityOutlinedIcon onClick={togglePassword} />
                ) : (
                  <VisibilityOffOutlinedIcon onClick={togglePassword} />
                )}
              </Eye>
            </FormGroup>
            {/* <FormGroup>
              <PasswordStrengthBar password={formState.password} />
            </FormGroup> */}
            <ButtonContainer>
              {formState.isLoading ? (
                <LoadingButton>
                  <img src={loader} alt="Estate IQ" />
                </LoadingButton>
              ) : (
                <StyledButton
                  type="submit"
                  disabled={isDisabled()}
                  defaultValue="Login"
                />
              )}
              {formState.formError.length < 2 ? (
                formState.formError.map((err: any) => {
                  return <FormErr>{err}</FormErr>;
                })
              ) : (
                <FormErr>{formState.formError}</FormErr>
              )}
              {/* {formState.formError? <FormErr>{formState.formError}</FormErr> :""} */}
              <Forgot>
                <span>
                Forgot Password? 
                </span>
                <Link to="/auth/reset">
                {" "}Reset Password
                </Link>
              </Forgot>
            </ButtonContainer>
          </StyledForm>
        </Content>
      </Left>
      <Right>{/* <img src={authImage} alt="Estate IQ Auth" /> */}</Right>
    </Wrapper>
  );
};

export default Login;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;
const Left = styled.div`
  width: 65%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const Right = styled.div`
  width: 35%;
  height: 100vh;
  background-image: url(${authImage});
  background-size: cover;
  @media (max-width: 991px) {
    display: none;
  }
`;
const Content = styled.div`
  width: 500px;
  margin: auto;
  padding-top: 40px;
  @media (min-width: 1367px) {
    padding-top: 85px;
  }
  @media (max-width: 1240px) {
    padding-top: 40px;
  }
  @media (max-width: 576px) {
    width: 80%;
    margin: auto;
  }
`;
const IconContainer = styled.div`
  text-align: left;
  margin-bottom:40px;
  img {
    width: 135px;
    // height: 210px;
  }
  @media (min-width: 1367px) {
    img {
      width: 203px;
      // height: 231px;
    }
  }
  @media (max-width: 1240px) {
    img {
      width: 93px;
      // height: 123px;
    }
  }
  @media (max-width: 576px) {
    text-align: center;
    img {
      width: 77px;
      // height: 87px;
    }
  }
`;
const CreateAccount = styled.p `
padding-top:10px;
padding-bottom:10px;
@media(max-width:768px){
  font-size:10px;
}
`
const FormHeader = styled.div`
  margin-top: 10px;
  margin-bottom: 50px;
  text-align: left;
  h3 {
    margin-top: 0px;
    margin-bottom: 1px;
    font-size: 38px;
    letter-spacing: -2px;
    color: #111111;
    font-weight: bold;
  }
  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 12px;
    color: #545454;
  }
  @media (min-width: 1367px) {
    margin-top: 15px;
    margin-bottom: 75px;
  }
  @media (max-width: 1240px) {
    margin-top: 15px;
    margin-bottom: 35px;
  }
  @media (max-width: 576px) {
    text-align: center;
    margin-top: 15px;
    h3 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
      letter-spacing: -1px;
    }
    p {
      font-size: 12px;
    }
  }
`;
const StyledForm = styled.form`
  width: 100%;
`;
const FormGroup = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  position:relative;
  input {
    width: 97%;
    background: #fbfbfb;
    border: 1px solid #c5cae4;
    border-radius: 4px;
    height: 58px;
    padding-left: 10px;
  }
  label {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #686868;
  }
`;
const Eye = styled.div`
  position: absolute;
  right: 10px;
  bottom: 13px;

  svg {
    font-size: 14px;
  }
`;
const FormErr = styled.div`
  font-size: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  color: tomato;
  text-align: right;
`;
const StyledButton = styled.input`
  width: 363px;
  height: 51px;
  border-color: transparent;
  border-radius: 6px;
  color: white;
  background-color: var(--mainBlue);
  font-size: 12px;
  margin-bottom: 10px;
  text-align: center;
  :disabled {
    background-color: #ccc;
    color: black;
  }
  :disabled:hover {
    background-color: #ccc;
    color: black;
  }
  @media (max-width: 426px) {
    width: 100%;
    margin: auto;
  }
  :hover {
    background-color: #f2cd16;
    color: var(--mainBlue);
  }
`;
const Forgot = styled.div `
margin-left:auto;
width: 300px;
font-size:12px!important;
a{
 font-size:12px!important;
 letter-spacing:-0.6px;
 color:var(--mainBlue)!important;
}
// a:first-child{
//   color:green;
// }
// a:last-child{
//   color:red
// }
`
const LoadingButton = styled.button`
  width: 363px;
  height: 51px;
  border-color: transparent;
  border-radius: 6px;
  color: white;
  background-color: var(--mainBlue);
  font-size: 12px;
  margin-bottom: 10px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
    // height:45px;
  }
  @media (max-width: 426px) {
    width: 100%;
    margin: auto;
  }
  :hover {
    background-color: #f2cd16;
    color: var(--mainBlue);
  }
`;
const ButtonContainer = styled.div`
  margin-top: 25px;
  text-align: right;
  a {
    width: 363px;
    font-size: 16px;
    color: #111111;
    font-weight: 400;
    text-decoration: none;
    :hover {
      color: var(--mainBlue);
    }
  }
  @media (min-width: 1367px) {
    margin-top: 70px;
  }
  @media (max-width: 576px) {
    text-align: center;
    a {
      font-size: 12px;
    }
  }
`;
