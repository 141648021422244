import React from "react";
import ReactDom from "react-dom";
import styled from "styled-components";
// import close from "../../assets/icons/close.svg";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
// import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
// import back from "../../assets/icons/back.svg";

interface Props {
  modal: boolean;
  toggleModal: any;
  heading: React.ReactNode;
  body: React.ReactNode;
}

const modalDiv = document.getElementById("portal");

const Modal: React.FC<Props> = (props) => {
  return modalDiv
    ? ReactDom.createPortal(
        <>
          {props.modal ? (
            <ModalContainer>
              <ModalBodyClose onClick={props.toggleModal}></ModalBodyClose>
              <ModalDiv>
                <ModalClose onClick={props.toggleModal}>
                  <CancelOutlinedIcon />
                </ModalClose>
                <ModalHeading>
                  {/* <ArrowBackOutlinedIcon onClick={props.toggleModal} /> */}
                  {props.heading}
                </ModalHeading>
                {props.body}
              </ModalDiv>
            </ModalContainer>
          ) : null}
        </>,
        modalDiv
      )
    : null;
};

const ModalBodyClose = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background: rgba(79, 86, 101, 0.2);
  backdrop-filter: blur(12px);
  @media (max-width: 991px) {
    display: none;
  }
`;
const ModalContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  @media (max-width: 991px) {
    width: 100%;
    // overflow-y:hidden ;
  }
`;
const ModalDiv = styled.div`
  z-index: 2;
  max-width: 669px;
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 30%;
  left: 55%;
  transform: translateX(-50%);
  padding-bottom: 40px;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #65676c;
  p {
    margin-top: 25px;
  }
  @media (max-width: 1366px) {
    top: 15%;
    left: 50%;
  }

  @media (max-width: 991px) {
    max-width: none;
    top: 0px;
    left: 0px;
    transform: translateX(0px);
    border-radius: 0px;
    min-height: 100vh;
  }
`;
const ModalClose = styled.button`
  display: grid;
  place-content: center;
  width: 44px;
  height: 44px;
  background-color: #fff;
  border-radius: 50%;
  border: none;
  position: fixed;
  right: -50px;
  top: -50px;
  color: var(--mainBlue);
`;

const ModalHeading = styled.div`
  color: #171717;
  margin: 0px;
  font-size: 18px;
  font-weight: bold;
  padding: 0px 0px 16px;
  text-align: center;
  letter-spacing: -1px;
  padding-top: 24px;
  svg {
    display: none;
  }

  @media (max-width: 991px) {
    padding-left: 24px;
    font-size: 22px;
    display: flex;
    svg {
      margin-right: 25px;
      display: flex;
    }
  }
`;

export default Modal;
