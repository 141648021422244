import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import IconImage from "../../assets/estate.png";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { AppContext } from "../../Context";
import Modal from "../Constants/Modal";
import Success from "../Constants/Success";
import { httprequeset } from "../Constants/Constant";
import loader from "../../assets/loader.gif";
import { useNavigate } from "react-router-dom";

type Props = {};

const AdminApprovals = (props: Props) => {
  const { estateMembers, handleUserProfile } = useContext(AppContext);
  const [toggleState, setToggleState] = useState("total");
  const navigate = useNavigate();
  // const [approve, setApproved] = useState({ active: true });
  const [estateMemberDetails, setEstateMemberDetails] = useState<any>([]);
  const [activeCount, setActiveCount] = useState(0);
  const [inActiveCount, setinActiveCount] = useState(0);
  const [memberCount, setMemberCount] = useState(0);
  const [toggleActivate, setToggleActivate] = useState(false);
  const [activateLoading, setActivateLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    // console.log(estateMembers)
    const residents = estateMembers?.filter(
      (member: any) => member.estateRole === "resident"
    );
    const activeMembers = residents?.filter(
      (members: any) => members.status === "active"
    );
    const inActiveMembers = residents?.filter(
      (members: any) => members.status === "provisioned"
    );
    setEstateMemberDetails(residents && residents);
    setMemberCount(residents?.length);
    setActiveCount(activeMembers?.length);
    setinActiveCount(inActiveMembers?.length);
  }, [estateMembers]);
  const residents = estateMembers?.filter(
    (member: any) => member.estateRole === "resident"
  );
  const handleActivateToggle = () => {
    setToggleActivate(!toggleActivate);
  };
  const CloseSuccessModal = () => {
    setSuccess(!success);
  };
  const handleApproval =
    (id: any) => async (event: React.FormEvent<HTMLInputElement>) => {
      handleActivateToggle();
      setActivateLoading(true);
      setSuccess(false);
      // console.log(event.currentTarget.checked)
      const status =
        event.currentTarget.checked === false ? "provisioned" : "active";

      try {
        const response = await httprequeset.patch(`/estate-members/${id}`, {
          status: status,
        });

        if (response.status === 200) {
          setActivateLoading(false);
          setSuccess(true);
          handleUserProfile();
        }
      } catch (err: any) {
        // console.log(err);
        setSuccess(false);
        setActivateLoading(false);
        if (err.response.status === 401) {
          return navigate("/");
        }
      }
      // const checked = estateMembers?.find(
      //   (members: any) => members.memberId===id
      // );
      // console.log(checked)

      // setApproved({
      //   ...approve,
      //   [event.currentTarget.name]: event.currentTarget.checked,
      // });
    };
  const handleAll = () => {
    setEstateMemberDetails(residents);
    setToggleState("total");
    setMemberCount(residents?.length);
  };
  const handleActive = () => {
    const activeMembers = residents?.filter(
      (members: any) => members.status === "active"
    );
    setEstateMemberDetails(activeMembers);
    setToggleState("approved");
    setActiveCount(activeMembers?.length);
  };
  const handleInActive = () => {
    const inActiveMembers = residents?.filter(
      (members: any) => members.status === "provisioned"
    );
    setEstateMemberDetails(inActiveMembers);
    setToggleState("pending");
    setinActiveCount(inActiveMembers?.length);
  };
  return (
    <Wrapper>
      <Counters>
        <Count>
          <div>
            <HomeOutlinedIcon />
          </div>
          <div>
            <CountTitle style={{ color: "#C5CAE4" }}>
              Total Approvals
            </CountTitle>
            <h3>{memberCount}</h3>
          </div>
        </Count>
        <Count>
          <div>
            <HomeOutlinedIcon />
          </div>
          <div>
            <CountTitle style={{ color: "#00BA07" }}>Approved</CountTitle>
            <h3>{activeCount}</h3>
          </div>
        </Count>
        <Count>
          <div>
            <HomeOutlinedIcon />
          </div>
          <div>
            <CountTitle style={{ color: "#B50C0C" }}>Pending</CountTitle>
            <h3>{inActiveCount}</h3>
          </div>
        </Count>
      </Counters>
      <ToggleResidentContainer>
        <ToggleButton
          style={{
            borderBottom:
              toggleState === "total" ? "10px solid #2D4BF3" : "transparent",
            color:
              toggleState === "total" ? "#686868" : "rgba(104, 104, 104, 0.2)",
          }}
          onClick={handleAll}
        >
          Total
        </ToggleButton>
        <ToggleButton
          style={{
            borderBottom:
              toggleState === "approved" ? "10px solid #2D4BF3" : "transparent",
            color:
              toggleState === "approved"
                ? "#686868"
                : "rgba(104, 104, 104, 0.2)",
          }}
          onClick={handleActive}
        >
          Approved Requests
        </ToggleButton>
        <ToggleButton
          style={{
            borderBottom:
              toggleState === "pending" ? "10px solid #2D4BF3" : "transparent",
            color:
              toggleState === "pending"
                ? "#686868"
                : "rgba(104, 104, 104, 0.2)",
          }}
          onClick={handleInActive}
        >
          Pending Requests
        </ToggleButton>
      </ToggleResidentContainer>
      <MemberListContainer>
        {estateMemberDetails?.map((member: any) => {
          return (
            <Member key={member.memberId}>
              <DP>
                <img src={IconImage} alt="Estate IQ" />
                <h4>{member.firstName + " " + member.lastName}</h4>
              </DP>
              <EName>{member.email}</EName>

              <Address>{member.address}</Address>
              <EName>{member.phoneNumber}</EName>
              <Approve>
                <FormControlLabel
                  control={
                    <Switch
                      checked={member.status === "active" ? true : false}
                      onChange={handleApproval(member.memberId)}
                      color="primary"
                      name="active"
                      size="small"
                    />
                  }
                  label={
                    <StyledLabel>
                      {member.status === "active"
                        ? " Activated"
                        : "Deactivated"}
                    </StyledLabel>
                  }
                />
              </Approve>
              <Modal
        toggleModal={handleActivateToggle}
        body={
          <>
            {activateLoading && (
              <Loader>
                <img src={loader} alt="loading" />
              </Loader>
            )}
            {success ? (
              <>
                <Modal
                  heading=""
                  toggleModal={() => {
                    CloseSuccessModal();
                    handleActivateToggle();
                  }}
                  modal={success}
                  body={
                    <>
                      <Success
                        messageOne=""
                        messageTwo={member.status === "active" ? "Successfully Approved" : "Disapproved"}
                        action={() => {
                          CloseSuccessModal();
                          handleActivateToggle();
                        }}
                        actionText="Back"
                      />
                    </>
                  }
                />
              </>
            ) : null}
          </>
        }
        heading={member.status === "active" ? "Deactivate Resident" : "Activate Resident"}
        modal={toggleActivate}
      />
            </Member>
          );
        })}

      </MemberListContainer>
      
    </Wrapper>
  );
};

export default AdminApprovals;

const Wrapper = styled.div`
  width: 100%;
`;
const Counters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 765px;
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 991px) {
    width: 700px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 576px) {
    margin-top: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
    width: 400px;
  }
  @media (max-width: 426px) {
    width: 90%;
    margin: auto;
  }
`;
const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 235px;
  height: 80px;
  border-radius: 5px;
  text-align: left;
  :first-child {
    border: 1px solid rgba(45, 75, 243, 0.2);
  }
  :nth-child(2) {
    border: 1px solid rgba(0, 186, 7, 0.2);
  }
  :last-child {
    border: 1px solid rgba(181, 12, 12, 0.2);
  }
  h3 {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  div {
    :first-child {
      background-color: #f4f6fe;
      border-radius: 5px;
      padding: 10px;
      margin-right: 10px;
      svg {
        color: var(--mainBlue);
      }
    }
  }
  @media (max-width: 1200px) {
    width: 220px;
  }
  @media (max-width: 768px) {
    width: 160px;
  }
  @media (max-width: 576px) {
    width: 190px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media (max-width: 426px) {
    width: 100%;
  }
`;
const CountTitle = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.6px;
`;
const MemberListContainer = styled.div`
  padding-bottom: 50px;
`;
const Member = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px;
  height: 71px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
  @media (max-width: 426px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
const EName = styled.div`
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -0.7px;
  color: var(--mainBlack);
  @media (max-width: 1200px) {
    font-size: 12px;
  }
  @media (max-width: 426px) {
    display: none;
  }
`;
const DP = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 31px;
    height: 31px;
    border-radius: 50%;
  }
  h4 {
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 12px;
    font-size: 14px;
    letter-spacing: -0.8px;
  }
  @media (max-width: 1200px) {
    h4 {
      font-size: 12px;
    }
  }
  @media (max-width: 426px) {
    img {
      width: 24px;
      height: 24px;
    }
  }
`;
const Address = styled.div`
  margin-left: 25px;
  width: 230px;
  text-align: left;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: -0.8px;
  color: var(--mainBlack);
  @media (max-width: 1200px) {
    font-size: 12px;
    width: 230px;
    margin-left: 15px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const Approve = styled.div`
  width: 100px;
  align-items: center;
  label {
    font-size: 12px;
  }
`;
const Loader = styled.div`
  width: 100%;
  text-align: center;
  img {
    width: 40px;
    margin: auto;
  }
`;
const StyledLabel = styled.span`
  font-weight: 700;
  font-size: 12px;
  letter-spacing: -0.8px;
`;
const ToggleResidentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;
const ToggleButton = styled.button`
  width: 187px;
  height: 49px;
  background: #fbfbfb;
  font-size: 12px;
  letter-spacing: -0.6px;
  font-weight: bold;
  outline: 0;
  border-color: transparent;
  :hover {
    cursor: pointer;
  }
`;
