import React,{useContext} from "react";
import styled from "styled-components";
import Logo from "../../assets/logo.svg";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Link,NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {AppContext} from '../../Context'
import { v4 as uuidv4 } from "uuid";
import {
  faBoxesStacked,
  faCalendarXmark,
  faEnvelope,
  faGroupArrowsRotate,
  faMoneyBillTransfer,
  faSquareCheck,
  faSquarePollVertical,
  faToggleOn,
  faUser,
  faUsers,
  faPersonCircleCheck,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";

// import burger from "../assets/burger.svg";
// import close from "../assets/close.svg";
// import animateScrollTo from "animated-scroll-to";

// interface Props {
//   navToggle: boolean;
// }
interface Props {
  toggle: boolean;
}
interface INav {
  display: string;
}

const Header: React.FC<Props> = (props) => {
  const {userRole,handleUserProfile} =useContext(AppContext);
  const navigate = useNavigate();
  const LogoutUser = () => {
    localStorage.clear();
    handleUserProfile();
    navigate("/");
  };
  
  // const [userStatus, setUserStatus] = useState({ status: false });
  // const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
  //   setUserStatus({
  //     ...userStatus,
  //     [event.currentTarget.name]:event.currentTarget.checked,
  //   });

  //   localStorage.setItem("userStatus", userStatus ? 'true' :'false');
  //     if(userStatus.status){
  //     dispatchChangeUser({
  //       type:'USER_CHANGED',
  //       payload:{residents:'',externals:'externals'}
  //     })
  //   }else{
  //     dispatchChangeUser({
  //       type:'USER_CHANGED',
  //       payload:{residents:'residents',externals:''}
  //     })
  //   }
  // };

  // useEffect(()=>{
  //   let UserStorageStatus = localStorage.getItem('userStatus') === 'true' ? true :false
  //   console.log(UserStorageStatus)
  //   setUserStatus({...userStatus, status:UserStorageStatus})
  // },[]);

  // console.log(userState)
  const Item = [
    {
      name: "Dashboard",
      icon: <FontAwesomeIcon icon={faSquarePollVertical} />,
      route: "/dashboard",
      active: "false",
      display: "flex",
    },
    {
      name: "Approvals",
      icon: <FontAwesomeIcon icon={faPersonCircleCheck}/>,
      route: "/admin/approval",
      active: "true",
      // display: (userState?.userState?.residents) ?'flex' :'none'
      display: userRole==='estate_admin' ? 'flex' : 'none',
    },
    {
      name: "Members",
      icon: <FontAwesomeIcon icon={faUsers} />,
      route: "/member",
      active: "false",
      // display: userRole==='resident' ? 'flex' : 'none',
      display:"none"
      // display: (userState?.userState?.residents) ?'flex' :'none'
    },
    {
      name: "Clients",
      icon: <FontAwesomeIcon icon={faUser} />,
      route: "/client",
      active: "false",
      display:userRole==='super_admin' ? 'flex' : 'none',
    },
    {
      name: "Complaints",
      icon: <FontAwesomeIcon icon={faCalendarXmark} />,
      route: "/complaint",
      active: "false",
      display: "none",
    },
    {
      name: "Messaging",
      icon: <FontAwesomeIcon icon={faEnvelope}/>,
      route: "/message",
      active: "false",
      // display: (userState?.userState?.residents) ?'flex' :'none'
      display: "none",
    },
    {
      name: "Residents",
      icon: <FontAwesomeIcon icon={faUsers} />,
      route: "/resident",
      active: "false",
      // display:userRole==='super_admin'|| userRole==='estate_admin' ? 'flex' : 'none',
      display: 'none'
    },
    {
      name: "Utilities",
      icon: <FontAwesomeIcon icon={faGroupArrowsRotate} />,
      route: "/utility",
      active: "false",
      display: "none",
    },
    {
      name: "Transactions",
      icon: <FontAwesomeIcon icon={faMoneyBillTransfer} />,
      route: "/transaction",
      active: "false",
      // display:userRole==='estate_admin' ? 'flex' : 'none',
      display:'none'
    },
    {
      name: "Activity Log",
      icon: <FontAwesomeIcon icon={faBoxesStacked} />,
      route: "/activity",
      active: "false",
      // display:(userRole==='security' || userRole==='resident') ? 'flex' : 'none',
      display: "none",
    },
    {
      name: "Tasks",
      icon: <FontAwesomeIcon icon={faSquareCheck} />,
      route: "/task",
      active: "false",
      // display: (userState?.userState?.residents) ?'flex' :'none'
      display: "none",
    },
    {
      name: "Permissions",
      icon: <FontAwesomeIcon icon={faToggleOn} />,
      route: "/permission",
      active: "false",
      display:userRole==='super_admin' ? 'flex' : 'none',
    },
    {
      name: "Personnels",
      icon: <FontAwesomeIcon icon={faUserTie} />,
      route: "/personnel",
      active: "false",
      display:userRole==='estate_admin' ? 'flex' : 'none',
    },
  ];

  return (
    <>
      <Abs>
        <Wrapper>
          <LogoContainer to="/">
            <img src={Logo} alt="EstateIQ Logo" />
          </LogoContainer>
          <Nav>
            {Item.map((item) => {
              return (
                <NavItems key={uuidv4()}>
                  <Items to={item.route} display={item.display}>
                    <span>{item.icon}</span>
                    {item.name}
                  </Items>
                </NavItems>
              );
            })}
            <LogoutContainer>
              <hr />
              <Logout onClick={LogoutUser}>
                <LogoutOutlinedIcon /> Log Out
              </Logout>
            </LogoutContainer>
          </Nav>
        </Wrapper>
      </Abs>
      {props.toggle ? (
        <SmallWrapper>
          <LogoContainer to="/">
            <img src={Logo} alt="EstateIQ Logo" />
          </LogoContainer>
          <Nav>
            {Item.map((item) => {
              return (
                <NavItems key={uuidv4()}>
                  <Items to={item.route} display={item.display}>
                    <span>{item.icon}</span>
                    {item.name}
                  </Items>
                </NavItems>
              );
            })}
             <LogoutContainer>
              <hr />
              <Logout onClick={LogoutUser}>
                <LogoutOutlinedIcon /> Log Out
              </Logout>
            </LogoutContainer>
          </Nav>
        </SmallWrapper>
      ) : null}
    </>
  );
};

export default Header;
const Abs = styled.div`
  position: relative;
  width: 241px;
  top: 0;
  left: 0;
  @media (max-width: 991px) {
    display: none;
  }
`;
const Wrapper = styled.div`
  background-color: var(--mainBlue);
  width: 241px;
  height: 100vh;
  position: fixed;
  z-index: 3;
  @media (max-width: 991px) {
    display: none;
  }
`;
const SmallWrapper = styled.div`
  background-color: var(--mainBlue);
  width: 241px;
  height: 100vh;
  position: fixed;
  z-index: 3;
  @media (min-width: 991px) {
    display: none;
  }
`;
const LogoContainer = styled(Link)`
  width: 90px;
  height: 24px;
  margin: auto;
  img {
    margin-top: 37px;
  }
`;
const Nav = styled.div`
  width: 200px;
  margin: auto;
  margin-top: 70px;
  .active {
    color: green;
    text-decoration: none;
    background-color: white !important;
  }
`;
const NavItems = styled.div`
  width: 200px;
`;
const Items = styled(NavLink)<INav>`
  width: 130px;
  padding-left: 25px;
  padding-right: 25px;
  margin: auto;
  display: ${(props) => props.display};
  // display: flex;
  align-items: center;
  color: white;
  height: 50px;
  font-size: 12px;
  text-decoration: none;
  :hover {
    // background-color:#F0E5F2;
    color: black;
    border-radius: 3px;
  }
  span {
    width: 40px;
  }
`;
const LogoutContainer = styled.div`
  position: absolute;
  bottom: 32px;
  width: 180px;
  font-size: 12px;
  hr {
    border: 0.5px solid #e1e1e1;
  }
  svg {
    font-size: 12px;
  }
`;
const Logout = styled.div`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  padding-top: 12px;
  :hover {
    cursor: pointer;
  }
`;
