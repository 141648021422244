import React, { useState, useContext } from "react";
import styled from "styled-components";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import EmojiFlagsOutlinedIcon from "@mui/icons-material/EmojiFlagsOutlined";
// import Switch from "@material-ui/core/Switch";
import PersonOutlineSharpIcon from "@mui/icons-material/PersonOutlineSharp";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
// import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
// import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
// import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// import Alert from "../Constants/Alert";
// import IconImage from "../../assets/estate.png";
import { AppContext } from "../../Context/index";

const AdminProfile = () => {
  const { userData } = useContext(AppContext);
  // console.log(userData)
  const [doc, setDoc] = useState<any>();
  // const [deactivate, setDeactivate] = useState({ active: true });
  // const [open, setOpen] = useState(true);
  // const [CopyMemberID, setCopyMemberId] = useState(false);

  // const handleDeactivate = (event: React.FormEvent<HTMLInputElement>) => {
  //   setDeactivate({
  //     ...deactivate,
  //     [event.currentTarget.name]: event.currentTarget.checked,
  //   });
  // };

  // const closeAlertModal = () => {
  //   setOpen(false);
  // };
  const onChangeFile = (e: React.FormEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];
    setDoc(file);
    // setPersonnelFormState({
    //   ...personnelFormState,doc:file
    // })
    // const formData = new FormData();

    // formData.append("file", file);
    // constants.httprequeset
    //   .post(`base/upload`, formData)
    //   .then((res) => {
    //     setFileURL(res.data.data);
    //   })
    //   .catch((error) => {});
  };

  return (
    <>
      <AdminWrapper>
        <ImageUploadSection>
          <ImageDisplay>
            <PersonOutlineSharpIcon />
          </ImageDisplay>
          <ProfileDetails>
            <h4>{userData?.firstName + " " + userData?.lastName}</h4>
            <p>{userData?.email}</p>
            <UploadDP>
              {doc?.name ? doc.name : "Upload Image"}
              <input type="file" onChange={onChangeFile} />
            </UploadDP>
          </ProfileDetails>
        </ImageUploadSection>

        <SubHeader>Information</SubHeader>
        <Information>
          <Address>
            {/* <LocationOnOutlinedIcon /> */}
            {/* 10B, Wiseman Crescent, Golden Gate Estate. */}
          </Address>
          <h3>{userData?.contactPhoneNumber}</h3>
        </Information>
        <SubHeader>Estates</SubHeader>
        <EstateList>
          {userData?.estates?.map((estate:any,i:any) => {
            return (
              <EstateCard key={i}>
                <h4>{estate?.name}</h4>
                <LocationInCard>
                  <LocationOnOutlinedIcon />
                  <p>{estate?.address}</p>
                </LocationInCard>
                <EstateDetails>
                  <div>
                    <h5>Zone</h5>
                    <div>
                      <EmojiFlagsOutlinedIcon /> <span>{estate?.zone}</span>
                    </div>
                  </div>
                  <div>
                    <h5>Residents</h5>
                    <h1>{estate?.estimatedNumberofResidents}</h1>
                  </div>
                </EstateDetails>
              </EstateCard>
            );
          })}
        </EstateList>

        {/* <SubHeader>Members</SubHeader>
        <MembersCard>
          <Members>
            <DP>
              <img src={IconImage} alt="Estate IQ" />
            </DP>
            <Name>
              <h4>Oladoye Tolulope</h4>
              <p>goldengateestate@gmail.com</p>
            </Name>
            <CTA>
              <div>
                <EmailOutlinedIcon />
              </div>
              <div>
                <LocalPhoneOutlinedIcon />
              </div>
            </CTA>

            <Copy>
              <p>10B-002</p>

              <CopyToClipboard
                text="10B-002"
                onCopy={() => setCopyMemberId(true)}
              >
                <div onClick={() => setOpen(true)}>
                  <ContentCopyOutlinedIcon />
                </div>
              </CopyToClipboard>
            </Copy>
            {CopyMemberID ? (
              <Alert
                open={open}
                alertSeverity="success"
                alertTitle="Copied"
                closeModal={closeAlertModal}
              />
            ) : null}
            <DeActivate>
              <FormControlLabel
                control={
                  <Switch
                    checked={deactivate.active}
                    onChange={handleDeactivate}
                    color="primary"
                    name="active"
                    size="small"
                  />
                }
                label=""
              />
            </DeActivate>
          </Members>
        </MembersCard> */}
      </AdminWrapper>
    </>
  );
};

export default AdminProfile;

const AdminWrapper = styled.div`
  padding-top: 40px;
`;
const ImageUploadSection = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 40px;
`;
const ProfileDetails = styled.div`
  margin-left: 20px;
  h4 {
    font-size: 24px;
    // letter-spacing:-0.8px;
    font-weight: bold;
    margin-bottom: 0px;
    margin-left: 0px;
    text-align: left;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.8px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #686868;
  }
  @media(max-width:576px){
    font-size:14px;
  }
`;
const ImageDisplay = styled.div`
  background-color: #fbfbfb;
  width: 147px;
  height: 147px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 53px;
    color: #c5cae4;
    font-weight: 300;
  }
`;
const UploadDP = styled.div`
  position: relative;
  width: 150px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c5cae4;
  border-radius: 5px;
  color: var(--mainBlue);
  font-size: 12px;
  :hover {
    cursor: pointer;
  }
  input {
    position: absolute;
    width: 150px;
    opacity: 0;
    cursor: pointer;
  }
`;
const SubHeader = styled.div`
  font-size: 10px;
  font-weight: 700;
  padding-top: 15px;
  padding-bottom: 15px;
  letter-spacing: -0.7px;
  color: #c5cae4;

  text-align: left;
`;
const EstateList = styled.div`
  display: flex;
  gap: 30px;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 25px;
  flex-wrap:wrap;
  margin:auto;
`;
const EstateCard = styled.div`
  width: 240px;
  padding: 20px;
  // height:390px;
  text-align: left;
  border: 1px solid #c5cae4;
  border-radius: 5px;
  
`;
const LocationInCard = styled.div`
  display: flex;
  gap: 10px;
  // margin-top:25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #f5f5f5;

  svg {
    color: var(--mainBlue);
  }
  p {
    font-size: 10px;
    letter-spacing: -0.6px;
    color: #686868;
    font-weight: 700;
    color: #00ba07;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;
const EstateDetails = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  svg {
    color: var(--mainPurple);
    font-size: 10px;
    font-weight: 700;
  }
  span {
    font-size: 10px;
    color: var(--mainPurple);
    letter-spacing: -0.6px;
    font-weight: 700;
  }
  h5 {
    color: #c5cae4;
    font-size: 10px;
    margin-bottom: 5px;
  }
  h1 {
    font-weight: 700;
    color: var(--mainBlack);
    font-size: 24px;
    margin-top: 0px;
  }
`;
const Address = styled.div`
  width: 330px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.8px;
  color: var(--mainBlack);
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    color: var(--mainBlue);
  }
  @media (max-width: 1200px) {
    font-size: 12px;
    width: 230px;
    margin-left: 15px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const Information = styled.div`
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  h3 {
    margin-bottom: 0px;
    margin-top: 0px;
    color: var(--mainBlack);
    font-size: 18px;
    font-weight: bold;
  }
`;
// const MembersCard = styled.div`
//   width: 100%;
//   p {
//     font-size: 12px;
//     font-weight: bold;
//     letter-spacing: -1px;
//     color: #c5cae4;
//   }
//   // @media(max-width:1200px){
//   //   width:600px;
//   // }
// `;
// const Members = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;
// const DP = styled.div`
//   width: 31px;
//   height: 31px;
//   img {
//     border-radius: 50%;
//   }
// `;
// const Name = styled.div`
//   margin-left: 25px;
//   width: 150px;
//   text-align:left;
//   h4 {
//     letter-spacing: -1px;
//     font-size: 14px;
//     font-weight: bold;
//     letter-spacing: -1px;
//     margin-bottom: 0px;
//     margin-top: 0px;
//   }
//   p {
//     color: #686868;
//     letter-spacing: 1px;
//     font-size: 12px;
//     letter-spacing: -0.8px;
//     font-weight: 300;
//     margin-bottom: 0px;
//     margin-top: 0px;
//   }
// `;
// const CTA = styled.div`
//   display: flex;
//   width: 58px;
//   justify-content: space-between;
//   div {
//     width: 23px;
//     height: 23px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 50%;
//     border: 1px solid var(--mainBlue);
//     :hover {
//       background-color: var(--mainBlue);
//       svg {
//         color: white;
//       }
//     }
//   }
//   svg {
//     width: 13px;
//     height: 13px;
//     color: var(--mainBlue);
//   }
// `;
// const Copy = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100px;

//   p {
//     font-size: 14px;
//     font-weight: bold;
//     letter-spacing: -1px;
//     margin-top: 0px;
//     margin-bottom: 0px;
//   }
//   div {
//     background-color: #f5f5f5;
//     width: 35px;
//     height: 35px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 5px;
//   }
//   svg {
//     color: var(--mainBlue);
//     font-size: 18px;
//   }
//   @media (max-width: 576px) {
//     display: none;
//   }
// `;
// const DeActivate = styled.div`
//   width: 32px;
//   align-items: center;
// `;
