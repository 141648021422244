import React, { useState } from "react";
import styled from "styled-components";
import authImage from "../../assets/authImage.jpg";
import PasswordStrengthBar from "react-password-strength-bar";
import Logo from "../../assets/logo-colored.svg";
import { Link, useNavigate } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import axios from "axios";
import { BASEURL } from "../Constants/Constant";
import loader from "../../assets/loader.gif";
import Alert from "../Constants/Alert";

type Props = {};
const Signup = (props: Props) => {
  const [open, setOpen] = useState(true);
  const [formState, setFormState] = useState({
    password: "",
    email: "",
    firstName: "",
    lastName: "",
    cfPassword: "",
    contactPhoneNumber: "",
    isLoading: false,
    passwordErr: "",
    passwordType: "password",
    cfPasswordType: "password",
    formError: [],
    terms:false
  });
  const [error, setError] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    cfPassword: "",
    contactPhoneNumber: "",
  });
  const navigate = useNavigate();
  const closeAlertModal = () => {
    setOpen(false);
  };
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };
  const handleCheck = (event: React.FormEvent<HTMLInputElement>) =>{
    setFormState({
      ...formState,
    terms:!formState.terms
    })
  }
  const validateInput = (event: React.FormEvent<HTMLInputElement>) => {
    let { name, value } = event.currentTarget;
    setError((prev: any) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "firstName":
          if (!value) {
            stateObj[name] = "Please Enter your First Name";
          }
          break;
        case "lastName":
          if (!value) {
            stateObj[name] = "Please Enter your Last Name";
          }
          break;
        case "contactPhoneNumber":
          if (!value) {
            stateObj[name] = "Enter your Phone Number";
          }
          break;
        case "email":
          if (!value) {
            stateObj[name] = "Please Enter your Email";
          }
          break;
        case "password":
          if (!value) {
            stateObj[name] = "Please Enter Password";
          } else if (formState.cfPassword && value !== formState.cfPassword) {
            stateObj["cfPassword"] =
              "Password and Confrim Pasword do not match";
          } else {
            stateObj["cfPassword"] = formState.cfPassword
              ? ""
              : error.cfPassword;
          }
          break;
        case "cfPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm password";
          } else if (formState.password && value !== formState.password) {
            stateObj[name] = "Password and Confrim Pasword do not match";
          }
          break;
        default:
          break;
      }

      return stateObj;
    });
  };

  const handleSignUp = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormState({ ...formState, isLoading: true, formError: [] });
    const payload = { ...formState };
    axios
      .post(`${BASEURL}/auth/register`, payload)
      .then((res) => {
        console.log(res);
        localStorage.setItem("adminToken", res.data.token);
        if (res.status === 201) {
          navigate("/auth/options");
          setFormState({
            ...formState,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        setFormState({
          ...formState,
          isLoading: false,
          formError: Object.values(err?.response?.data?.message),
        });
        console.log(err.response.data.message);
        // console.log(Object.values(err.response.data.message))
        // console.log(typeof(err.response))
        // console.log(Object.values(err.response.data.message))
      });
  };
  const isDisabled = () => {
    const {
      firstName,
      lastName,
      cfPassword,
      password,
      contactPhoneNumber,
      email,
      terms
    } = formState;
    if (
      firstName &&
      lastName &&
      cfPassword &&
      password &&
      contactPhoneNumber &&
      email && terms
    ) {
      return false;
    }
    return true;
  };

  const togglePassword = () => {
    if (formState.passwordType === "password") {
      setFormState({
        ...formState,
        passwordType: "text",
      });
    } else {
      setFormState({
        ...formState,
        passwordType: "password",
      });
    }
  };
  const toggleCfPassword = () => {
    if (formState.cfPasswordType === "password") {
      setFormState({
        ...formState,
        cfPasswordType: "text",
      });
    } else {
      setFormState({
        ...formState,
        cfPasswordType: "password",
      });
    }
  };
  return (
    <Wrapper>
      <Left>
        <Content>
          <IconContainer>
            <Link to="/">
              <img src={Logo} alt="Estate IQ" />
            </Link>
          </IconContainer>
          <FormHeader>
            <h3>Create Account</h3>
          </FormHeader>
          <StyledForm onSubmit={handleSignUp}>
            <FlexFormGroup>
              <div>
                <label>
                  First Name{" "}
                  {error.firstName && (
                    <span className="err"> *** {error.firstName} *** </span>
                  )}
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formState.firstName}
                  onChange={handleChange}
                  onBlur={validateInput}
                />
              </div>
              <div>
                <label>
                  Last Name{" "}
                  {error.lastName && (
                    <span className="err"> *** {error.lastName} *** </span>
                  )}
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formState.lastName}
                  onChange={handleChange}
                  onBlur={validateInput}
                />
              </div>
            </FlexFormGroup>

            <FormGroup>
              <label>Email Address</label>
              <input
                type="email"
                name="email"
                value={formState.email}
                onChange={handleChange}
                onBlur={validateInput}
              />
              {error.email && <span className="err">{error.email}</span>}
            </FormGroup>
            <FormGroup>
              <label>Password</label>
              <input
                type={formState.passwordType}
                name="password"
                value={formState.password}
                onChange={handleChange}
                onBlur={validateInput}
              />
              <Eye>
                {formState.passwordType === "password" ? (
                  <VisibilityOutlinedIcon onClick={togglePassword} />
                ) : (
                  <VisibilityOffOutlinedIcon onClick={togglePassword} />
                )}
              </Eye>
            </FormGroup>
            {error.password && <p className="err">{error.password}</p>}
            <FormGroup>
              <label>Confirm Password</label>
              <input
                type={formState.cfPasswordType}
                name="cfPassword"
                value={formState.cfPassword}
                onChange={handleChange}
                onBlur={validateInput}
              />
              <Eye>
                {formState.cfPasswordType === "password" ? (
                  <VisibilityOutlinedIcon onClick={toggleCfPassword} />
                ) : (
                  <VisibilityOffOutlinedIcon onClick={toggleCfPassword} />
                )}
              </Eye>
            </FormGroup>
            {error.cfPassword && (
              <span className="err">{error.cfPassword}</span>
            )}
            <FormGroup>
              <label>Contact Phone Number</label>
              <input
                type="tel"
                name="contactPhoneNumber"
                value={formState.contactPhoneNumber}
                onChange={handleChange}
                onBlur={validateInput}
              />
            </FormGroup>
            {error.contactPhoneNumber && (
              <span className="err">{error.contactPhoneNumber}</span>
            )}
            <FormGroup>
              <PasswordStrengthBar password={formState.password} />
            </FormGroup>
            <CheckBoxContainer>
              <CheckBox type="checkbox" name="terms" checked={formState.terms} onChange={handleCheck}/>
              <label>I agree to the <Link to="/terms" target="_blank" rel="noopener noreferrer">terms and conditions</Link></label>
            </CheckBoxContainer>
            <ButtonContainer>
              {formState.isLoading ? (
                <LoadingButton>
                  <img src={loader} alt="Estate IQ" />
                </LoadingButton>
              ) : (
                <StyledButton
                  type="submit"
                  disabled={isDisabled()}
                  value="Sign Up"
                />
              )}
              {formState.formError.length < 2 ? (
                formState.formError.map((err: any, i) => {
                  return (
                    <Alert
                      open={open}
                      alertSeverity="error"
                      alertTitle={err}
                      closeModal={closeAlertModal}
                    />
                  );
                  // <FormErr key={i}>{err}</FormErr>
                })
              ) : (
                <Alert
                  open={open}
                  alertSeverity="error"
                  alertTitle={formState.formError}
                  closeModal={closeAlertModal}
                />
              )}

              {/* // <FormErr>{formState.formError}</FormErr> */}
            </ButtonContainer>
            <div className="login_from_signup">
              <Link to="/">You have an account? Click here to login</Link>
            </div>
          </StyledForm>
        </Content>
      </Left>
      <Right></Right>
    </Wrapper>
  );
};

export default Signup;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;
const Left = styled.div`
  width: 65%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const Right = styled.div`
  width: 35%;
  height: 1000px;
  background-image: url(${authImage});
  background-size: cover;
  @media (min-width: 1366px) {
    height: 100vh;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;
const Content = styled.div`
  width: 500px;
  margin: auto;
  padding-top: 40px;
  @media (min-width: 1367px) {
    padding-top: 85px;
  }
  @media (max-width: 1240px) {
    padding-top: 40px;
  }
  @media (max-width: 576px) {
    width: 80%;
    margin: auto;
  }
`;
const IconContainer = styled.div`
  text-align: left;
  img {
    width: 135px;
    height: 64px;
  }
  @media (max-width: 991px) {
    text-align: left;
    img {
      width: 111px;
      height: 29px;
    }
  }
`;
const FormHeader = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: left;
  h3 {
    margin-top: 0px;
    margin-bottom: 1px;
    font-size: 38px;
    letter-spacing: -2px;
    color: #111111;
    font-weight: bold;
  }
  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 12px;
    color: #545454;
  }
  @media (min-width: 1367px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  @media (max-width: 1240px) {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  @media (max-width: 576px) {
    text-align: left;
    margin-top: 50px;
    h3 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
      letter-spacing: -1px;
    }
    p {
      font-size: 12px;
    }
  }
`;
const StyledForm = styled.form`
  width: 100%;
  .err {
    font-size: 10px;
    margin-top: 0px;
    color: tomato;
    text-align: left;
  }
  .login_from_signup {
    width: 100%;
    text-align: right;
    margin-left: auto;
  }
`;
// const FormErr = styled.div `
// font-size: 10px;
// margin-top: 2px;
// margin-bottom:2px;
// color: tomato;
// text-align: right;
// `
const FlexFormGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 102%;
  div {
    width: 50%;
    text-align: left;
  }
  input {
    width: 90%;
    background: #fbfbfb;
    border: 1px solid #c5cae4;
    border-radius: 4px;
    height: 58px;
    padding-left: 10px;
  }
  label {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #686868;
  }
  @media (max-width: 1366px) {
    input {
      height: 48px;
    }
  }
  @media (max-width: 576px) {
    flex-direction: column;
    width: 97%;
    div {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    input {
      width: 100%;
    }
  }
`;
const FormGroup = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  position: relative;
  input {
    width: 97%;
    background: #fbfbfb;
    border: 1px solid #c5cae4;
    border-radius: 4px;
    height: 58px;
    padding-left: 10px;
  }
  label {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #686868;
  }
  @media (max-width: 1366px) {
    input {
      height: 48px;
    }
  }
`;
const Eye = styled.div`
  position: absolute;
  right: 10px;
  bottom: 13px;

  svg {
    font-size: 14px;
  }
`;
const StyledButton = styled.input`
  width: 363px;
  height: 51px;
  border-color: transparent;
  border-radius: 6px;
  color: white;
  background-color: var(--mainBlue);
  font-size: 12px;
  margin-bottom: 10px;
  :disabled {
    background-color: #ccc;
    color: black;
  }
  :disabled:hover {
    background-color: #ccc;
    color: black;
  }
  @media (max-width: 426px) {
    width: 100%;
    margin: auto;
  }
  :hover {
    background-color: #f2cd16;
    color: var(--mainBlue);
  }
`;
const LoadingButton = styled.button`
  width: 363px;
  height: 51px;
  border-color: transparent;
  border-radius: 6px;
  color: white;
  background-color: var(--mainBlue);
  font-size: 12px;
  margin-bottom: 10px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
    // height:45px;
  }
  @media (max-width: 426px) {
    width: 100%;
    margin: auto;
  }
  :hover {
    background-color: #f2cd16;
    color: var(--mainBlue);
  }
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  text-align: right;
  a {
    width: 363px;
    padding: 12px 88px;
    font-size: 16px;
    color: white;
    font-weight: 400;
    text-decoration: none;
  }
  @media (min-width: 1367px) {
    margin-top: 70px;
  }
  @media (max-width: 576px) {
    text-align: center;
    a {
      font-size: 12px;
    }
  }
`;
const CheckBoxContainer = styled.div `
display:flex;
align-items:center;
gap:5px;
label{
  font-size:11px;
}

`
const CheckBox = styled.input`
width:12px;!important;
height:12px!important;

`;
