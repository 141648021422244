import React, { useState } from "react";
import styled from "styled-components";
import IconImage from "../../assets/estate.png";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Alert from "../Constants/Alert";

export default function ResidentMembers() {
  const [state, setState] = useState({ status: true });
  const [deactivate, setDeactivate] = useState({ active: true });
  const [open, setOpen] = useState(true);
  const [CopyMemberID, setCopyMemberId] = useState(false);

  // Change State Function
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.currentTarget.name]: event.currentTarget.checked,
    });
  };
  const handleDeactivate = (event: React.FormEvent<HTMLInputElement>) => {
    setDeactivate({
      ...deactivate,
      [event.currentTarget.name]: event.currentTarget.checked,
    });
  };

  const closeAlertModal = () => {
    setOpen(false);
  };
 
  return (
    <Wrapper>
      <HouseContainer>
        <h3> House 10B</h3>
        <HouseCount>
          <div>
            <HomeOutlinedIcon />
          </div>
          <div>
            <p>House Members</p>
            <h3>5</h3>
          </div>
        </HouseCount>
      </HouseContainer>
      <SubPackage>
        <LeftPackage>
          <HomeWorkIcon />
          {/* <FontAwesomeIcon icon={faHouseChimneyWindow}/> */}
          <p>E1Q4RG</p>
        </LeftPackage>
        <RightPackage>
          <div>Delexue Package Subscription</div>
          <div>10 Days Left</div>
          <FormControlLabel
            control={
              <Switch
                checked={state.status}
                onChange={handleChange}
                color="primary"
                name="status"
                size="small"
              />
            }
            label=""
          />
        </RightPackage>
      </SubPackage>
      <Address>
        <LocationOnOutlinedIcon />{" "}
        <h4>10B, Wiseman Crescent, Golden Gate Estate.</h4>
      </Address>
      <ContactInfo>
        <p>Contact Info</p>
        <div>
          <h4>Goldengateestate@gmail.com</h4> <h4>+2348003948299</h4>
        </div>
      </ContactInfo>
      <MembersCard>
        <p>Members</p>
        <Members>
          <DP>
            <img src={IconImage} alt="Estate IQ" />
          </DP>
          <Name>
            <h4>Oladoye Tolulope</h4>
            <p>goldengateestate@gmail.com</p>
          </Name>
          <CTA>
            <div>
              <EmailOutlinedIcon />
            </div>
            <div>
              <LocalPhoneOutlinedIcon />
            </div>
          </CTA>

          <Copy>
            <p>10B-002</p>

            <CopyToClipboard
              text="10B-002"
              onCopy={() => setCopyMemberId(true)}
            >
              <div onClick={() => setOpen(true)}>
                <ContentCopyOutlinedIcon />
              </div>
            </CopyToClipboard>
          </Copy>
          {CopyMemberID ? (
            <Alert
              open={open}
              alertSeverity="success"
              alertTitle="Copied"
              closeModal={closeAlertModal}
            />
          ) : null}
          <DeActivate>
            <FormControlLabel
              control={
                <Switch
                  checked={deactivate.active}
                  onChange={handleDeactivate}
                  color="primary"
                  name="active"
                  size="small"
                />
              }
              label=""
            />
          </DeActivate>
        </Members>
      </MembersCard>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  // width:100%:
  width: 723px;
  text-align: left;
  @media(max-width:1200px){
    width:600px;
  }
  @media (max-width: 991px) {
    width:100%;
    margin: auto;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  // @media (max-width: 576px) {
  //   width: 400px;
  // }
  // @media (max-width: 426px) {
  //   width: 330px;
  // }
`;

const HouseContainer = styled.div`
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3 {
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -1px;
  }
  @media(max-width:576px){
    width:100%;
    h3{
      font-size:18px;
    }
  }
`;
const HouseCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 235px;
  height: 80px;
  border: 1px solid rgba(45, 75, 243, 0.2);
  border-radius: 5px;

  div {
    :first-child {
      background-color: #f4f6fe;
      border-radius: 5px;
      padding: 10px;
      svg {
        color: var(--mainBlue);
      }
    }
    :last-child {
      margin-left: 16px;
      h3 {
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 0px;
      }
      p {
        color: #c5cae4;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 12px;
      }
    }
  }
  @media(max-width:576px){
    width:180px;
  }
`;
const SubPackage = styled.div`
  background-color: #f4f6fe;
  width: 100%;
  padding: 12px 17px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  border-radius: 5px;
`;
const LeftPackage = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 10px;
    margin-left: 5px;
  }
  svg {
    color: var(--mainBlue);
  }
`;
const RightPackage = styled.div`
  display: flex;
  align-items: center;
  div {
    :first-child {
      color: #686868;
      background-color: #d6deff;
      color: #686868;
      padding: 6px 14px;
      font-size: 10px;
      font-weight: bold;
      border-radius: 5px;

      letter-spacing: -1px;
    }
    :nth-child(2) {
      color: #b50c0c;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: -1px;
      margin-right: 15px;
      margin-left: 10px;
    }
  }
  @media(max-width:476px){
    div{
      :first-child{
        padding:4px 10px;
      }
    }
  }
`;
const Address = styled.div`
  display: flex;
  align-items: center;
  padding-top: 30px;
  width: 540px;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 30px;
  svg {
    color: var(--mainBlue);
  }
  h4 {
    color: #171717;
    font-weight: bold;
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
  }
  @media(max-width:576px){
    width:100%;
  }
  @media(max-width:426px){
    h4{
      font-size:12px;
    }
  }
`;
const ContactInfo = styled.div`
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 30px;
  width: 540px;
  p {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -1px;
    color: #c5cae4;
  }
  div {
    display: flex;
    width: 450px;
    justify-content: space-between;
  }
  @media(max-width:576px){
    width:100%;
    div{
      flex-direction:column;
      width:100%;
      h4{
        margin-top:5px;
        margin-bottom:5px;
        
      }
    }
  }
`;
const MembersCard = styled.div`
  width: 100%;
  p {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -1px;
    color: #c5cae4;
  }
  // @media(max-width:1200px){
  //   width:600px;
  // }
`;
const Members = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const DP = styled.div`
  width: 31px;
  height: 31px;
  img {
    border-radius: 50%;
  }
`;
const Name = styled.div`
  margin-left: 25px;
  width: 150px;
  h4 {
    letter-spacing: -1px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -1px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  p {
    color: #686868;
    letter-spacing: 1px;
    font-size: 12px;
    letter-spacing: -0.8px;
    font-weight: 300;
    margin-bottom: 0px;
    margin-top: 0px;
  }
`;
const CTA = styled.div`
  display: flex;
  width: 58px;
  justify-content: space-between;
  div {
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid var(--mainBlue);
    :hover {
      background-color: var(--mainBlue);
      svg {
        color: white;
      }
    }
  }
  svg {
    width: 13px;
    height: 13px;
    color: var(--mainBlue);
  }
`;
const Copy = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;

  p {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -1px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  div {
    background-color: #f5f5f5;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
  svg {
    color: var(--mainBlue);
    font-size: 18px;
  }
  @media(max-width:576px){
    display:none;
  }
`;
const DeActivate = styled.div`
  width: 32px;
  align-items: center;
`;
