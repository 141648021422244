import React from "react";
import styled from "styled-components";
import IconImage from "../../assets/estate.png";
import HowToVoteOutlinedIcon from "@mui/icons-material/HowToVoteOutlined";

type Props = {};

const ResidentsComplaints = (props: Props) => {
  return (
    <Wrapper>
      <StatTable>
        <Content>
          <IconBG>
            <HowToVoteOutlinedIcon />
          </IconBG>
          <div>
            <p>Resolve Rate</p>
            <h1>50 %</h1>
          </div>
        </Content>
        <Content>
          <div>
            <p>Total Complains</p>
            <h2>100</h2>
          </div>
        </Content>
        <Content>
          <div>
            <p>Resolved Complaints</p>
            <h2>50</h2>
          </div>
        </Content>
      </StatTable>
      <Container>
        <CardWrapper>
          <CardContent>
            <CardHeader>
              <LeftCardHeader>
                <img src={IconImage} alt="Estate IQ" />
                <h2>Oladokun Moses</h2>
                <div>Member</div>
              </LeftCardHeader>
              <RightCardHeader>
                <h2>EST19</h2>
              </RightCardHeader>
            </CardHeader>
            <CardBody>
              This dummy text is supposed to include complainets from respective
              admins, either escalated or direct, and the super admin has the
              prerogagative to mark them as resolved as soon as he attends to
              them
            </CardBody>
            <CardFooter>
              <div>
                <p>3 , June 2021 </p>
                <p> 07:43PM</p>
              </div>
              <h6>Resolved</h6>
            </CardFooter>
          </CardContent>
        </CardWrapper>
        <CardWrapper>
          <CardContent>
            <CardHeader>
              <LeftCardHeader>
                <img src={IconImage} alt="Estate IQ" />
                <h2>Oladokun Moses</h2>
                <div>Member</div>
              </LeftCardHeader>
              <RightCardHeader>
                <h2>EST19</h2>
              </RightCardHeader>
            </CardHeader>
            <CardBody>
              This dummy text is supposed to include complainets from respective
              admins, either escalated or direct, and the super admin has the
              prerogagative to mark them as resolved as soon as he attends to
              them
            </CardBody>
            <CardFooter>
              <div>
                <p>3 , June 2021 </p>
                <p> 07:43PM</p>
              </div>
              <h6>Resolved</h6>
            </CardFooter>
          </CardContent>
        </CardWrapper>
        <CardWrapper>
          <CardContent>
            <CardHeader>
              <LeftCardHeader>
                <img src={IconImage} alt="Estate IQ" />
                <h2>Oladokun Moses</h2>
                <div>Member</div>
              </LeftCardHeader>
              <RightCardHeader>
                <h2>EST19</h2>
              </RightCardHeader>
            </CardHeader>
            <CardBody>
              This dummy text is supposed to include complainets from respective
              admins, either escalated or direct, and the super admin has the
              prerogagative to mark them as resolved as soon as he attends to
              them
            </CardBody>
            <CardFooter>
              <div>
                <p>3 , June 2021 </p>
                <p> 07:43PM</p>
              </div>
              <h6>Resolved</h6>
            </CardFooter>
          </CardContent>
        </CardWrapper>
        <CardWrapper>
          <CardContent>
            <CardHeader>
              <LeftCardHeader>
                <img src={IconImage} alt="Estate IQ" />
                <h2>Oladokun Moses</h2>
                <div>Member</div>
              </LeftCardHeader>
              <RightCardHeader>
                <h2>EST19</h2>
              </RightCardHeader>
            </CardHeader>
            <CardBody>
              This dummy text is supposed to include complainets from respective
              admins, either escalated or direct, and the super admin has the
              prerogagative to mark them as resolved as soon as he attends to
              them
            </CardBody>
            <CardFooter>
              <div>
                <p>3 , June 2021 </p>
                <p> 07:43PM</p>
              </div>
              <h6>Resolved</h6>
            </CardFooter>
          </CardContent>
        </CardWrapper>
        <CardWrapper>
          <CardContent>
            <CardHeader>
              <LeftCardHeader>
                <img src={IconImage} alt="Estate IQ" />
                <h2>Oladokun Moses</h2>
                <div>Member</div>
              </LeftCardHeader>
              <RightCardHeader>
                <h2>EST19</h2>
              </RightCardHeader>
            </CardHeader>
            <CardBody>
              This dummy text is supposed to include complainets from respective
              admins, either escalated or direct, and the super admin has the
              prerogagative to mark them as resolved as soon as he attends to
              them
            </CardBody>
            <CardFooter>
              <div>
                <p>3 , June 2021 </p>
                <p> 07:43PM</p>
              </div>
              <h6>Resolved</h6>
            </CardFooter>
          </CardContent>
        </CardWrapper>
         <CardWrapper>
          <CardContent>
            <CardHeader>
              <LeftCardHeader>
                <img src={IconImage} alt="Estate IQ" />
                <h2>Oladokun Moses</h2>
                <div>Member</div>
              </LeftCardHeader>
              <RightCardHeader>
                <h2>EST19</h2>
              </RightCardHeader>
            </CardHeader>
            <CardBody>
              This dummy text is supposed to include complainets from respective
              admins, either escalated or direct, and the super admin has the
              prerogagative to mark them as resolved as soon as he attends to
              them
            </CardBody>
            <CardFooter>
              <div>
                <p>3 , June 2021 </p>
                <p> 07:43PM</p>
              </div>
              <h6>Resolved</h6>
            </CardFooter>
          </CardContent>
        </CardWrapper>
      </Container>
    </Wrapper>
  );
};

export default ResidentsComplaints;

const Wrapper = styled.div`
  width: 100%;
  padding-bottom:80px;
`;
const StatTable = styled.div`
  display: flex;
  // align-items:center;
  justify-content: space-around;
  border: 1px solid #ebd8f5;
  width: 624px;
  border-radius: 5px;
  padding-top: 23px;
  padding-bottom: 23px;
  margin-top: 30px;
//   margin-bottom: 30px;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  border-right: 1px solid #ebd8f5;
  padding-right: 30px;
  text-align: left;
  :last-child {
    border-right: transparent;
  }
  p {
    font-size: 10px;
    letter-spacing: -0.4px;
    color: #9d3ccb;
    margin-bottom: 10px;
    margin-top: 0px;
  }
  h1 {
    font-size: 48px;
    letter-spacing: -1px;
    font-weight: bold;
    color: var(--mainBlack);
    margin-bottom: 0px;
    margin-top: 0px;
  }
  h2 {
    font-size: 24px;
    color: var(--mainBlack);
    margin-bottom: 0px;
    margin-top: 0px;
  }
`;
const IconBG = styled.div`
color #9D3CCB;
background-color:#EBD8F5;
border-radius:5px;
display:flex;
align-items:center;
justify-content:center;
width:40px;
height:40px;
margin-right:10px;
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1366px) {
    width: 100%;
  }
  @media (min-width: 1366.1px) {
    width: 1080px;
  }
`;

const CardWrapper = styled.div`
  width: 530px;
  height: 153px;
  margin-top:35px;
  margin-bottom:35px;
`;
const CardContent = styled.div`
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 23px 30px;
  margin-top:20px;
  @media (max-width: 1366px) {
    width: 500px;
  }
`;
const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const LeftCardHeader = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 31px;
    height: 31px;
    border-radius: 50%;
  }
  h2 {
    font-size: 14px;
    font-weight: bold;
    color: var(--mainBlack);
    letter-spacing: -0.7px;
    margin-left: 11px;
    margin-right: 11px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  div {
    background-color: rgba(234, 203, 48, 0.1);
    border-radius: 2px;
    padding: 4px 18px;
  }
`;
const RightCardHeader = styled.div`
  h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -1px;
  }
`;
const CardBody = styled.div`
  width: 400px;
  padding: 8px 11px;
  background-color: white;
  border-radius: 5px;
  margin: auto;
  font-size: 12px;
  letter-spacing: -0.5px;
  color: #686868;
  text-align: left;
  margin-top: 18px;
  margin-bottom: 18px;
`;
const CardFooter = styled.div`
  width: 422px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    width: 116px;
    justify-content: space-between;
  }
  p {
    font-size: 12px;
    color: #686868;
    letter-spacing: -0.6px;
    line-height: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  h6 {
    color: var(--mainBlue);
    padding: 8px 40px;
    border-radius: 5px;
    background-color: white;
    letter-spacing: -0.6px;
    font-size: 10px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;
