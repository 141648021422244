import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { httprequeset } from "../Constants/Constant";
import { useNavigate } from "react-router-dom";
import loader from "../../assets/loader.gif";
import Modal from "../Constants/Modal";
import Success from "../Constants/Success";
import { AppContext } from "../../Context/index";

type Props = {
  closeFormToggle: any;
};

const JoinNew = (props: Props) => {
  const { handleUserProfile } = useContext(AppContext);
  const [allEstates, setAllEstates] = useState([]);
  const [hideInput, setHideInput] = useState(false);
  const [formState, setFormState] = useState({
    estateId: "",
    role: "resident",
    estateName: "",
    isLoading: false,
    formError: [],
    queryResult: [],
    createSuccess: false,
  });
  const handleHide = () => {
    setHideInput(!hideInput);
  };
  const chooseEstate = (id: any, name: any) => {
    handleHide();
    setFormState({
      ...formState,
      estateId: id,
      estateName: name,
    });
  };
  const navigate = useNavigate();
  useEffect(() => {
    httprequeset
      .get(`/estates`)
      .then((res) => {
        // console.log(res)
        setAllEstates(res?.data?.estates);
      })

      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          navigate("/");
        }
      });
  }, [navigate]);
  const [searchModal, setSearchModal] = useState(false);

  const SearchQuery = (event: React.FormEvent<HTMLInputElement>) => {
    const input = event.currentTarget.value;
    const result = allEstates?.filter((estate: any) => {
      return estate.name.toLowerCase().indexOf(input.toLowerCase()) > -1;
    });
    if (result?.length > 0) {
      setSearchModal(!searchModal);
      setFormState({ ...formState, queryResult: result });
    }
    setFormState({
      ...formState,
      queryResult: result,
    });
  };
  const ClearForm = () => {
    setFormState({
      ...formState,
      estateId: "",
      role: "resident",
      estateName: "",
      isLoading: false,
      formError: [],
      queryResult: [],
    });
  };
  const CloseSuccessMessage = () => {
    setFormState({ ...formState, createSuccess: false });
    navigate("/settings");
  };
  const handleSignUp = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormState({ ...formState, isLoading: true, formError: [] });
    const payload = { ...formState };
    // console.log(payload);
    httprequeset
      .post(`estates/${formState?.estateId}/join`, payload)
      .then((res) => {
        // console.log(res);
        if (res.status === 201) {
          ClearForm();
          handleUserProfile();
          setFormState({
            ...formState,
            createSuccess: true,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        if (err?.response.status===401){
          return navigate('/')
      }
        setFormState({
          ...formState,
          createSuccess: false,
          isLoading: false,
          formError: Object.values(err?.response?.data?.message),
        });
      });
  };
  const isDisabled = () => {
    if (formState.estateId) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div
      onClick={() => {
        setSearchModal(false);
      }}
    >
      <Form onSubmit={handleSignUp}>
        <CloseForm onClick={props.closeFormToggle}>
          <FontAwesomeIcon icon={faXmark} />
        </CloseForm>
        <SectionTitle>Join Estate</SectionTitle>
        <FormGroup>
          <label>Choose Estate</label>
          {hideInput ? (
            <input
              type="text"
              onMouseEnter={handleHide}
              value={formState.estateName}
            />
          ) : (
            <input type="text" name="estateName" onChange={SearchQuery} />
          )}
        </FormGroup>
        {searchModal ? (
          <SearchForm>
            <SearchModal>
              {formState.queryResult?.map((query: any, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      chooseEstate(query.id, query.name);
                    }}
                  >
                    {query?.name}
                  </div>
                );
              })}
            </SearchModal>
          </SearchForm>
        ) : null}
        {formState.isLoading ? (
          <LoadingButton>
            <img src={loader} alt="Estate IQ" />
          </LoadingButton>
        ) : (
          <CreateButton
            type="submit"
            value="Join Estate"
            disabled={isDisabled()}
          />
        )}
        {formState.formError.length < 2 ? (
          formState.formError.map((err: any) => {
            return <FormErr>{err}</FormErr>;
          })
        ) : (
          <FormErr>{formState.formError}</FormErr>
        )}
      </Form>
      <Modal
        heading=""
        modal={formState.createSuccess}
        toggleModal={CloseSuccessMessage}
        body={
          <>
            <Success
              messageOne=""
              messageTwo="Successfully Joined a New Estate"
              action={CloseSuccessMessage}
              actionText="Back"
            />
          </>
        }
      />
    </div>
  );
};
export default JoinNew;
const Form = styled.form`
  width: 500px;
  position: relative;
  @media (max-width: 576px) {
    width: 100%;
    margin: auto;
  }
`;
const SectionTitle = styled.div`
  color: #686868;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.7px;
  text-align: left;
  padding-top: 20px;
  @media (max-width: 576px) {
    width: 100%;
    padding-bottom: 10px;
  }
`;
const FormGroup = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: left;
  position: relative;
  width: 500px;
  margin: auto;
  input {
    width: 100%;
    background: #fbfbfb;
    border: 1px solid #c5cae4;
    border-radius: 4px;
    height: 58px;
    padding-left: 10px;
  }
  label {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #686868;
  }
  @media (max-width: 1366px) {
    input {
      height: 48px;
    }
  }
  @media (max-width: 576px) {
    width: 300px;
    padding-top: 10px;
    padding-bottom: 10px;
    input {
      width: 100%;
      padding: 0px !important;
    }
  }
`;
const SearchForm = styled.div`
  position: absolute;
  margin-top: -10px;
  width: 497px;
  z-index: 5;
  background: white;

  border-radius: 5px;
  border: 1px solid #c5cae4;
`;
const SearchModal = styled.div`
  div {
    padding-left: 10px;
    paddding-right: 10px;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    :hover {
      background-color: #ccc;
      cursor: pointer;
    }
  }
`;
const CloseForm = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-left: auto;
  margin-right: -30px;
  color: black;
  svg {
    font-size: 24px;
  }
  :hover {
    color: var(--mainBlue);
    cursor: pointer;
  }
  @media (max-width: 576px) {
    position: absolute;
    right: 30px;
    top: -40px;
  }
`;
const CreateButton = styled.input`
  background-color: var(--mainBlue);
  width: 300px;
  height: 42px;
  margin: auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  outline: 0;
  border-color: transparent;
  :hover {
    cursor: pointer;
  }
  :disabled {
    background-color: #ccc;
    color: white;
  }
  :disabled:hover {
    cursor: not-allowed;
  }
`;
const LoadingButton = styled.div`
  background-color: var(--mainBlue);
  width: 300px;
  height: 42px;
  margin: auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  outline: 0;
  border-color: transparent;
  img {
    width: 18px;
  }
`;
const FormErr = styled.div`
  font-size: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  color: tomato;
  text-align: right;
`;
