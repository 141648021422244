import React, { createContext, useEffect, useState } from "react";
import { httprequeset } from "../Components/Constants/Constant";
// import {useNavigate} from 'react-router-dom';
const AppContext = createContext();

const AppProvider = ({ children }) => {
  // const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [estateMembers, setEstateMembers] = useState();
  const [estateID, setEstateID] = useState(0);
  const [estateVisits,setEstateVisits]=useState([]);
  // const handleAllUser = async () => {
  //   try {
  //     const response = await httprequeset.get("/user");
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const handleUserProfile = async () => {
    try {
      const response = await httprequeset.get("/auth/profile");
      setUserData(response?.data);
      setUserRole(response?.data?.role);
      setEstateID(response?.data?.memberships[0]);
      if (response?.data?.status === 'provisioned'){
        localStorage.setItem("activeStatus",'provisioned')
      }else if(response?.data?.status === 'active'){
        localStorage.setItem("activeStatus",'active')
      }else{
        localStorage.setItem("activeStatus",'active')
      }
      if (response?.data?.estates?.length<1 && response.data?.memberships?.length < 1){
        localStorage.setItem("estate",'emptyEstate')
      }else{
        localStorage.setItem("estate",'nonEmptyEstate')
      }
      if (response?.data?.memberships[0].estateId) {
        const id = response?.data?.memberships[0].estateId;
        const responseMembers = await httprequeset.get(
          `/estates/${id}/members`
        );
        const estateVisits = await httprequeset.get(
          `/estates/${id}/visits`
        );
        // console.log(estateVisits)
        // console.log(responseMembers);
        setEstateMembers(responseMembers?.data?.members);
        setEstateVisits(estateVisits?.data)
      }
      
      
    } catch (err) {
      // if (err?.response.status===401){
      //     return navigate('/')
      // }
      // console.log(err)
    }
  };
 
  // const handleUserData = async () => {
  //   try {
  //     const response = await httprequeset.get("/user");
  //     console.log(response)
  //     // setUserData(response?.data);
  //     // setUserRole(response?.data?.role);
  //     // if(response?.data?.estates[0].id){
  //     //   const id = response?.data?.estates[0].id;
  //     //   const responseMembers = await httprequeset.get(`/estates/${id}/members`);
  //     //   setEstateMembers(responseMembers?.data?.members)
  //     // }
  //   } catch (err) {
  //     if (err?.response.status===401){
  //         return navigate('/')
  //     }
  //     // console.log(err)
  //   }
  // };

  useEffect(() => {
    // handleAllUser();
    handleUserProfile();
    // handleUserData()
  }, []);
  return (
    <AppContext.Provider
      value={{
        userData,
        setUserData,
        userRole,
        handleUserProfile,
        estateMembers,
        estateID,
        estateVisits
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
const AppConsumer = AppContext.Consumer;
export { AppContext, AppProvider, AppConsumer };
