import React, { useContext,useEffect } from "react";
import Main from "../Constants/Main";
import ResidentMessaging from "./ResidentMessaging";
import { VisitorAccessButton, NewChatButton } from "../Constants/Snippets";
import { AppContext } from "../../Context/index";
import { httprequeset } from "../Constants/Constant";
import {useNavigate} from 'react-router-dom';
// interface Props {
//   navToggle: boolean;
// }

const Index: React.FC = (props) => {
  const { userRole } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(()=>{
    httprequeset.get(`/auth/profile`)
      .then((res) => {  
      })
    .catch((err) => {
         if(err.response.status===401){
          navigate('/')
         }
       });
  },[navigate])
  return (
    <>
      <Main
        tabName="Messaging"
        rightSubHeader={
          <>
            {userRole === "resident" && (
              <>
                <NewChatButton /> <VisitorAccessButton />
              </>
            )}
          </>
        }
        mainBody={
          <>
            <ResidentMessaging />
          </>
        }
      />
    </>
  );
};

export default Index;
