import React, { useState, useContext } from "react";
import styled from "styled-components";
// import from Modal from '../'
import Modal from "./Modal";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import Success from "./Success";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import PersonOutlineSharpIcon from "@mui/icons-material/PersonOutlineSharp";
import Alert from "./Alert";
import IconImage from "../../assets/estate.png";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { AppContext } from "../../Context";
import { httprequeset } from "./Constant";
import loader from "../../assets/loader.gif";
import { useNavigate } from "react-router-dom";

const AdminAddResident = () => {
  const { estateID, handleUserProfile } = useContext(AppContext);
  const navigate = useNavigate();
  const [addResidentOption, setResidentOption] = useState(false);
  const [toggleNewResident, setNewResident] = useState(false);
  const [toggleBulkUpload, setToggleBulkUpload] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const toggleAddOptions = () => {
    setResidentOption(!addResidentOption);
    setTimeout(() => {
      closeToggleAddOptions();
    }, 3000);
  };
  const closeToggleAddOptions = () => {
    setResidentOption(false);
  };

  const CloseSuccessModal = () => {
    setSuccessMessage(false);
  };
  const [residentFormState, setResidentFormState] = useState({
    firstName: "",
    lastName: "",
    address: "",
    email: "",
    phoneNumber: "",
    role: "resident",
    isLoading: false,
    formError: [],
  });
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setResidentFormState({
      ...residentFormState,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };
  const handleAreaChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    setResidentFormState({
      ...residentFormState,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };
  // const CloseAddToggle=()=>{
  //   setResidentOption(false)
  // }
  // const DoNothing =()=>{

  // }
  const handleNewResidentToggle = () => {
    setNewResident(!toggleNewResident);
    setResidentFormState({
      ...residentFormState,
      firstName: "",
      address: "",
      email: "",
      lastName: "",
      isLoading: false,
    });
  };
  const handleBulkUpload = () => {
    setToggleBulkUpload(!toggleBulkUpload);
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setResidentFormState({ ...residentFormState, isLoading: true });
    const payload = { ...residentFormState };
    //   console.log(payload)
    httprequeset
      .post(`/estates/${estateID?.estateId}/members`, payload)
      .then((res) => {
        if (res.status === 201) {
          setSuccessMessage(!successMessage);
          handleUserProfile();
          setResidentFormState({
            ...residentFormState,
            firstName: "",
            address: "",
            email: "",
            lastName: "",
            isLoading: false,
          });

          setNewResident(!toggleNewResident);
          //        setAccessCode(res?.data?.accessCode)
          //         handleAccess()
        }
      })
      .catch((err) => {
        // console.log(err);
        setResidentFormState({
          ...residentFormState,
          isLoading: false,
          formError: Object.values(err?.response?.data?.message),
        });
        if (err.response.status === 401) {
          navigate("/");
        }
        //     setFormState({
        //      ...formState,
        //     isLoading: false,
        //    formError: Object.values(err?.response?.data?.message),
        //  });
      });
  };
  const { firstName, lastName, email, phoneNumber } = residentFormState;
  const isDisabled = () => {
    if (firstName && lastName && email && phoneNumber) {
      return false;
    }
    return true;
  };
  return (
    <>
      <AdminAddButton onClick={toggleAddOptions}>
        Add New Resident
      </AdminAddButton>
      {addResidentOption ? (
        <DropModal>
          <OptionCTA onClick={handleNewResidentToggle}>
            <IconBG>
              <AddBoxOutlinedIcon />
            </IconBG>
            <RightModalContent>
              <h2>Add Resident</h2>
              <p>Add a single member at a time</p>
            </RightModalContent>
          </OptionCTA>
          <OptionCTA onClick={handleBulkUpload}>
            <IconBG>
              <AttachmentOutlinedIcon />
            </IconBG>
            <RightModalContent>
              <h2>Make Bulk Upload</h2>
              <p>Add a lot of estate members using a CSV or XLS file</p>
            </RightModalContent>
          </OptionCTA>
        </DropModal>
      ) : null}
      <Modal
        modal={toggleNewResident}
        heading=""
        body={
          <>
            <ModalHeader>
              <ChevronLeftOutlinedIcon
                onClick={() => {
                  handleNewResidentToggle();
                  toggleAddOptions();
                }}
              />
              <h5>Add Resident </h5>
            </ModalHeader>
            <FormHeadeInfo>Estate Info</FormHeadeInfo>
            <ModalForm onSubmit={handleSubmit}>
              <FormGroup>
                <label>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  value={residentFormState.firstName}
                />
              </FormGroup>
              <FormGroup>
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  value={residentFormState.lastName}
                />
              </FormGroup>
              <FormGroup>
                <label>Email Address</label>
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={residentFormState.email}
                />
              </FormGroup>
              <FormGroup>
                <label>Contact Phone Number</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  onChange={handleChange}
                  value={residentFormState.phoneNumber}
                />
              </FormGroup>
              <FormGroup>
                <label>Address</label>
                <textarea
                  name="address"
                  onChange={handleAreaChange}
                  value={residentFormState.address}
                ></textarea>
              </FormGroup>
              <ButtonArea>
                {residentFormState.isLoading ? (
                  <div>
                    <img src={loader} alt="Loader" />
                  </div>
                ) : (
                  <input type="submit" disabled={isDisabled()} value="Done" />
                )}
              </ButtonArea>
              {residentFormState.formError.length < 2 ? (
                residentFormState.formError.map((err: any) => {
                  return <FormErr>{err}</FormErr>;
                })
              ) : (
                <FormErr>{residentFormState.formError}</FormErr>
              )}
            </ModalForm>
          </>
        }
        toggleModal={handleNewResidentToggle}
      />
      <Modal
        heading=""
        modal={toggleBulkUpload}
        toggleModal={handleBulkUpload}
        body={
          <>
            <UploadContainer>
              <ModalHeader>
                <ChevronLeftOutlinedIcon
                  onClick={() => {
                    handleBulkUpload();
                    toggleAddOptions();
                  }}
                />

                <h5>Bulk Upload</h5>
              </ModalHeader>
              <UploadWrapper>
                <Upload>
                  <label>
                    <UploadButton type="file" />
                  </label>
                </Upload>
              </UploadWrapper>
            </UploadContainer>
          </>
        }
      />
      <Modal
        heading=""
        modal={successMessage}
        toggleModal={CloseSuccessModal}
        body={
          <>
            <Success
              messageOne=""
              messageTwo="Successful"
              action={CloseSuccessModal}
              actionText="Back"
            />
          </>
        }
      />
    </>
  );
};

const AddMemberButton = () => {
  const [memberForm, setMemberForm] = useState(false);
  const handleNewMember = () => {
    setMemberForm(!memberForm);
  };
  const handleMemberFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <AddButton onClick={handleNewMember}>Add New Member</AddButton>
      <Modal
        heading="Add New Member"
        modal={memberForm}
        toggleModal={handleNewMember}
        body={
          <>
            <AccessForm onSubmit={handleMemberFormSubmit}>
              <MemberFormGroup>
                <label>Name</label>
                <input type="text" name="name" />
              </MemberFormGroup>
              <MemberFormGroup>
                <label>Email</label>
                <input type="email" name="email" />
              </MemberFormGroup>
              <MemberFormGroup>
                <label>Phone Number</label>
                <input type="tel" name="phoneNumber" />
              </MemberFormGroup>
              <KeyButton type="submit">Done</KeyButton>
            </AccessForm>
          </>
        }
      />
    </>
  );
};

const VisitorAccessButton = () => {
  const { estateID, handleUserProfile } = useContext(AppContext);
  const [giveAccess, setGiveAccess] = useState(false);
  const [accessForm, setAccessForm] = useState(false);
  const [copyAccessCode, setCopyAccessCode] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const navigate = useNavigate();
  const [accessFormState, setAccessFormState] = useState({
    visitorFullName: "",
    visitorEmail: "",
    visitorPhoneNumber: "",
    visitorAddress: "",
    purpose: "",
    visitStartDate: "",
    visitEndDate: "",
    selectedDate: "",
    estateId: estateID?.estateId,
    isLoading: false,
    formError: [],
  });
  const handleAccess = () => {
    setGiveAccess(!giveAccess);
  };
  const handleAccessForm = () => {
    setAccessForm(!accessForm);
  };

  const closeAlertModal = () => {
    setCopyAccessCode(false);
  };
  const ClearForm = () => {
    const form = {
      visitorFullName: "",
      visitorEmail: "",
      visitorPhoneNumber: "",
      visitorAddress: "",
      purpose: "",
      visitStartDate: "",
      visitEndDate: "",
      selectedDate: "",
      isLoading: false,
    };
    setAccessFormState({ ...accessFormState, ...form });
  };
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setAccessFormState({
      ...accessFormState,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };
  const handleKeyGen = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setAccessFormState({ ...accessFormState, isLoading: true });
    const payload = { ...accessFormState };
    httprequeset
      .post(`/visits`, payload)
      .then((res) => {
        if (res.status === 201) {
          setAccessFormState({ ...accessFormState, isLoading: false });
          setAccessCode(res?.data?.accessCode);
          ClearForm();
          handleAccess();
          handleUserProfile();
          handleAccessForm();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/");
        }
        setAccessFormState({
          ...accessFormState,
          isLoading: false,
          formError: Object.values(err?.response?.data?.message),
        });
      });
  };
  const {
    visitorFullName,
    visitorEmail,
    visitorAddress,
    visitorPhoneNumber,
    purpose,
    visitStartDate,
    visitEndDate,
  } = accessFormState;
  const isDisabled = () => {
    if (
      visitorFullName &&
      visitorEmail &&
      visitorPhoneNumber &&
      visitorAddress &&
      purpose &&
      visitStartDate &&
      visitEndDate
    ) {
      return false;
    }
    return true;
  };
  return (
    <>
      <AccessButton onClick={handleAccessForm}>
        Give Visitor Access
      </AccessButton>
      <Modal
        heading=""
        modal={giveAccess}
        toggleModal={handleAccess}
        body={
          <>
            <ModalHeader>
              <ChevronLeftOutlinedIcon
                onClick={() => {
                  handleAccessForm();
                  handleAccess();
                }}
              />
              <h5>New Access Key</h5>
            </ModalHeader>
            <AccessWrapper>
              <p>Code</p>
              <AccessContent>{accessCode && accessCode}</AccessContent>
              <h6>Code Valid within visit hours</h6>
              <CopyToClipboard
                text={accessCode}
                onCopy={() => setCopyAccessCode(true)}
              >
                <CopyButton>
                  Copy
                  <ContentCopyOutlinedIcon />
                </CopyButton>
              </CopyToClipboard>
              {copyAccessCode ? (
                <Alert
                  open={copyAccessCode}
                  alertSeverity="success"
                  alertTitle="Copied"
                  closeModal={closeAlertModal}
                />
              ) : null}
            </AccessWrapper>
          </>
        }
      />
      <Modal
        heading=""
        modal={accessForm}
        toggleModal={handleAccessForm}
        body={
          <>
            <ModalHeader>
              <ChevronLeftOutlinedIcon
                onClick={() => {
                  handleAccessForm();
                  handleAccess();
                }}
              />
              <h5>Give Visitor Access</h5>
            </ModalHeader>
            <AccessForm onSubmit={handleKeyGen}>
              <RowFormGroup>
                <MemberFormGroup>
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="visitorFullName"
                    value={visitorFullName}
                    onChange={handleChange}
                  />
                </MemberFormGroup>
                <MemberFormGroup>
                  <label>Phone Number</label>
                  <input
                    type="tel"
                    placeholder="+234********"
                    name="visitorPhoneNumber"
                    value={visitorPhoneNumber}
                    onChange={handleChange}
                  />
                </MemberFormGroup>
              </RowFormGroup>

              <MemberFormGroup>
                <label>Email</label>
                <input
                  type="email"
                  name="visitorEmail"
                  value={visitorEmail}
                  onChange={handleChange}
                />
              </MemberFormGroup>

              <MemberFormGroup>
                <label>Address</label>
                <input
                  type="address"
                  name="visitorAddress"
                  value={visitorAddress}
                  onChange={handleChange}
                />
              </MemberFormGroup>

              <MemberFormGroup>
                <label>Purpose</label>
                <input
                  type="text"
                  name="purpose"
                  value={purpose}
                  onChange={handleChange}
                />
              </MemberFormGroup>
              <RowFormGroup>
                <MemberFormGroup>
                  <label> Start Date </label>
                  <input
                    type="datetime-local"
                    name="visitStartDate"
                    value={visitStartDate}
                    onChange={handleChange}
                  />
                </MemberFormGroup>
                <MemberFormGroup>
                  <label> End Date </label>
                  <input
                    type="datetime-local"
                    name="visitEndDate"
                    value={visitEndDate}
                    onChange={handleChange}
                  />
                </MemberFormGroup>
              </RowFormGroup>

              <KeyButton type="submit" disabled={isDisabled()}>
                {accessFormState.isLoading ? (
                  <img src={loader} alt="IQ Loader" />
                ) : (
                  "Generate Access Key"
                )}{" "}
              </KeyButton>
              {accessFormState.formError.length < 2 ? (
                accessFormState.formError.map((err: any) => {
                  return <FormErr>{err}</FormErr>;
                })
              ) : (
                <FormErr>{accessFormState.formError}</FormErr>
              )}
            </AccessForm>
          </>
        }
      />
    </>
  );
};
const VerifyAccessButton = () => {
  const { estateID, handleUserProfile } = useContext(AppContext);
  const [giveAccess, setGiveAccess] = useState(false);
  const [copyAccessCode, setCopyAccessCode] = useState(false);
  const [residentDetails, setResidentDetails] = useState(false);
  const [residentAccess, setResidentAccess] = useState(false);
  const [codeDetails, setCodeDetails] = useState<any>([]);
  const [accessFormState, setAccessFormState] = useState({
    accessCode: "",
    isLoading: false,
    formError: [],
  });
  const navigate = useNavigate();
  const handleAccess = () => {
    setGiveAccess(!giveAccess);
  };

  const handleResidentDetails = () => {
    setResidentDetails(!residentDetails);
  };
  const handleResidentAccess = () => {
    setResidentAccess(!residentAccess);
  };
  const handleValidateCode = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setAccessFormState({ ...accessFormState, isLoading: true });
    httprequeset
      .get(`/visits/${accessFormState.accessCode}`)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          setAccessFormState({
            ...accessFormState,
            isLoading: false,
            accessCode: "",
          });
          setCodeDetails(res?.data);
          handleResidentAccess();
          handleResidentDetails();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/");
        }
        setAccessFormState({
          ...accessFormState,
          isLoading: false,
          formError: Object.values(err?.response?.data?.message),
        });
      });
  };
  const handleVisitStatus = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // console.log(codeDetails);
    const formData = {
      visitorFullName: codeDetails?.visitor?.fullName,
      visitorEmail: codeDetails?.visitor?.email,
      visitorPhoneNumber: codeDetails?.visitor?.phoneNumber,
      visitorAddress: codeDetails?.visitor?.address,
      purpose: codeDetails?.purpose,
      visitStartDate: codeDetails?.startDate,
      visitEndDate: codeDetails?.endDate,
      status:
        codeDetails?.status === "pending"
          ? "accepted"
          : codeDetails?.status === "accepted"
          ? "rejected"
          : codeDetails?.status === "rejected"
          ? "rejected"
          : "rejected",
      estateId: estateID?.estateId,
    };
    setAccessFormState({ ...accessFormState, isLoading: true });

    httprequeset
      .patch(`/visits/${codeDetails?.accessCode}`, formData)
      .then((res) => {
        if (res.status === 200) {
          setAccessFormState({ ...accessFormState, isLoading: false });
          handleUserProfile();
          navigate("/dashboard");
          handleResidentDetails();
        }
      })
      .catch((err) => {
        // console.log(err);
        if (err.response.status === 401) {
          navigate("/");
        }
        setAccessFormState({
          ...accessFormState,
          isLoading: false,
          formError: Object.values(err?.response?.data?.message),
        });
      });
  };

  const DisableSignIn = () => {
    if (codeDetails?.status === "rejected") {
      return true;
    } else {
      return false;
    }
  };
  const closeAlertModal = () => {
    setCopyAccessCode(false);
  };
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setAccessFormState({
      ...accessFormState,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const { accessCode } = accessFormState;
  const isDisabled = () => {
    if (accessCode) {
      return false;
    }
    return true;
  };
  // "visitorFullName": "string",
  // "visitorEmail": "string",
  // "visitorPhoneNumber": "string",
  // "visitorAddress": "string",
  // "purpose": "string",
  // "visitStartDate": "2022-06-17T01:28:48.538Z",
  // "visitEndDate": "2022-06-17T01:28:48.538Z",

  // "estateId": 0
  return (
    <>
      <AccessButton onClick={handleResidentAccess}>
        Verify Access Code
      </AccessButton>
      <Modal
        heading=""
        modal={giveAccess}
        toggleModal={handleAccess}
        body={
          <>
            <ModalHeader>
              <ChevronLeftOutlinedIcon
                onClick={() => {
                  handleAccess();
                }}
              />
              <h5>New Access Key</h5>
            </ModalHeader>
            <AccessWrapper>
              <p>Code</p>
              <AccessContent>{accessCode && accessCode}</AccessContent>
              <h6>Code Valid within visit hours</h6>
              <CopyToClipboard
                text="PQY-3091"
                onCopy={() => setCopyAccessCode(true)}
              >
                <CopyButton>
                  Copy
                  <ContentCopyOutlinedIcon />
                </CopyButton>
              </CopyToClipboard>
              {copyAccessCode ? (
                <Alert
                  open={copyAccessCode}
                  alertSeverity="success"
                  alertTitle="Copied"
                  closeModal={closeAlertModal}
                />
              ) : null}
            </AccessWrapper>
          </>
        }
      />
      <Modal
        heading=""
        modal={residentDetails}
        toggleModal={handleResidentDetails}
        body={
          <>
            <ModalHeader>
              <ChevronLeftOutlinedIcon
                onClick={() => {
                  handleResidentDetails();
                }}
              />
              <h5>Visitor Info</h5>
            </ModalHeader>
            <AccessForm onSubmit={handleVisitStatus}>
              <AccessWrapper>
                <VisitorDetails>
                  <div>
                    <p>Host Name</p>
                    <h3>
                      {" "}
                      {codeDetails?.host?.firstName +
                        " " +
                        codeDetails?.host?.lastName}
                    </h3>
                    <p>Guest's Name</p>
                    <h3>{codeDetails?.visitor?.fullName}</h3>
                    <p>Visitor's Phone Number</p>
                    <h3>{codeDetails?.visitor?.phoneNumber}</h3>
                  </div>
                  <div>
                    <p>Host Address</p>
                    <h3>{codeDetails?.host?.address}</h3>
                    <p>Visitor's Email Address</p>
                    <h3>{codeDetails?.visitor?.email}</h3>
                    <p>Visitor's Purpose</p>
                    <h3>{codeDetails?.purpose}</h3>
                  </div>
                </VisitorDetails>

                <AccessCodeDetails>{codeDetails?.accessCode}</AccessCodeDetails>
                <SignedIn>
                  {codeDetails?.status === "pending"
                    ? "Pending"
                    : codeDetails?.status === "accepted"
                    ? "Signed In"
                    : "Inactive"}
                </SignedIn>
                <KeyButton
                  type="submit"
                  disabled={DisableSignIn()}
                  style={{
                    backgroundColor:
                      codeDetails?.status === "pending"
                        ? "#2D4BF3"
                        : codeDetails?.status === "accepted"
                        ? "green"
                        : codeDetails?.status === "rejected"
                        ? "tomato"
                        : "#9D3CCB",
                  }}
                >
                  {accessFormState?.isLoading ? (
                    <img src={loader} alt="IQ Loader" />
                  ) : codeDetails?.status === "pending" ? (
                    "Log in"
                  ) : codeDetails?.status === "accepted" ? (
                    "Log Out"
                  ) : (
                    "Not Active"
                  )}{" "}
                </KeyButton>
              </AccessWrapper>
            </AccessForm>
          </>
        }
      />
      <Modal
        heading=""
        modal={residentAccess}
        toggleModal={() => {
          handleResidentAccess();
        }}
        body={
          <>
            <ModalHeader>
              <ChevronLeftOutlinedIcon
                onClick={() => {
                  handleResidentAccess();
                }}
              />
              <h5>Input Access Code</h5>
            </ModalHeader>
            <AccessWrapper>
              <p>Code</p>
              <StyledForm onSubmit={handleValidateCode}>
                <InputCode
                  type="text"
                  onChange={handleChange}
                  name="accessCode"
                />
                {/* <h6>Code Valid within 72 Hours</h6> */}
                <KeyButton type="submit" disabled={isDisabled()}>
                  {accessFormState.isLoading ? (
                    <img src={loader} alt="IQ Loader" />
                  ) : (
                    "Verify"
                  )}{" "}
                </KeyButton>
              </StyledForm>
            </AccessWrapper>
          </>
        }
      />
    </>
  );
};
const AddPersonnelButton = () => {
  const { estateID, handleUserProfile } = useContext(AppContext);
  const navigate = useNavigate();
  const [personnelForm, setPersonnelForm] = useState(false);
  const [personnelFormState, setPersonnelFormState] = useState({
    firstName: "",
    lastName: "",
    address: "none",
    email: "",
    phoneNumber: "",
    role: "security",
    isLoading: false,
    formError: [],
    fileUrl: "",
  });
  const [doc, setDoc] = useState<any>();
  const [successMessage, setSuccessMessage] = useState(false);
  const handlePersonnelForm = () => {
    setPersonnelForm(!personnelForm);
  };
  const clearForm = () => {
    setPersonnelFormState({
      ...personnelFormState,
      firstName: "",
      lastName: "",
      address: "none",
      email: "",
      phoneNumber: "",
      role: "security",
      isLoading: false,
      formError: [],
    });
  };
  // const closeAlertModal = () => {
  //   setCopyAccessCode(false);
  // };
  const CloseSuccessModal = () => {
    setSuccessMessage(false);
  };
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setPersonnelFormState({
      ...personnelFormState,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };
  const handleSelectChange = (event: React.FormEvent<HTMLSelectElement>) => {
    setPersonnelFormState({
      ...personnelFormState,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };
  const onChangeFile = (e: React.FormEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];
    setDoc(file);
    // setPersonnelFormState({
    //   ...personnelFormState,doc:file
    // })
    // const formData = new FormData();

    // formData.append("file", file);
    // constants.httprequeset
    //   .post(`base/upload`, formData)
    //   .then((res) => {
    //     setFileURL(res.data.data);
    //   })
    //   .catch((error) => {});
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setPersonnelFormState({ ...personnelFormState, isLoading: true });
    const payload = { ...personnelFormState };
    // console.log(payload);
    httprequeset
      .post(`/estates/${estateID?.estateId}/members`, payload)
      .then((res) => {
        if (res.status === 201) {
          setSuccessMessage(!successMessage);
          handleUserProfile();
          clearForm();
          setPersonnelForm(!personnelForm);
        }
      })
      .catch((err) => {
        setPersonnelFormState({
          ...personnelFormState,
          isLoading: false,
          formError: Object.values(err?.response?.data?.message),
        });
        if (err.response.status === 401) {
          navigate("/");
        }
      });
  };
  const { firstName, lastName, email, phoneNumber, role } = personnelFormState;
  const isDisabled = () => {
    if (firstName && lastName && email && phoneNumber && role) {
      return false;
    }
    return true;
  };
  // "visitorFullName": "string",
  // "visitorEmail": "string",
  // "visitorPhoneNumber": "string",
  // "visitorAddress": "string",
  // "purpose": "string",
  // "visitStartDate": "2022-06-17T01:28:48.538Z",
  // "visitEndDate": "2022-06-17T01:28:48.538Z",

  // "estateId": 0
  return (
    <>
      <AccessButton onClick={handlePersonnelForm}>
        Add New Personnel
      </AccessButton>

      <Modal
        heading=""
        modal={personnelForm}
        toggleModal={handlePersonnelForm}
        body={
          <>
            <ModalHeader>
              <ChevronLeftOutlinedIcon onClick={handlePersonnelForm} />

              <h5>Add Personnel </h5>
            </ModalHeader>
            <FormHeadeInfo>Personnel Info</FormHeadeInfo>
            <AccessForm onSubmit={handleSubmit}>
              <ImageUploadSection>
                <ImageDisplay>
                  <PersonOutlineSharpIcon />
                </ImageDisplay>
                <UploadDP>
                  {doc?.name ? doc.name : "Upload Image"}

                  <input type="file" onChange={onChangeFile} />
                </UploadDP>
              </ImageUploadSection>
              <RowFormGroup>
                <MemberFormGroup>
                  <label>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={personnelFormState.firstName}
                    onChange={handleChange}
                  />
                </MemberFormGroup>
                <MemberFormGroup>
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={personnelFormState.lastName}
                    onChange={handleChange}
                  />
                </MemberFormGroup>
                {/* <MemberFormGroup>
                  <label>Position</label>
                  <select>

                  </select>
                  <input
                    type="text"
                    name="visitorPhoneNumber"
                    value={visitorPhoneNumber}
                    onChange={handleChange}
                  />
                </MemberFormGroup> */}
              </RowFormGroup>
              <FormGroup>
                <MemberFormGroup>
                  <label>Select Personnel</label>
                  <select name="role" onChange={handleSelectChange}>
                    <option value="security">Security</option>
                    <option value="estate_admin">Estate Admin</option>
                  </select>
                </MemberFormGroup>
              </FormGroup>
              <RowFormGroup>
                <MemberFormGroup>
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={personnelFormState.email}
                    onChange={handleChange}
                  />
                </MemberFormGroup>

                <MemberFormGroup>
                  <label>Phone Number</label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    value={personnelFormState.phoneNumber}
                    onChange={handleChange}
                  />
                </MemberFormGroup>
              </RowFormGroup>

              <KeyButton type="submit" disabled={isDisabled()}>
                {personnelFormState.isLoading ? (
                  <img src={loader} alt="IQ Loader" />
                ) : (
                  "Add Personnel"
                )}{" "}
              </KeyButton>
              {personnelFormState.formError.length < 2 ? (
                personnelFormState.formError.map((err: any) => {
                  return <FormErr>{err}</FormErr>;
                })
              ) : (
                <FormErr>{personnelFormState.formError}</FormErr>
              )}
            </AccessForm>
          </>
        }
      />
      <Modal
        heading=""
        modal={successMessage}
        toggleModal={CloseSuccessModal}
        body={
          <>
            <Success
              messageOne=""
              messageTwo="Personnel Successfully Added"
              action={CloseSuccessModal}
              actionText="Back"
            />
          </>
        }
      />
    </>
  );
};

// "visitStartDate": "2022-06-17T08:15:57.718Z",
const AddComplaintsButton = () => {
  const [giveComplaint, setGiveComplaint] = useState(false);
  const handleComplaint = () => {
    setGiveComplaint(!giveComplaint);
  };
  const handleComplaintFormSubmit = (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    handleComplaint();
  };
  return (
    <>
      <ComplaintButton onClick={handleComplaint}>
        Add New Complaint
      </ComplaintButton>
      <Modal
        heading="New Complaint"
        modal={giveComplaint}
        toggleModal={handleComplaint}
        body={
          <>
            <ModalHeader>
              <ChevronLeftOutlinedIcon
                onClick={() => {
                  handleComplaint();
                }}
              />
              <h5>New Complaint</h5>
            </ModalHeader>
            <AccessForm onSubmit={handleComplaintFormSubmit}>
              <FormGroup>
                <label>Title</label>
                <input type="text" name="title" />
              </FormGroup>
              <FormGroup>
                <label>Message</label>
                <textarea name="message" />
              </FormGroup>
              <KeyButton type="submit">Send</KeyButton>
            </AccessForm>
          </>
        }
      />
    </>
  );
};

const NewChatButton = () => {
  const [startChat, setStartChat] = useState(false);
  const handleStartChat = () => {
    setStartChat(!startChat);
  };
  const handleStartChatForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // handleAccess();
  };
  return (
    <>
      <ChatButton onClick={handleStartChat}>Start New Chat</ChatButton>
      <Modal
        heading=""
        modal={startChat}
        toggleModal={handleStartChat}
        body={
          <>
            <ModalHeader>
              <ChevronLeftOutlinedIcon
                onClick={() => {
                  handleStartChat();
                }}
              />
              <h5>Start New Chat</h5>
            </ModalHeader>
            <AccessForm onSubmit={handleStartChatForm}>
              <SearchGroup>
                <label>Estate Name</label>
                <input
                  type="search"
                  name="searchestate"
                  placeholder="House Number or person name"
                />
                <SearchOutlinedIcon />
              </SearchGroup>
              <ContactList>
                {Array.from(Array(15)).map(() => (
                  <Contact>
                    <IconDP>
                      <img src={IconImage} alt="Estate IQ" />
                    </IconDP>
                    <ContactDetails>
                      <h3>Oladoye Tolulope</h3>
                      <p>Golden Gate Estate</p>
                    </ContactDetails>
                    <EstateID>EST024</EstateID>
                    <Position>Admin</Position>
                  </Contact>
                ))}
              </ContactList>
            </AccessForm>
          </>
        }
      />
    </>
  );
};

const FilterByDate = () => {
  return (
    <>
      <SortInput type="date" placeholder="Date" />
    </>
  );
};

const MemberRightHeader = () => {
  const [giveAccess, setGiveAccess] = useState(false);
  const [accessForm, setAccessForm] = useState(false);
  const [copyAccessCode, setCopyAccessCode] = useState(false);
  const [memberForm, setMemberForm] = useState(false);
  const handleAccess = () => {
    setGiveAccess(!giveAccess);
  };
  const handleAccessForm = () => {
    setAccessForm(!accessForm);
  };
  const handleAccessFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleAccess();
  };
  const handleMemberFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };
  const handleNewMember = () => {
    setMemberForm(!memberForm);
  };
  const closeAlertModal = () => {
    setCopyAccessCode(false);
  };
  return (
    <>
      <AddButton onClick={handleNewMember}>Add New Member</AddButton>
      <AccessButton onClick={handleAccessForm}>
        Give Visitor Access
      </AccessButton>
      <Modal
        heading="New Access Key"
        modal={giveAccess}
        toggleModal={handleAccess}
        body={
          <>
            <AccessWrapper>
              <p>Code</p>
              <AccessContent>PQY-3091</AccessContent>
              <h6>Code Valid within the visit </h6>
              <CopyToClipboard
                text="PQY-3091"
                onCopy={() => setCopyAccessCode(true)}
              >
                <CopyButton>
                  Copy
                  <ContentCopyOutlinedIcon />
                </CopyButton>
              </CopyToClipboard>
              {copyAccessCode ? (
                <Alert
                  open={copyAccessCode}
                  alertSeverity="success"
                  alertTitle="Copied"
                  closeModal={closeAlertModal}
                />
              ) : null}
            </AccessWrapper>
          </>
        }
      />
      <Modal
        heading="Give Visitor Access"
        modal={accessForm}
        toggleModal={handleAccessForm}
        body={
          <>
            <AccessForm onSubmit={handleAccessFormSubmit}>
              <MemberFormGroup>
                <label>Name</label>
                <input type="text" name="name" />
              </MemberFormGroup>
              <MemberFormGroup>
                <label>Email</label>
                <input type="email" name="email" />
              </MemberFormGroup>
              <MemberFormGroup>
                <label>Phone Number</label>
                <input type="tel" name="phoneNumber" />
              </MemberFormGroup>
              <KeyButton type="submit">Generate Access Key</KeyButton>
            </AccessForm>
          </>
        }
      />
      <Modal
        heading="Add New Member"
        modal={memberForm}
        toggleModal={handleNewMember}
        body={
          <>
            <AccessForm onSubmit={handleMemberFormSubmit}>
              <MemberFormGroup>
                <label>Name</label>
                <input type="text" name="name" />
              </MemberFormGroup>
              <MemberFormGroup>
                <label>Email</label>
                <input type="email" name="email" />
              </MemberFormGroup>
              <MemberFormGroup>
                <label>Phone Number</label>
                <input type="tel" name="phoneNumber" />
              </MemberFormGroup>
              <KeyButton type="submit">Done</KeyButton>
            </AccessForm>
          </>
        }
      />
    </>
  );
};

// const Head = styled.div`
//   select {
//     width: 115px;
//     height: 40px;
//     padding-left: 10px;
//     padding-right: 10px;
//     font-size: 12px;
//     background-color: #f4f6fe;
//     border-radius: 5px;
//     border-color: transparent;
//     outline: 0;
//   }
// `;

const AdminAddButton = styled.button`
  width: 200px;
  height: 50px;
  color: var(--mainBlue);
  background-color: var(--mainBlue);
  outline: 0;
  border-color: transparent;
  margin-right: 15px;
  font-size: 12px;
  border-radius: 5px;
  color: white;
  z-index: 6;
  :hover {
    background-color: white;
    color: var(--mainBlue);
    border: 1px solid var(--mainBlue);
  }
`;
// const ResidentAddButton = styled.button`
//   width: 200px;
//   height: 50px;
//   color: var(--mainBlue);
//   background-color: var(--mainBlue);
//   outline: 0;
//   border-color: transparent;
//   margin-right: 15px;
//   font-size: 12px;
//   border-radius: 5px;
//   color: white;
//   :hover {
//     background-color: white;
//     color: var(--mainBlue);
//     border: 1px solid var(--mainBlue);
//   }
// `;
const AddButton = styled.button`
  width: 200px;
  height: 50px;
  color: var(--mainBlue);
  background-color: white;
  outline: 0;
  border: 1px solid var(--mainBlue);
  margin-right: 15px;
  font-size: 12px;
  border-radius: 5px;

  :hover {
    background-color: var(--mainBlue);
    color: white;
    border: transparent;
  }
`;
const DropModal = styled.div`
  position: absolute;
  width: 300px;
  right: 35px;
  top: 150px;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 20px;
  z-index: 5;
  @media (max-width: 1100px) {
    top: 350px;
    right: 15px;
    // display: ${(props: any) => (props.navToggle ? "block" : "none")};
  }
  @media (max-width: 576px) {
    width: 300px;
    padding: 10px;
  }
  a {
    text-decoration: none;
  }
`;
const IconBG = styled.div`
  background-color: #f2f6ff;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  svg {
    width: 18px;
    color: var(--mainBlue);
  }
`;
const OptionCTA = styled.div`
  display: flex;
  background-color: #fbfbfb;
  border-radius: 20px;
  // width:100%;
  padding: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  :hover {
    cursor: pointer;
  }
  img {
    margin-right: 10px;
  }
  @media (max-width: 576px) {
    img {
      width: 50px;
      height: 50px;
    }
  }
`;
const RightModalContent = styled.div`
  text-align: left;
  h2 {
    font-weight: 700;
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 5px;
    letter-spacing: -2px;
    color: black;
  }
  p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 10px;
    letter-spacing: -0.8px;
    color: black;
  }
`;
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-bottom: 20px;
  svg {
    margin-right: 20px;
  }
  h5 {
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #171717;
    letter-spacing: -0.8px;
  }
`;
const FormHeadeInfo = styled.div`
  background-color: #f4f6fe;
  width: 530px;
  margin: auto;
  text-align: left;
  padding-left: 20px;
  font-size: 12px;
  color: var(--mainBlue);
  font-weight: bold;
  letter-spacing: -0.5px;
  height: 58px;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  @media (max-width: 576px) {
    width: 82%;
    margin: auto;
  }
`;
const ModalForm = styled.form`
  width: 550px;
  margin: auto;
  @media (max-width: 768px) {
    margin: auto;
  }
  @media (max-width: 576px) {
    width: 85%;
    margin: auto;
  }
`;
const FormGroup = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  label {
    font-size: 12px;
    font-weight: 400;
    color: #686868;
    margin-bottom: 10px;
  }
  input {
    width: 95%;
    height: 58px;
    border: 1px solid #c5cae4;
    background-color: #fbfbfb;
    border-radius: 4px;
    padding-left: 20px;
  }
  textarea {
    width: 95%;
    padding-left: 20px;
    padding-top: 20px;
    height: 186px;
    border: 1px solid #c5cae4;
    background-color: #fbfbfb;
    border-radius: 4px;
  }
`;
const FormErr = styled.div`
  font-size: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  color: tomato;
  text-align: right;
`;
const ButtonArea = styled.div`
  width: 100%;
  input {
    width: 363px;
    height: 51px;
    margin-left: auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--mainBlue);
    color: white;
    outline: 0;
    border-radius: 4px;
    border-color: transparent;
    :disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  div {
    width: 363px;
    height: 51px;
    margin-left: auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--mainBlue);
    color: white;
    outline: 0;
    border-radius: 4px;
    border-color: transparent;
  }
  img {
    width: 18px;
  }
  @media (max-width: 426px) {
    input {
      width: 300px;
      margin: auto;
    }

    div {
      width: 300px;
      margin: auto;
    }
  }
`;
const UploadContainer = styled.div``;
const UploadWrapper = styled.div`
width:100%:
height:450px;
display:flex;
align-items:center;
justify-content:center;
`;
const Upload = styled.div`
  background-color: #fbfbfb;
  border: 1px dashed #c5cae4;
  width: 320px;
  height: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
`;
const UploadDP = styled.div`
  position: relative;
  width: 150px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c5cae4;
  border-radius: 5px;
  color: var(--mainBlue);
  margin-left: 20px;
  font-size: 12px;
  :hover {
    cursor: pointer;
  }
  input {
    position: absolute;
    width: 150px;
    opacity: 0;
    cursor: pointer;
  }
`;
const UploadButton = styled.input`
  width: 100px;
  // height:40px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  letter-spacing: -0.5px;
  background-color: var(--mainBlue);
  border-color: transparemt;
  outline: 0;
`;

// member Styling
const AccessButton = styled.button`
  background-color: var(--mainBlue);
  // border: 1px solid var(--mainBlue);
  border-color: transparent;
  outline: 0;
  width: 200px;
  height: 50px;
  margin-left: 15px;
  font-size: 12px;
  border-radius: 5px;
  color: white;
  :hover {
    background-color: transparent;
    color: var(--mainBlue);
    border: 1px solid var(--mainBlue);
  }
`;
const AccessWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  p {
    font-size: 12px;
    color: #686868;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  h6 {
    margin-top: 10px;
    font-size: 12px;
    color: var(--mainBlue);
  }
`;
const AccessContent = styled.div`
  width: 500px;
  background-color: #fbfbfb;
  border: 1px solid #c5cae4;
  border-radius: 4px;
  color: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 64px;
  font-weight: bold;
  letter-spacing: -3px;
  margin: auto;
  height: 128px;
`;
const InputCode = styled.input`
  width: 500px;
  background-color: #fbfbfb;
  border: 1px solid #c5cae4;
  border-radius: 4px;
  color: #171717;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 64px;
  font-weight: bold;
  letter-spacing: -3px;
  margin: auto;
  height: 128px;
  @media (max-width: 576px) {
    width: 80%;
    margin: auto;
  }
`;
const VisitorDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-bottom: 50px;
  p {
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 2px;
    font-color: #686868;
    letter-spacing: -0.5px;
    text-align: left;
    color: var(--mainBlue);
  }
  h3 {
    color: var(--mainBlack);
    letter-spacing: -0.7px;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 12px;
  }
  div {
    width: 45%;
  }
  @media (max-width: 576px) {
    font-size: 11px;
    h3 {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: -0.5px;
    }
  }
`;
const CopyButton = styled.button`
  background-color: var(--mainBlue);
  outline: 0;
  border-color: transparent;
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin: auto;
  font-size: 12px;
  border-radius: 5px;
  svg {
    margin-left: 10px;
    font-size: 12px;
  }
`;

const AccessForm = styled.form`
  width: 550px;
  margin: auto;
  padding-top: 40px;
  @media (max-width: 768px) {
    margin: auto;
  }
  @media (max-width: 576px) {
    width: 85%;
    margin: auto;
  }
`;
const AccessCodeDetails = styled.div`
  width: 373px;
  margin: auto;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f6ff;
  border-radius: 4px;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: -6px;
  color: var(--mainBlue);
  @media (max-width: 426px) {
    width: 80%;
  }
`;
const SignedIn = styled.div`
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: bold;
`;

const MemberFormGroup = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  label {
    color: #686868;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    width: 100%;
  }
  input {
    width: 100%;
    margin-top: 7px;
    height: 58px;
    background-color: #fbfbfb;
    border-radius: 4px;
    border: 1px solid #c5cae4;
  }
  select {
    width: 100%;
    margin-top: 7px;
    height: 58px;
    background-color: #fbfbfb;
    border-radius: 4px;
    border: 1px solid #c5cae4;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: auto;
  }
`;

const RowFormGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  div {
    width: 48%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    div {
      width: 100%;
    }
  }
`;
const ImageUploadSection = styled.div`
  display: flex;
  align-items: center;
`;
const ImageDisplay = styled.div`
  background-color: #fbfbfb;
  width: 147px;
  height: 147px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 53px;
    color: #c5cae4;
    font-weight: 300;
  }
`;
const KeyButton = styled.button`
  background-color: var(--mainBlue);
  outline: 0;
  border-color: transparent;
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin: auto;
  margin-top: 45px;
  font-size: 12px;
  border-radius: 5px;
  :disabled {
    background-color: #ccc;
    border: transparent;
    color: black;
  }
  img {
    width: 38px;
  }
`;
const ComplaintButton = styled.button`
  width: 200px;
  height: 50px;
  color: var(--mainBlue);
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f4f6fe;
  border: 1px solid var(--mainBlue);
  outline: 0;
  margin-right: 15px;
  font-size: 14px;
  border-radius: 5px;
`;
const ChatButton = styled.button`
  background-color: transparent;
  border: 1px solid var(--mainBlue);
  color: var(--mainBlue);
  outline: 0;
  width: 200px;
  height: 50px;
  margin-left: 15px;
  font-size: 12px;
  border-radius: 5px;

  :hover {
    background-color: var(--mainBlue);
    color: white;
    border-color: transparent;
  }
`;
// Chat Styling
const SearchGroup = styled.div`
  margin-bottom: 10px;
  position: relative;
  label {
    color: #686868;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    width: 100%;
  }
  input {
    width: 100%;
    margin-top: 7px;
    height: 58px;
    background-color: #fbfbfb;
    border-radius: 4px;
    border: 1px solid #c5cae4;
    padding-left: 40px;
    font-size: 12px;
    font-weight: 500;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: auto;
  }
  svg {
    position: absolute;
    left: 5px;
    top: 43px;
  }
`;
const ContactList = styled.div`
  margin-top: 7px;
  background-color: #f7f7f7;
  height: 400px;
  overflow: scroll;
`;
const Contact = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: transparent;
  width: 100%;
  :hover {
    background-color: #c5cae4;
    border: 1px solid #2d4bf3;
  }
`;
const IconDP = styled.div`
  width: 31px;
  height: 31px;
  margin-left: 15px;

  img {
    width: 31px;
    height: 31px;
    border-radius: 50%;
  }
`;
const ContactDetails = styled.div`
  text-align: left;
  width: 30%;
  h3 {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 8px;
    color: var(--mainBlack);
    letter-spacing: -0.7px;
    font-weight: 700;
  }
  p {
    color: var(--mainBlue);
    font-weight: bold;
    font-size: 12px;
    letter-spacing: -0.6px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;
const EstateID = styled.div`
  font-size: 10px;
  color: var(--mainBlack);
  font-weight: bold;
`;
const Position = styled.div`
  font-size: 12px;
  color: var(--mainBlue);
  margin-right: 15px;
`;
// Filter By Date Styling

const SortInput = styled.input`
  width: 200px;
  height: 50px;
  color: black;
  border: 1px solid var(--mainBlue);
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f4f6fe;
  outline: 0;
  border-color: transparent;
  margin-right: 15px;
  font-size: 14px;
  border-radius: 5px;
`;
const StyledForm = styled.form`
  width: 100%;
`;
// const SubmitButton = styled.button`
//   background-color: var(--mainBlue);
//   outline: 0;
//   border-color: transparent;
//   width: 200px;
//   height: 50px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: white;
//   margin: auto;
//   font-size: 12px;
//   border-radius: 5px;
//   svg {
//     margin-left: 10px;
//     font-size: 12px;
//   }
// `;
export {
  MemberRightHeader,
  AddMemberButton,
  VisitorAccessButton,
  AddComplaintsButton,
  NewChatButton,
  FilterByDate,
  AdminAddResident,
  AddPersonnelButton,
  VerifyAccessButton,
};
