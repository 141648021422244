import React from "react";
import styled from "styled-components";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import Logo from "../../assets/logo-colored.svg";
import { Link } from "react-router-dom";

type Props = {};

const Confirm = (props: Props) => {
  return (
    <Wrapper>
      <Container>
        <Link to="/dashboard">
          <img src={Logo} alt="Estate IQ Logo" />
        </Link>
        <Icon>
          <DoneOutlinedIcon />
        </Icon>
        <h3>Congratulations</h3>
        <h5>You have successfully registered your account</h5>
        <Button to="/auth/login">Log in</Button>
      </Container>
    </Wrapper>
  );
};

export default Confirm;

const Wrapper = styled.div`
  width: 100vh;
  height: 100vh;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Container = styled.div`
  width: 363px;
  margin: auto;
  text-align: center;
  img {
    width: 146px;
    height: 39px;
  }
  h3 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 10px;
    letter-spacing: -1.2px;
    color: var(--mainBlue);
  }
  h5 {
    color: #171717;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: -1px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;
const Icon = styled.div`
  width: 91px;
  margin: auto;
  height: 91px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 16px solid #f4f6fe;
  margin-top: 100px;
  margin-bottom: 30px;
  background-color: var(--mainBlue);
  color: white;
`;
const Button = styled(Link)`
  width: 363px;
  background-color: var(--mainBlue);
  height: 51px;
  margin: auto;
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 6px;
  text-decoration: none;
  a {
    width: 100%;
    color: white;
    text-decoration: none;
  }
`;
